// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Video_video-wrapper__\\+OGAO {
  width: 100%;
  display: flex;
  padding: 40px 0px;
  justify-content: center;
}
.Video_video-wrapper__\\+OGAO video {
  width: 100%;
  max-height: 600px;
}`, "",{"version":3,"sources":["webpack://./src/components/Lesson/Preview/Components/Video/Video.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,iBAAA;EACA,uBAAA;AACF;AACE;EACE,WAAA;EACA,iBAAA;AACJ","sourcesContent":[".video-wrapper {\n  width: 100%;\n  display: flex;\n  padding: 40px 0px;\n  justify-content: center;\n\n  video {\n    width: 100%;\n    max-height: 600px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"video-wrapper": `Video_video-wrapper__+OGAO`
};
export default ___CSS_LOADER_EXPORT___;
