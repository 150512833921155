import { FC } from "react";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { getRoles } from "utils/getRoles";
import { classNames } from "primereact/utils";
import { InputNumber } from "primereact/inputnumber";
import { HomeworkSettings } from "typings/lesson";

import styles from "./ConditionDepositMoney.module.scss";

type ConditionDepositMoneyProps = {
  conditionList: {
    countPoints: number;
    countHours: number;
    dateBefore: string;
  }[];
  updateSettings: (name: string, value: any) => void;
  dateValue: boolean;
  settings: HomeworkSettings;
};

const ConditionDepositMoney: FC<ConditionDepositMoneyProps> = (props) => {
  const isAdmin = getRoles();

  const addNewCondition = () => {
    const newCondition = {
      countPoints: null,
      countHours: null,
      dateBefore: null,
    };
    props.updateSettings("settingsAddingPoints", {
      ...props.settings.settingsAddingPoints,
      conditionAddingPoints: props.settings.settingsAddingPoints?.conditionAddingPoints
        ? [...props.settings.settingsAddingPoints?.conditionAddingPoints, newCondition]
        : [newCondition],
    });
  };

  const removeCondition = (index: number) => {
    const newArrayItems = props.settings.settingsAddingPoints?.conditionAddingPoints.filter(
      (_, indexEl) => indexEl !== index
    );
    props.updateSettings("settingsAddingPoints", {
      ...props.settings.settingsAddingPoints,
      conditionAddingPoints: newArrayItems,
    });
  };

  const changeCondition = (
    index: number,
    name: string,
    value: string | number | null | Date | Date[] | null | undefined
  ) => {
    const newArrayItems = props.settings.settingsAddingPoints?.conditionAddingPoints.map(
      (el, indexEl) => {
        if (indexEl === index) {
          return { ...el, [name]: value };
        } else return el;
      }
    );
    props.updateSettings("settingsAddingPoints", {
      ...props.settings.settingsAddingPoints,
      conditionAddingPoints: newArrayItems,
    });
  };

  return (
    <div className={styles["condition-wrapper"]}>
      <div className={styles["content"]}>
        {props.conditionList && props.conditionList.length > 0 ? (
          <div className={styles["list"]}>
            {props.conditionList.map((el, index) => (
              <div className={styles["list-item"]} key={index}>
                <span className={styles["iterable"]}>{index + 1}</span>
                <InputNumber
                  value={el.countPoints}
                  onChange={(e) => {
                    changeCondition(index, "countPoints", e.value);
                  }}
                  useGrouping={false}
                  min={0}
                  maxFractionDigits={0}
                  placeholder="Введите кол-во женег"
                  className={styles["form-item"]}
                  disabled={!isAdmin}
                />
                {props.dateValue ? (
                  <Calendar
                    value={el.dateBefore ? new Date(el.dateBefore) : undefined}
                    onChange={(e) => {
                      changeCondition(index, "dateBefore", e.target.value);
                    }}
                    showTime
                    hourFormat="24"
                    className={styles["calendar"]}
                    dateFormat="dd/mm/yy"
                    readOnlyInput
                    minDate={new Date()}
                    placeholder="Выберите дату"
                    disabled={!isAdmin}
                  />
                ) : (
                  <InputNumber
                    value={el.countHours}
                    onChange={(e) => {
                      changeCondition(index, "countHours", e.value);
                    }}
                    useGrouping={false}
                    min={0}
                    maxFractionDigits={0}
                    placeholder="Введите кол-во часов"
                    className={styles["form-item"]}
                    disabled={!isAdmin}
                  />
                )}
                {isAdmin && (
                  <button
                    className={styles["delete-btn"]}
                    onClick={() => removeCondition(index)}
                    disabled={!isAdmin}
                  >
                    <i className="pi pi-trash" />
                  </button>
                )}
              </div>
            ))}
          </div>
        ) : (
          <span className={styles["empty-list"]}>Список условий пуст</span>
        )}
      </div>
      {isAdmin && (
        <Button className={classNames("p-button", styles["add_btn"])} onClick={addNewCondition}>
          <i className="pi pi-plus" />
          Добавить условие
        </Button>
      )}
    </div>
  );
};

export default ConditionDepositMoney;
