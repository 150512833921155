export const ERRORS = {
  invalid_email: {
    backend_value: "email must be an email",
    alert: "Неккоректный Email",
  },
  invalid_pwd: {
    backend_value: "Invalid password",
    alert: "Пароль не подходит",
  },
  uniq_email_required: {
    backend_value: "A user with this email already exists",
    alert: "Такой пользователь уже существует",
  },

  failed_upload: {
    backend_value: "failed_upload",
    alert: "Что-то пошло не так. Повторите загрузку.",
  },
};
