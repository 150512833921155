import { FC } from "react";
import { Checkbox } from "primereact/checkbox";
import { classNames } from "primereact/utils";
import { TCourseComposition } from "typings/courses";

import styles from "./UpdateTarif.module.scss";

type TarifChildrensProps = {
  isAdmin: boolean;
  courseComposition: TCourseComposition | null;
  lessonIds: number[] | undefined;
  changeLessonPermissions: (newLessonIds: number[]) => void;
};

const TarifChildrens: FC<TarifChildrensProps> = (props) => {
  const changePermissionsLessons = (lessonId: number) => {
    if (props.lessonIds) {
      if (props.lessonIds?.includes(lessonId)) {
        props.changeLessonPermissions(props.lessonIds.filter((el) => el !== lessonId));
      } else props.changeLessonPermissions([...props.lessonIds, lessonId]);
    } else return;
  };

  const setCheckedModule = (moduleId: number) => {
    let value = true;
    let moduleLessonsIds: number[] = [];
    const module = props.courseComposition?.submodules.find((el) => el.id === moduleId);

    if (module) {
      module.lessons.map((el) => moduleLessonsIds.push(el.id));

      moduleLessonsIds.map((el) => {
        if (props.lessonIds && !props.lessonIds.find((item) => el === item)) value = false;
      });
    }

    return value;
  };

  const changePermissionsModule = (moduleId: number, value: boolean) => {
    let moduleLessonsIds: number[] = [];
    const module = props.courseComposition?.submodules.find((el) => el.id === moduleId);

    if (module && props.lessonIds) {
      module.lessons.map((el) => moduleLessonsIds.push(el.id));
      if (value) {
        props.changeLessonPermissions([...props.lessonIds, ...moduleLessonsIds]);
      } else {
        props.changeLessonPermissions(
          props.lessonIds.filter((el) => !moduleLessonsIds.includes(el))
        );
      }
    }
  };

  return (
    <div className={styles["tarif-child"]}>
      <span className={styles["tarif-child-header"]}>
        {props.courseComposition?.name || "Степ"}
      </span>

      {props.courseComposition?.lessons && props.courseComposition?.lessons.length > 0 && (
        <div className={styles["tarif-child-block"]}>
          {props.courseComposition &&
            props.courseComposition.lessons.map((el) => (
              <div className={styles["tarif-child-block-row"]} key={el.id}>
                <p>
                  <b>Урок -</b> {el.name}
                </p>
                <Checkbox
                  onChange={() => {
                    changePermissionsLessons(el.id);
                  }}
                  checked={props.lessonIds && props.lessonIds.includes(el.id)}
                  disabled={!props.isAdmin}
                ></Checkbox>
              </div>
            ))}
        </div>
      )}
      {props.courseComposition?.submodules &&
        props.courseComposition?.submodules.length === 0 &&
        props.courseComposition?.lessons &&
        props.courseComposition?.lessons.length === 0 && (
          <div className={styles["empty-course-childrens"]}>
            <p>Нет ни одного урока</p>
          </div>
        )}

      {props.courseComposition?.submodules && props.courseComposition?.submodules.length > 0 && (
        <div className={styles["tarif-child-block"]}>
          {props.courseComposition &&
            props.courseComposition.submodules.map((el) => {
              if (el.lessons.length > 0)
                return (
                  <div className={styles["tarif-child-block-row-module"]} key={el.id}>
                    <div className={styles["tarif-child-block-row"]}>
                      <p>
                        <b>Модуль - </b>
                        {el.name}
                      </p>
                      <Checkbox
                        onChange={(e) => {
                          changePermissionsModule(el.id, e.target.checked);
                        }}
                        checked={setCheckedModule(el.id)}
                        disabled={!props.isAdmin}
                      ></Checkbox>
                    </div>
                    <div
                      className={classNames(
                        styles["tarif-child-block"],
                        styles["tarif-child-block-module"]
                      )}
                    >
                      {el.lessons.map((lesson) => (
                        <div className={styles["tarif-child-block-row"]} key={lesson.id}>
                          <p>
                            <b>Урок - </b>
                            {lesson.name}
                          </p>
                          <Checkbox
                            onChange={() => {
                              changePermissionsLessons(lesson.id);
                            }}
                            checked={props.lessonIds && props.lessonIds.includes(lesson.id)}
                            disabled={!props.isAdmin}
                          ></Checkbox>
                        </div>
                      ))}
                    </div>
                  </div>
                );
            })}
        </div>
      )}
    </div>
  );
};

export default TarifChildrens;
