import { TLesson } from "typings/lesson";
import { InputText } from "primereact/inputtext";
import { useAppDispatch } from "utils/hooks";
import { updateComponent } from "slices/lessonConstructor";
import { FC, useCallback } from "react";

import styles from "./Constructor.module.scss";

type LessonTrackListProps = {
  lessonInfo: TLesson;
};

const LessonTrackList: FC<LessonTrackListProps> = (props) => {
  const dispatch = useAppDispatch();

  const addNewItem = () => {
    dispatch(
      updateComponent({
        property: "playlist",
        value: {
          ...props.lessonInfo.playlist,
          list: props.lessonInfo.playlist?.list ? [...props.lessonInfo.playlist?.list, ""] : [""],
        },
      })
    );
  };

  const removeItem = (removeIndex: number) => {
    const newArray = props.lessonInfo.playlist?.list.filter((el, index) => index !== removeIndex);

    dispatch(
      updateComponent({
        property: "playlist",
        value: {
          ...props.lessonInfo.playlist,
          list: newArray,
        },
      })
    );
  };

  const changeItem = useCallback(
    (updateIndex: number, value: string) => {
      const newArray = props.lessonInfo.playlist?.list.map((el, index) => {
        if (index === updateIndex) {
          return value;
        } else return el;
      });

      dispatch(
        updateComponent({
          property: "playlist",
          value: {
            ...props.lessonInfo.playlist,
            list: newArray,
          },
        })
      );
    },
    [props.lessonInfo.playlist?.list, dispatch]
  );

  return (
    <div className={styles["tracklist"]}>
      {(props.lessonInfo.playlist &&
        props.lessonInfo.playlist?.list &&
        props.lessonInfo.playlist?.list.length > 0 &&
        props.lessonInfo.playlist?.list.map((el, index) => (
          <div className={styles["list-item"]} key={index}>
            <InputText
              value={el}
              onChange={(e) => {
                changeItem(index, e.target.value);
              }}
              placeholder="Введите текст"
              className={styles["form-item"]}
            />
            <button className={styles["remove-btn"]} onClick={() => removeItem(index)}>
              <i className="pi pi-trash" />
            </button>
          </div>
        ))) || <span className={styles["empty-list"]}>Список пуст</span>}
      <button className={styles["add-btn"]} onClick={addNewItem}>
        Добавить
      </button>
    </div>
  );
};

export default LessonTrackList;
