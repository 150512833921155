import { TNewLesson } from "typings/lesson";
import http from "./https";
import { TAxiosGetCourseList, TNewCourse, TShedule, TUpdateCourse } from "typings/courses";

export const getAllCourses = (params: TAxiosGetCourseList) =>
  http.get(
    "/api/courses",
    {},
    {
      params,
    }
  );

export const createNewCourse = (courseData: TNewCourse) => http.post("/api/courses", courseData);

export const removeCourse = (courseId: number) => http.remove(`/api/courses/${courseId}`, {});

export const getCourseById = (courseId: string) => http.get(`/api/courses/${courseId}`);

export const createNewModule = (moduleData: TNewCourse) => http.post("/api/courses", moduleData);

export const createNewLesson = (lessonData: TNewLesson) => http.post("/api/lessons", lessonData);

export const updateCourseAsync = (courseId: number, courseData: TUpdateCourse) =>
  http.patch(`/api/courses/${courseId}`, courseData);

export const getCourseSheduleAsync = (courseId: number) =>
  http.get(`/api/courses/${courseId}/lessons-schedule`);

export const updateCourseShedule = (courseId: number, newCourseShedule: TShedule[]) =>
  http.patch(`/api/courses/${courseId}/lessons-schedule`, { schedule: newCourseShedule });

export const getShortListCoursesModules = () => http.get(`api/head-courses/list?withLessons=false`);

export const saveChangePlacesAsync = (
  indexesData: { id: number; index: number }[],
  courseId: string,
  isLessons: boolean
) =>
  http.patch(`api/courses/change-index?courseId=${courseId}&isLessons=${isLessons}`, {
    indexesData,
  });

export const copyCourseAsync = (
  courseId: string,
  data: { name: string; headCourseId: number }
) => http.post(`api/courses/${courseId}/copy`, data);
