export const menu = () => {
  const IS_CURATOR = localStorage.getItem("isCurator");

  const CURATOR_MENU = [
    {
      label: "Курсы",
      icon: "pi pi-compass",
      to: "/courses",
    },
    {
      label: "Выход",
      icon: "pi pi-sign-out",
      bottom: true,
      logout: true,
    },
  ];

  const ADMIN_MENU = [
    {
      label: "Пользователи",
      icon: "pi pi-home",
      to: "/users",
    },
    {
      label: "Ученики",
      icon: "pi pi-align-left",
      to: "/students",
    },
    {
      label: "Курсы",
      icon: "pi pi-compass",
      to: "/courses",
    },
    {
      label: "Выход",
      icon: "pi pi-sign-out",
      bottom: true,
      logout: true,
    },
  ];

  if (IS_CURATOR === "true") return CURATOR_MENU;
  else return ADMIN_MENU;
};
