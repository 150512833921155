import { FC } from "react";

import styles from "./Video.module.scss";

type CustomLessonVideoProps = {
  url: string;
};

const CustomLessonVideo: FC<CustomLessonVideoProps> = (props) => {
  return (
    <div className={styles["video-wrapper"]}>
      <video controls width="100%">
        <source src={props.url} />
      </video>
    </div>
  );
};

export default CustomLessonVideo;
