import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { FC } from "react";
import { Dropdown } from "primereact/dropdown";
import { ROLES, ROLES_LIST } from "constants/roles";
import { TAdmin, TNewAdmin } from "typings/admins";
import styles from "../components/Admins/Admins.module.scss";

type EditAdminPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  selectedAdmin: TAdmin;
  changeSelectedAdmin: (name: string, value: string | boolean) => void;
  updateAdmin: (adminData: TNewAdmin) => void;
};

const EditAdminPopup: FC<EditAdminPopupProps> = ({
  isOpen,
  handleHide,
  selectedAdmin,
  changeSelectedAdmin,
  updateAdmin,
}) => {
  const updateAdminLocalFunc = () =>
    updateAdmin({
      firstName: selectedAdmin.firstName,
      lastName: selectedAdmin.lastName,
      isCurator: selectedAdmin.isCurator ? true : false,
    });

  return (
    <Dialog
      visible={isOpen}
      style={{ width: "500px" }}
      modal
      onHide={handleHide}
      header={`Редактирование пользователя`}
    >
      <div className={styles["create-account-popup-wrapper"]}>
        <span className={styles["create-account-input-wrapper"]}>
          <p>Имя</p>
          <InputText
            name="firstName"
            value={selectedAdmin?.firstName}
            onChange={(e) => changeSelectedAdmin(e.target.name, e.target.value)}
            maxLength={50}
            placeholder="Введите имя"
          />
        </span>
        <span className={styles["create-account-input-wrapper"]}>
          <p>Фамилия</p>
          <InputText
            name="lastName"
            value={selectedAdmin?.lastName}
            onChange={(e) => changeSelectedAdmin(e.target.name, e.target.value)}
            maxLength={50}
            placeholder="Введите фамилию"
          />
        </span>
        <span className={styles["create-account-input-wrapper"]}>
          <p>Выберите роль</p>
          <Dropdown
            options={ROLES}
            onChange={(e) =>
              changeSelectedAdmin(e.target.name, e.value === ROLES_LIST.curator ? true : false)
            }
            value={selectedAdmin.isCurator ? ROLES_LIST.curator : ROLES_LIST.admin}
            name="isCurator"
          />
        </span>

        <div className={styles["create-account-btn-wrap"]}>
          <button className={styles["cancel-btn"]} onClick={handleHide}>
            Отмена
          </button>
          <button
            className={styles["create-acc-btn"]}
            onClick={updateAdminLocalFunc}
            disabled={typeof selectedAdmin.isCurator !== "boolean" || !selectedAdmin.firstName}
          >
            Сохранить изменения
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default EditAdminPopup;
