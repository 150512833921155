import { FC, useCallback, useEffect, useState } from "react";
import { tableControlsType } from "typings/table-controls";
import { useAppDispatch } from "utils/hooks";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { phrases } from "constants/phrases";
import { ERRORS } from "constants/errors";
import { TNewStudent, TStudent } from "typings/students";
import { createNewStudent, getAllStudents, removeStudent, updateStudentAsync } from "api/students";
import StudentsLayout from "./Students.layout";
import CreateStudentPopup from "popups/create-student";
import EditStudentPopup from "popups/edit-student";
import RemoveStudentPopup from "popups/remove-student";
import StudentInfoPopup from "popups/student-info";

type StudentsProps = {};

const defaultParams: tableControlsType = {
  first: 0,
  rows: 15,
  page: 0,
  search: "",
};

const Students: FC<StudentsProps> = () => {
  const [studentsList, setStudentsList] = useState<TStudent[]>([]);
  const [params, setParams] = useState<tableControlsType>(defaultParams);
  const [selectedStudent, setSelectedStudent] = useState<TStudent | null>(null);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const [isOpenCreateStudentPopup, setIsOpenCreateStudentPopup] = useState<boolean>(false);
  const [isOpenEditStudentPopup, setIsOpenEditStudentPopup] = useState<boolean>(false);
  const [isOpenRemoveStudentPopup, setIsOpenRemoveStudentPopup] = useState<boolean>(false);
  const [isOpenStudentInfoPopup, setIsOpenStudentInfoPopup] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const handleSelectedStudent = (student: TStudent) => setSelectedStudent(student);

  const handleOpenCreateStudentPopup = () => setIsOpenCreateStudentPopup(true);
  const handleCloseCreateStudentPopup = () => setIsOpenCreateStudentPopup(false);

  const handleOpenEditStudentPopup = () => setIsOpenEditStudentPopup(true);
  const handleCloseEditStudentPopup = () => setIsOpenEditStudentPopup(false);

  const handleOpenRemoveStudentPopup = () => setIsOpenRemoveStudentPopup(true);
  const handleCloseRemoveStudentPopup = () => setIsOpenRemoveStudentPopup(false);

  const handleOpenStudentInfoPopup = () => setIsOpenStudentInfoPopup(true);
  const handleCloseStudentInfoPopup = () => setIsOpenStudentInfoPopup(false);

  const changeSelectedStudent = (name: string, value: string | boolean | number | null) => {
    setSelectedStudent((prev: any) => ({ ...prev, [name]: value }));
  };

  const getStudentsList = useCallback(
    async (params) => {
      setIsLoadingTable(true);
      try {
        const response = await getAllStudents(params);
        if (response.status === 200 || response.status === 201) {
          setStudentsList(response.data.data);
          setTotalRecords(response.data.pagination.totalItems);
          setIsLoadingTable(false);
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    getStudentsList({
      page: params.page,
      limit: params.rows,
      search: params.search || undefined,
    });
  }, [params, getStudentsList]);

  const createStudent = useCallback(
    async (studentData: TNewStudent) => {
      setIsLoadingTable(true);
      try {
        const response = await createNewStudent(studentData);
        if (response.status === 200 || response.status === 201) {
          getStudentsList({
            page: params.page,
            limit: params.rows,
            search: params.search || undefined,
          });
          dispatch(addSuccessMessage(phrases.student_success_added));
        } else throw new Error();
      } catch (err: any) {
        if (err.response.data.message.includes(ERRORS.invalid_email.backend_value))
          return dispatch(addErrorMessage(ERRORS.invalid_email.alert));
        else if (err.response.data.message.includes(ERRORS.uniq_email_required.backend_value))
          return dispatch(addErrorMessage(ERRORS.uniq_email_required.alert));
        else return dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrong));
      } finally {
        handleCloseCreateStudentPopup();
        setIsLoadingTable(false);
      }
    },
    [dispatch]
  );

  const deleteStudent = useCallback(async () => {
    setIsLoadingTable(true);
    try {
      if (selectedStudent) {
        const response = await removeStudent(selectedStudent.id);
        if (response.status === 200 || response.status === 201) {
          dispatch(addSuccessMessage(phrases.student_sucess_deleted));
          getStudentsList({
            page: params.page,
            limit: params.rows,
            search: params.search || undefined,
          });
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
    } finally {
      handleCloseRemoveStudentPopup();
      setIsLoadingTable(false);
    }
  }, [dispatch, selectedStudent]);

  const updateStudent = useCallback(
    async (studentData: TNewStudent) => {
      setIsLoadingTable(true);
      try {
        if (selectedStudent) {
          const response = await updateStudentAsync(selectedStudent?.id, studentData);
          if (response.status === 200 || response.status === 201) {
            getStudentsList({
              page: params.page,
              limit: params.rows,
              search: params.search || undefined,
            });
            dispatch(addSuccessMessage(phrases.student_success_updated));
          } else throw new Error();
        } else return;
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      } finally {
        handleCloseEditStudentPopup();
        setIsLoadingTable(false);
      }
    },
    [dispatch, selectedStudent]
  );

  return (
    <>
      <StudentsLayout
        params={params}
        setParams={setParams}
        studentsList={studentsList}
        handleSelectedStudent={handleSelectedStudent}
        totalRecords={totalRecords}
        isLoadingTable={isLoadingTable}
        handleOpenCreateStudentPopup={handleOpenCreateStudentPopup}
        handleOpenRemoveStudentPopup={handleOpenRemoveStudentPopup}
        handleOpenEditStudentPopup={handleOpenEditStudentPopup}
        handleOpenStudentInfoPopup={handleOpenStudentInfoPopup}
      />
      <CreateStudentPopup
        isOpen={isOpenCreateStudentPopup}
        handleHide={handleCloseCreateStudentPopup}
        createStudent={createStudent}
      />
      {selectedStudent && (
        <EditStudentPopup
          isOpen={isOpenEditStudentPopup}
          handleHide={handleCloseEditStudentPopup}
          selectedStudent={selectedStudent}
          changeSelectedStudent={changeSelectedStudent}
          updateStudent={updateStudent}
        />
      )}
      <RemoveStudentPopup
        isOpen={isOpenRemoveStudentPopup}
        handleHide={handleCloseRemoveStudentPopup}
        selectedStudent={selectedStudent}
        deleteStudent={deleteStudent}
      />

      <StudentInfoPopup
        isOpen={isOpenStudentInfoPopup}
        handleHide={handleCloseStudentInfoPopup}
        selectedStudent={selectedStudent}
      />
    </>
  );
};

export default Students;
