import { FC } from "react";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { InputNumber } from "primereact/inputnumber";
import { TCoursePanel } from "typings/courses";
import { InputTextarea } from "primereact/inputtextarea";

import styles from "./AdditionalSettings.module.scss";

type AdditionalSettingsPanelProps = {
  panelList: TCoursePanel[];
  changePanel: React.Dispatch<React.SetStateAction<TCoursePanel[]>>;
};

const AdditionalSettingsPanel: FC<AdditionalSettingsPanelProps> = (props) => {
  const addNewItem = () => {
    props.changePanel((prev) => [...prev, { number: 0, text: "", title: "" }]);
  };

  const removeItem = (removeIndex: number) => {
    const newArray = props.panelList.filter((el, index) => index !== removeIndex);
    props.changePanel(newArray);
  };

  const changeItem = (updateIndex: number, fieldName: string, value: string | number | null) => {
    const newArray = props.panelList.map((el, index) => {
      if (index === updateIndex) {
        return { ...el, [fieldName]: value };
      } else return el;
    });
    props.changePanel(newArray);
  };

  return (
    <div className={styles["list-container"]}>
      {props.panelList.map((el, index) => (
        <div
          className={classNames(
            styles["list-item"],
            index + 1 !== props.panelList.length && styles["separator"]
          )}
          key={index}
        >
          <div>
            <InputNumber
              value={el.number}
              onValueChange={(e) => {
                changeItem(index, "number", e.value);
              }}
              useGrouping={false}
              className={styles["form-item"]}
              min={0}
              max={9999}
              maxFractionDigits={0}
              placeholder="Введите число"
            />
            <InputText
              value={el.title}
              onChange={(e) => {
                changeItem(index, "title", e.target.value);
              }}
              placeholder="Введите заголовок"
              className={styles["form-item"]}
            />
            <InputTextarea
              value={el.text}
              onChange={(e) => {
                changeItem(index, "text", e.target.value);
              }}
              rows={3}
              cols={30}
              autoResize
              placeholder="Введите комментарий"
              className={styles["form-item"]}
            />
          </div>
          <button className={styles["remove-btn"]} onClick={() => removeItem(index)}>
            <i className="pi pi-trash" />
          </button>
        </div>
      ))}
      <button className={styles["add-btn"]} onClick={addNewItem}>
        Добавить панель
      </button>
    </div>
  );
};

export default AdditionalSettingsPanel;
