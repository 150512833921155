import { phrases } from "constants/phrases";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "utils/hooks";
import { TAxiosGetCourseList } from "typings/courses";
import { THeadCourse, TNewHeadCourse } from "typings/headCourse";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { FC, useCallback, useEffect, useState } from "react";
import { createNewHeadCourse, getAllHeadCourses, removeHeadCourse } from "api/headCourses";

import styles from "./HeadCourses.module.scss";
import ScrollToTop from "react-scroll-to-top";
import HeadCoursesLayout from "./HeadCourses.layout";
import CreateHeadCoursePopup from "popups/create-head-course";
import RemoveHeadCoursePopup from "popups/remove-head-course";

type HeadCoursesProps = {};

const defaultParams: TAxiosGetCourseList = {
  search: "",
};

const HeadCourses: FC<HeadCoursesProps> = () => {
  const [headCoursesList, setHeadCoursesList] = useState<THeadCourse[]>([]);
  const [params, setParams] = useState<TAxiosGetCourseList>(defaultParams);
  const [selectedHeadCourse, setSelectedHeadCourse] = useState<THeadCourse | null>(null);

  const [isOpenCreateHeadCoursePopup, setIsOpenCreateHeadCoursePopup] = useState<boolean>(false);
  const [isOpenRemoveHeadCoursePopup, setIsOpenRemoveHeadCoursePopup] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleSelectedHeadCourse = (headCourse: THeadCourse) => setSelectedHeadCourse(headCourse);

  const handleOpenCreateHeadCoursePopup = () => setIsOpenCreateHeadCoursePopup(true);
  const handleCloseCreateHeadCoursePopup = () => setIsOpenCreateHeadCoursePopup(false);

  const handleOpenRemoveHeadCoursePopup = () => setIsOpenRemoveHeadCoursePopup(true);
  const handleCloseRemoveHeadCoursePopup = () => setIsOpenRemoveHeadCoursePopup(false);

  const getHeadCourseList = useCallback(
    async (defaultParams: TAxiosGetCourseList) => {
      try {
        const response = await getAllHeadCourses(defaultParams);
        if (response.status === 200 || response.status === 201) {
          setHeadCoursesList(response.data);
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    getHeadCourseList(params);
  }, [params, getHeadCourseList]);

  const createHeadCourse = useCallback(
    async (headCourseData: TNewHeadCourse) => {
      try {
        const response = await createNewHeadCourse(headCourseData);
        if (response.status === 200 || response.status === 201) {
          getHeadCourseList(params);
          navigate(`/courses/update_course?course_id=${response.data.id}`);
          dispatch(addSuccessMessage(phrases.course_success_added));
        } else throw new Error();
      } catch (err: any) {
        return dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrong));
      } finally {
        handleCloseCreateHeadCoursePopup();
      }
    },
    [dispatch]
  );

  const deleteHeadCourse = useCallback(
    async (headCourseId: number) => {
      try {
        const response = await removeHeadCourse(headCourseId);
        if (response.status === 200 || response.status === 201) {
          dispatch(addSuccessMessage(phrases.course_success_deleted));
          getHeadCourseList(params);
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      } finally {
        handleCloseRemoveHeadCoursePopup();
      }
    },
    [dispatch]
  );

  return (
    <>
      <HeadCoursesLayout
        params={params}
        setParams={setParams}
        headCoursesList={headCoursesList}
        handleSelectedHeadCourse={handleSelectedHeadCourse}
        handleOpenCreateHeadCoursePopup={handleOpenCreateHeadCoursePopup}
        deleteHeadCourse={deleteHeadCourse}
        handleOpenRemoveHeadCoursePopup={handleOpenRemoveHeadCoursePopup}
      />

      <CreateHeadCoursePopup
        isOpen={isOpenCreateHeadCoursePopup}
        handleHide={handleCloseCreateHeadCoursePopup}
        createHeadCourse={createHeadCourse}
      />

      <RemoveHeadCoursePopup
        isOpen={isOpenRemoveHeadCoursePopup}
        handleHide={handleCloseRemoveHeadCoursePopup}
        deleteHeadCourse={deleteHeadCourse}
        selectedHeadCourse={selectedHeadCourse}
      />

      <ScrollToTop
        smooth
        component={<i className="pi pi-chevron-up"></i>}
        className={styles["up-btn"]}
      />
    </>
  );
};

export default HeadCourses;
