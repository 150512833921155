import { getRoles } from "utils/getRoles";
import { Checkbox } from "primereact/checkbox";
import { classNames } from "primereact/utils";
import { useAppSelector } from "utils/hooks";
import { HomeworkSettings, TLesson } from "typings/lesson";
import { FC, memo, useCallback, useEffect, useState } from "react";

import HomeworkLayout from "./HomeWorkLayout";

import styles from "./HomeworkAndComments.module.scss";

type HomeworkAndCommentsProps = {
  updateLesson: (lessonData: TLesson) => void;
};

const HOMEWORK_SETTINGS = {
  isTask: false,
  isStopLesson: false,
  isOpenRegardlessOfStopLesson: false,
  task: "",
  isAcceptAnswerAuto: false,
  isStudentCanHideAnswer: false,
  isAnswerVisibleEveryone: false,
  isProhibitStudentsFromShowingTheirAnswer: false,
  additionalFieldsTask: [],
  isHideMainResponseField: false,
  taskDescription: "",
  isTextTask: false,
  settingsAddingPoints: {
    defaultCountPoints: 0,
    defaultPointsAt: 0,
    conditionByDate: false,
    conditionAddingPoints: [
      {
        countPoints: 0,
        countHours: 0,
        dateBefore: "",
      },
    ],
  },
};

const HomeworkAndComments: FC<HomeworkAndCommentsProps> = (props) => {
  const [settings, setSettings] = useState<HomeworkSettings>(HOMEWORK_SETTINGS);

  const { lessonInfo }: { lessonInfo: TLesson } = useAppSelector(
    (state) => state.lessonConstructor
  );

  const isAdmin = getRoles();

  const updateSettings = (name: string, value: any) => {
    setSettings((prev) => ({ ...prev, [name]: value }));
  };

  const renderHomeWorkLayout = useCallback(() => {
    if (settings.isTask)
      return <HomeworkLayout settings={settings} updateSettings={updateSettings} />;
    else return undefined;
  }, [settings]);

  const returnVisibleSaveButton = useCallback(() => {
    if (
      lessonInfo.isTask !== settings.isTask ||
      lessonInfo.isStopLesson !== settings.isStopLesson ||
      lessonInfo.isOpenRegardlessOfStopLesson !== settings.isOpenRegardlessOfStopLesson ||
      lessonInfo.task !== settings.task ||
      lessonInfo.isAcceptAnswerAuto !== settings.isAcceptAnswerAuto ||
      lessonInfo.isStudentCanHideAnswer !== settings.isStudentCanHideAnswer ||
      lessonInfo.isAnswerVisibleEveryone !== settings.isAnswerVisibleEveryone ||
      lessonInfo.isProhibitStudentsFromShowingTheirAnswer !==
        settings.isProhibitStudentsFromShowingTheirAnswer ||
      JSON.stringify(lessonInfo.additionalFieldsTask) !==
        JSON.stringify(settings.additionalFieldsTask) ||
      lessonInfo.isHideMainResponseField !== settings.isHideMainResponseField ||
      JSON.stringify(lessonInfo.settingsAddingPoints) !==
        JSON.stringify(settings.settingsAddingPoints) ||
      lessonInfo.isTextTask !== settings.isTextTask ||
      lessonInfo.taskDescription !== settings.taskDescription
    )
      return true;
    else return false;
  }, [lessonInfo, settings]);

  useEffect(() => {
    lessonInfo &&
      setSettings({
        isTask: lessonInfo.isTask,
        isStopLesson: lessonInfo.isStopLesson,
        isOpenRegardlessOfStopLesson: lessonInfo.isOpenRegardlessOfStopLesson,
        task: lessonInfo.task,
        isAcceptAnswerAuto: lessonInfo.isAcceptAnswerAuto,
        isStudentCanHideAnswer: lessonInfo.isStudentCanHideAnswer,
        isAnswerVisibleEveryone: lessonInfo.isAnswerVisibleEveryone,
        isProhibitStudentsFromShowingTheirAnswer:
          lessonInfo.isProhibitStudentsFromShowingTheirAnswer,
        additionalFieldsTask: lessonInfo.additionalFieldsTask,
        isHideMainResponseField: lessonInfo.isHideMainResponseField,
        settingsAddingPoints: lessonInfo.settingsAddingPoints,
        taskDescription: lessonInfo.taskDescription,
        isTextTask: lessonInfo.isTextTask,
      });
  }, [lessonInfo]);

  return (
    <div className={styles["main-wrapper"]}>
      <div className={styles["chek-wrap"]}>
        <Checkbox
          onChange={() => setSettings((prev) => ({ ...prev, isTask: !settings.isTask }))}
          checked={settings.isTask}
          disabled={!isAdmin}
        ></Checkbox>
        <label className="p-checkbox-label">В уроке есть задание</label>
      </div>
      <div className={styles["main-wrapper-content"]}>{renderHomeWorkLayout()}</div>
      <div className={styles["chek-wrap"]}>
        <Checkbox
          onChange={() =>
            setSettings((prev) => ({ ...prev, isStopLesson: !settings.isStopLesson }))
          }
          checked={settings.isStopLesson}
          disabled={!isAdmin}
        ></Checkbox>
        <label className="p-checkbox-label">Стоп-урок</label>
      </div>
      <div className={styles["chek-wrap"]}>
        <Checkbox
          onChange={() =>
            setSettings((prev) => ({
              ...prev,
              isOpenRegardlessOfStopLesson: !settings.isOpenRegardlessOfStopLesson,
            }))
          }
          checked={settings.isOpenRegardlessOfStopLesson}
          disabled={!isAdmin}
        ></Checkbox>
        <label className="p-checkbox-label">Доступен вне зависимости от стоп-уроков</label>
      </div>
      {isAdmin && (
        <button
          className={classNames(
            styles["save-button"],
            returnVisibleSaveButton() && styles["save-button-visible"]
          )}
          onClick={() => props.updateLesson({ ...lessonInfo, ...settings })}
        >
          Сохранить изменения
        </button>
      )}
    </div>
  );
};

export default memo(HomeworkAndComments);
