import { phrases } from "constants/phrases";
import { TNewLesson } from "typings/lesson";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "utils/hooks";
import { createNewLesson, saveChangePlacesAsync } from "api/courses";
import { TModuleInfo, TUpdateModule } from "typings/module";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { FC, memo, useCallback, useEffect, useState } from "react";
import { copyModuleAsync, getModuleById, removeModule, updateModuleAsync } from "api/module";

import ScrollToTop from "react-scroll-to-top";
import CreateLessonPopup from "popups/create-lesson";
import RemoveModulePopup from "popups/remove-module";
import UpdateModuleLayout from "./UpdateModule.layout";

import styles from "./UpdateModule.module.scss";
import ChangePlacesPopup from "popups/change-places";
import CopyModulePopup from "popups/copy-module";

type UpdateModuleProps = {
  moduleId: string | null | undefined;
  courseId: string | null | undefined;
  headCourseId: string | null | undefined;
  changeModuleId: (moduleID: string | null) => void;
};

const UpdateModule: FC<UpdateModuleProps> = (props) => {
  const [moduleInfo, setModuleInfo] = useState<TModuleInfo | null>(null);
  const [isOpenCreateLessonPopup, setIsOpenCreateLessonPopup] = useState<boolean>(false);
  const [isOpenRemoveModulePopup, setIsOpenRemoveModulePopup] = useState<boolean>(false);
  const [isOpenCopuModule, setIsOpenCopyModule] = useState<boolean>(false);
  const [isOpenChangePlacesPopup, setIsOpenChangePlacesPopup] = useState<{
    isOpen: boolean;
    type: string;
  }>({
    isOpen: false,
    type: "modules",
  });

  const navigate = useNavigate();

  const handleOpenCreateLessonPopup = () => setIsOpenCreateLessonPopup(true);
  const handleCloseCreateLessonPopup = () => setIsOpenCreateLessonPopup(false);

  const handleOpenRemoveModulePopup = () => setIsOpenRemoveModulePopup(true);
  const handleCloseRemoveModulePopup = () => setIsOpenRemoveModulePopup(false);

  const handleOpenCopyModulePopup = () => setIsOpenCopyModule(true);
  const handleCloseCopyModulePopup = () => setIsOpenCopyModule(false);

  const handleOpenChangePlacesPopup = (type: string) =>
    setIsOpenChangePlacesPopup({ type: type, isOpen: true });
  const handleCloseChangePlacesPopup = () =>
    setIsOpenChangePlacesPopup((prev) => ({ ...prev, isOpen: false }));

  const dispatch = useAppDispatch();

  const getModuleInfo = useCallback(async () => {
    try {
      if (props.moduleId) {
        const response = await getModuleById(props.moduleId);
        if (response.status === 200 || response.status === 201) {
          setModuleInfo(response.data);
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {
      props.changeModuleId(null);
    }
  }, [dispatch, props.moduleId]);

  useEffect(() => {
    getModuleInfo();
  }, [getModuleInfo]);

  const createLesson = useCallback(
    async (lessonData: TNewLesson) => {
      try {
        const response = await createNewLesson({ ...lessonData, courseId: Number(moduleInfo?.id) });
        if (response.status === 200 || response.status === 201) {
          navigate({
            pathname: "/courses/update_lesson",
            search: `?lesson_id=${response.data.id}&module_id=${props.moduleId}&course_id=${props.headCourseId}&step_id=${props.courseId}`,
          });
          dispatch(addSuccessMessage(phrases.lesson_success_added));
        } else throw new Error();
      } catch (err: any) {
        return dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrong));
      } finally {
        handleCloseCreateLessonPopup();
      }
    },
    [dispatch, moduleInfo]
  );

  const updateModule = useCallback(
    async (moduleData: TUpdateModule) => {
      try {
        if (moduleInfo) {
          const response = await updateModuleAsync(moduleInfo?.id, moduleData);
          if (response.status === 200 || response.status === 201) {
            getModuleInfo();
            dispatch(addSuccessMessage(phrases.module_success_updated));
          } else throw new Error();
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      }
    },
    [dispatch, moduleInfo]
  );

  const deleteModule = useCallback(
    async (moduleId: number) => {
      try {
        const response = await removeModule(moduleId);
        if (response.status === 200 || response.status === 201) {
          dispatch(addSuccessMessage(phrases.module_success_deleted));
          navigate(
            `/courses/update_step?course_id=${props.headCourseId}&step_id=${props.courseId}`
          );
          getModuleInfo();
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      } finally {
        handleCloseRemoveModulePopup();
      }
    },
    [dispatch]
  );

  const saveChangePlaces = useCallback(
    async (indexesData: { id: number; index: number }[], isLessons: boolean) => {
      try {
        if (props.moduleId) {
          const response = await saveChangePlacesAsync(indexesData, props.moduleId, isLessons);
          if (response.status === 200 || response.status === 201) {
            getModuleInfo();
            dispatch(addSuccessMessage(phrases.module_success_updated));
          } else throw new Error();
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      }
    },
    [dispatch, props.moduleId]
  );

  const copyModule = useCallback(
    async (name: string, copyCourse: number) => {
      try {
        if (props.moduleId && props.courseId) {
          const response = await copyModuleAsync(props.moduleId, {
            name,
            courseId: copyCourse,
          });
          if (response.status === 200 || response.status === 201) {
            dispatch(addSuccessMessage(phrases.module_success_copy));
          } else throw new Error();
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      } finally {
        handleCloseCopyModulePopup();
      }
    },
    [dispatch, props.moduleId, props.courseId]
  );

  return (
    <>
      <UpdateModuleLayout
        moduleInfo={moduleInfo}
        headCourseId={props.headCourseId}
        updateModule={updateModule}
        handleOpenCreateLessonPopup={handleOpenCreateLessonPopup}
        handleOpenRemoveModulePopup={handleOpenRemoveModulePopup}
        courseId={props.courseId}
        handleOpenChangePlacesPopup={handleOpenChangePlacesPopup}
        handleOpenCopyModulePopup={handleOpenCopyModulePopup}
      />

      <CreateLessonPopup
        isOpen={isOpenCreateLessonPopup}
        handleHide={handleCloseCreateLessonPopup}
        createLesson={createLesson}
      />

      <RemoveModulePopup
        isOpen={isOpenRemoveModulePopup}
        handleHide={handleCloseRemoveModulePopup}
        deleteModule={deleteModule}
        selectedModule={moduleInfo}
      />

      <ChangePlacesPopup
        isOpen={isOpenChangePlacesPopup.isOpen}
        type={isOpenChangePlacesPopup.type}
        handleHide={handleCloseChangePlacesPopup}
        courseId={props.moduleId}
        saveChangePlaces={saveChangePlaces}
      />

      <CopyModulePopup
        isOpen={isOpenCopuModule}
        handleHide={handleCloseCopyModulePopup}
        moduleInfo={moduleInfo}
        copyModule={copyModule}
      />

      <ScrollToTop
        smooth
        component={<i className="pi pi-chevron-up"></i>}
        className={styles["up-btn"]}
      />
    </>
  );
};

export default memo(UpdateModule);
