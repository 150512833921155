import { phrases } from "constants/phrases";
import { getRoles } from "utils/getRoles";
import { classNames } from "primereact/utils";
import { useAppDispatch } from "utils/hooks";
import { TCourseInfo, TShedule } from "typings/courses";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { getCourseSheduleAsync, updateCourseShedule } from "api/courses";
import { FC, memo, useCallback, useEffect, useState } from "react";

import Shedule from "components/comon/shedule/Shedule";

import styles from "./Shedule.module.scss";

type CourseSheduleTabProps = {
  courseInfo: TCourseInfo | null;
};

const CourseSheduleTab: FC<CourseSheduleTabProps> = (props) => {
  const [shedule, setShedule] = useState<TShedule[]>([]);
  const [initShedule, setInitShedule] = useState<TShedule[]>([]);

  const isAdmin = getRoles();

  const dispatch = useAppDispatch();

  const getCourseShedule = useCallback(async () => {
    try {
      if (props.courseInfo) {
        const response = await getCourseSheduleAsync(props.courseInfo.id);
        if (response.status === 200 || response.status === 201) {
          setShedule(response.data);
          setInitShedule(response.data);
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {}
  }, [dispatch, props.courseInfo]);

  useEffect(() => {
    getCourseShedule();
  }, [getCourseShedule]);

  const changeSheduleDate = (lessonId: number, newDate: string) => {
    const updatedShedule = shedule.map((el) => {
      if (el.id === lessonId) {
        return { ...el, startAt: newDate };
      } else return el;
    });
    setShedule(updatedShedule);
  };

  const changeSheduleVisible = (lessonId: number, visibility: string) => {
    const updatedShedule = shedule.map((el) => {
      if (el.id === lessonId) {
        return { ...el, visibility: visibility };
      } else return el;
    });
    setShedule(updatedShedule);
  };

  const updateShedule = useCallback(async () => {
    try {
      if (props.courseInfo) {
        const response = await updateCourseShedule(props.courseInfo?.id, shedule);
        if (response.status === 200 || response.status === 201) {
          dispatch(addSuccessMessage(phrases.shedule_success_updated));
          getCourseShedule();
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
    }
  }, [dispatch, props.courseInfo, shedule]);

  return (
    <div className={styles["main-wrapper"]}>
      <Shedule
        shedule={shedule}
        changeSheduleDate={changeSheduleDate}
        changeSheduleVisible={changeSheduleVisible}
        isAdmin={isAdmin}
      />
      {isAdmin && (
        <button
          className={classNames(
            styles["save-button"],
            initShedule !== shedule && styles["save-button-visible"]
          )}
          onClick={updateShedule}
        >
          Сохранить изменения
        </button>
      )}
    </div>
  );
};

export default memo(CourseSheduleTab);
