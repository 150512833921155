import { FC } from "react";
import { Dialog } from "primereact/dialog";
import { THeadCourse, THeadCourseInfo } from "typings/headCourse";

import styles from "../components/Admins/Admins.module.scss";

type RemoveHeadCoursePopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  selectedHeadCourse: THeadCourse | THeadCourseInfo | null;
  deleteHeadCourse: (headCourseId: number) => void;
};

const RemoveHeadCoursePopup: FC<RemoveHeadCoursePopupProps> = (props) => {
  const removeCourse = () => {
    if (props.selectedHeadCourse) props.deleteHeadCourse(props.selectedHeadCourse.id);
    else return;
  };

  return (
    <Dialog
      visible={props.isOpen}
      style={{ width: "345px" }}
      modal
      onHide={props.handleHide}
      header={`Удаление курса`}
    >
      <div className={styles["remove-admin-popup-wrapper"]}>
        <i className="pi pi-info-circle"></i>

        <h1>Вы уверены, что хотите удалить следующий курс?</h1>

        <span>{props.selectedHeadCourse?.name}</span>

        <p></p>

        <div className={styles["remove-admin-btn-wrap"]}>
          <button className={styles["cancel-btn"]} onClick={props.handleHide}>
            Отмена
          </button>
          <button className={styles["remove-btn"]} onClick={removeCourse}>
            Удалить
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default RemoveHeadCoursePopup;
