import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { getRoles } from "utils/getRoles";
import { classNames } from "primereact/utils";
import { CustomBreadCrumb } from "components/BreadCrumb/BreadCrumb";
import { TabPanel, TabView } from "primereact/tabview";
import { UPDATE_HEAD_COURSE_PAGE_MENU } from "constants/head-course";
import { THeadCourseInfo, TUpdateHeadCourse } from "typings/headCourse";
import { FC, memo, useCallback, useMemo, useRef, useState } from "react";

import styles from "./UpdateHeadCourse.module.scss";
import CourseContentTab from "./tabs/content/Content";
import CourseSettingsTab from "./tabs/settings/Settings";

type UpdateCourseLayoutProps = {
  headCourseInfo: THeadCourseInfo | null;
  updateHeadCourse: (headCourseData: TUpdateHeadCourse) => void;
  handleOpenRemoveHeadCoursePopup: () => void;
  handleOpenCreateCoursePopup: () => void;
};

const UpdateHeadCourseLayout: FC<UpdateCourseLayoutProps> = (props) => {
  const [openMenuTab, setOpenMenuTab] = useState<any>(UPDATE_HEAD_COURSE_PAGE_MENU[0].name);

  const actionPageMenuRef = useRef(null);

  const isAdmin = getRoles();

  const activeMenuIndex = useMemo(
    () => UPDATE_HEAD_COURSE_PAGE_MENU.findIndex((el) => el.name === openMenuTab),
    [openMenuTab]
  );

  const ACTION_PAGE_BTN = [
    {
      label: "Редактировать курс",
      icon: "pi pi-pencil",
      command: () => {
        setOpenMenuTab(UPDATE_HEAD_COURSE_PAGE_MENU[1].name);
      },
    },
    {
      label: "Удалить курс",
      icon: "pi pi-trash",
      command: props.handleOpenRemoveHeadCoursePopup,
    },
  ];

  const renderActiveTab = useCallback(() => {
    switch (openMenuTab) {
      case UPDATE_HEAD_COURSE_PAGE_MENU[0].name:
        return props.headCourseInfo && props.headCourseInfo?.courses.length < 1 ? (
          <div className={styles["empty-content"]}>
            <i className="pi pi-info-circle" />
            <p>Курс пуст. Вы еще не добавили ни одного степа!</p>
          </div>
        ) : (
          <CourseContentTab headCourseInfo={props.headCourseInfo} />
        );
      case UPDATE_HEAD_COURSE_PAGE_MENU[1].name:
        return (
          <CourseSettingsTab
            headCourseInfo={props.headCourseInfo}
            updateHeadCourse={props.updateHeadCourse}
          />
        );
    }
  }, [openMenuTab, props.headCourseInfo]);

  return (
    <div className={styles["main-wrapper"]}>
      <div className={styles["main-wrapper-header"]}>
        <div className={styles["main-wrapper-header-title"]}>
          <span>{props.headCourseInfo?.name}</span>
          <span className={styles["main-wrapper-breadcrumb-wrapper"]}>
            <CustomBreadCrumb
              home={{ label: "Все курсы", url: "/courses", icon: "pi pi-home" }}
              items={[
                {
                  label: props.headCourseInfo?.name || "Без названия",
                  url: `/courses/update_course?course_id=${props.headCourseInfo?.id}`,
                  disabled: true,
                },
              ]}
            />
          </span>
        </div>
        <div className={styles["actions-container"]}>
          {isAdmin && (
            <div className={styles["main-wrapper-header-actions"]}>
              <Menu model={ACTION_PAGE_BTN} popup ref={actionPageMenuRef} id="actionPageMenuRef" />
              <Button
                label="Действия"
                icon="pi pi-bolt"
                //@ts-ignore
                onClick={(event) => actionPageMenuRef.current.toggle(event)}
                aria-controls="actionPageMenuRef"
                aria-haspopup
                className={classNames(
                  styles["main-wrapper-header-actions-button"],
                  styles["main-wrapper-header-actions-action-button"],
                  openMenuTab !== UPDATE_HEAD_COURSE_PAGE_MENU[0].name && styles["allone_btn"]
                )}
              />

              <Button
                label="Добавить степ"
                icon="pi pi-plus"
                onClick={props.handleOpenCreateCoursePopup}
                className={classNames(
                  styles["main-wrapper-header-actions-button"],
                  styles["main-wrapper-header-actions-add-button"],
                  openMenuTab !== UPDATE_HEAD_COURSE_PAGE_MENU[0].name && styles["hide"],
                  props.headCourseInfo &&
                    props.headCourseInfo.isFree &&
                    props.headCourseInfo.courses.length > 0 &&
                    styles["hide"]
                )}
              />
            </div>
          )}
        </div>
      </div>

      <div className={styles["main-wrapper-menu"]}>
        <TabView
          activeIndex={activeMenuIndex}
          onTabChange={(e) => setOpenMenuTab(UPDATE_HEAD_COURSE_PAGE_MENU[e.index].name)}
        >
          {UPDATE_HEAD_COURSE_PAGE_MENU.map((el) => (
            <TabPanel
              key={el.name}
              headerTemplate={(options) => (
                <span
                  className={classNames(
                    options.className,
                    styles["main-wrapper-menu-item"],
                    openMenuTab === el.name && styles["main-wrapper-menu-active-item"]
                  )}
                  onClick={options.onClick}
                >
                  <p>{el.label}</p>
                </span>
              )}
            />
          ))}
        </TabView>
      </div>
      <div className={styles["main-wrapper-content"]}>{renderActiveTab()}</div>
    </div>
  );
};

export default memo(UpdateHeadCourseLayout);
