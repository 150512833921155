import AppWrapper from "AppWrapper";
import FilesStoragePopup from "components/comon/file-storage/files-storage";

import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const accessToken = localStorage.getItem("accessToken");

  if (!accessToken) return <Navigate to={"/login"} />;

  return (
    <>
      <AppWrapper>
        <Outlet />
        <FilesStoragePopup />
      </AppWrapper>
    </>
  );
};

export default PrivateRoute;
