export const UPDATE_HEAD_COURSE_PAGE_MENU: {
  label: string;
  name: string;
}[] = [
  {
    label: "Содержание",
    name: "content",
  },
  {
    label: "Настройки",
    name: "settings",
  },
];
