import { FC, memo, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Ripple } from "primereact/ripple";
import { tableControlsType } from "typings/table-controls";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { TStudent } from "typings/students";
import formattingDate from "utils/formattingDate";
import styles from "./students-table.module.scss";

type StudentsTableProps = {
  studentsList: TStudent[];
  handleSelectedStudent: (student: TStudent) => void;
  isLoadingTable: boolean;
  params: tableControlsType;
  totalRecords: number;
  setParams: React.Dispatch<React.SetStateAction<tableControlsType>>;
  handleOpenRemoveStudentPopup: () => void;
  handleOpenEditStudentPopup: () => void;
  handleOpenStudentInfoPopup: () => void;
};

const StudentsTable: FC<StudentsTableProps> = (props) => {
  const [currentPage, setCurrentPage] = useState<number | string>(1);
  const [pageInputTooltip, setPageInputTooltip] = useState<string>(
    `Нажмите 'Enter' чтобы перейти к нужной странице.`
  );

  const renderColumn = (value: string) => (rowData: TStudent) => {
    return (
      <div
        className="col-wrapper"
        onClick={() => {
          props.handleSelectedStudent(rowData);
        }}
      >
        <p className="table-text">{rowData[value]}</p>
      </div>
    );
  };

  const renderName = (rowData: TStudent) => {
    return (
      <div
        className="col-wrapper"
        onClick={() => {
          props.handleSelectedStudent(rowData);
        }}
      >
        <p className="table-text">{rowData.firstName + " " + rowData.lastName}</p>
      </div>
    );
  };

  const renderDate = (value: string) => (rowData: TStudent) => {
    return (
      <div
        className="col-wrapper"
        onClick={() => {
          props.handleSelectedStudent(rowData);
        }}
      >
        <p className="table-text">{formattingDate(rowData[value], "dd.mm.yyyy")}</p>
      </div>
    );
  };

  const renderAction = (value: string) => (rowData: TStudent) => {
    return (
      <div
        className={styles["col-action-wrapper"]}
        onClick={() => {
          props.handleSelectedStudent(rowData);
        }}
      >
        <button
          className={classNames(styles["action-btn"], styles["info-btn"])}
          onClick={props.handleOpenStudentInfoPopup}
        >
          <i className="pi pi-info-circle" />
        </button>
        <button
          className={classNames(styles["action-btn"], styles["edit-btn"])}
          onClick={props.handleOpenEditStudentPopup}
        >
          <i className="pi pi-pencil" />
        </button>
        <button
          className={classNames(styles["action-btn"], styles["delete-btn"])}
          onClick={props.handleOpenRemoveStudentPopup}
        >
          <i className="pi pi-trash" />
        </button>
      </div>
    );
  };

  const onPage = (event) => {
    props.setParams(event);
  };

  const onPageInputChange = (event) => {
    setCurrentPage(event.target.value);
  };

  const onPageInputKeyDown = (event, options) => {
    if (event.key === "Enter") {
      const page = typeof currentPage === "string" ? parseInt(currentPage) : currentPage;
      if (page < 1 || page > options.totalPages) {
        setPageInputTooltip(`Значние должно быть от 1 до ${options.totalPages}.`);
      } else {
        setPageInputTooltip(`Нажмите 'Enter' чтобы перейти к нужной странице.`);
        props.setParams((prev) => {
          return { ...prev, page: page - 1 };
        });
      }
    }
  };

  const paginatorTemplate = {
    layout: "NextPageLink PageLinks CurrentPageReport",
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-3">Следующая</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options) => {
      if (options.view.endPage === options.page && options.page + 1 !== options.totalPages) {
        const className = classNames(options.className, {
          "p-disabled": true,
        });

        return (
          <>
            <span className={className} style={{ userSelect: "none" }}>
              ...
            </span>

            <span className={className}>{options.totalPages}</span>
          </>
        );
      } else if (options.view.startPage === options.page && options.view.startPage !== 0) {
        const className = classNames(options.className, {
          "p-disabled": true,
        });
        return (
          <>
            <span className={className}>1</span>
            <span className={className} style={{ userSelect: "none" }}>
              ...
            </span>
          </>
        );
      }
      return (
        <button type="button" className={options.className} onClick={options.onClick}>
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span className="paginator-table-input-wrapper">
          Перейти к
          <InputText
            className="ml-1 paginator-table-input"
            value={currentPage}
            tooltip={pageInputTooltip}
            onKeyDown={(e) => onPageInputKeyDown(e, options)}
            onChange={onPageInputChange}
          />
        </span>
      );
    },
  };

  const paginatorRight = () => {
    return (
      <div className={styles["paginator-buttons"]}>
        <Button className="p-button p-button-inverted" disabled>
          <i className="pi pi-download" />
          Import
        </Button>
        <Button className="p-button p-button-inverted" disabled>
          <i className="pi pi-upload" />
          Export
        </Button>
      </div>
    );
  };

  return (
    <div>
      <DataTable
        value={props.studentsList}
        responsiveLayout="scroll"
        paginator
        lazy
        //@ts-ignore
        paginatorTemplate={paginatorTemplate}
        first={props.params.first}
        rows={15}
        onPage={onPage}
        showGridlines
        loading={props.isLoadingTable}
        totalRecords={props.totalRecords}
        paginatorRight={paginatorRight}
      >
        <Column field="id" header="ID" style={{ width: "10%" }} body={renderColumn("id")}></Column>
        <Column field="fio" header="ФИО" style={{ width: "20%" }} body={renderName}></Column>
        <Column
          field="createdAt"
          header="Создано"
          style={{ width: "10%" }}
          body={renderDate("createdAt")}
        ></Column>
        <Column
          field="updatedAt"
          header="Обновлено"
          style={{ width: "10%" }}
          body={renderDate("updatedAt")}
        ></Column>
        <Column
          field="email"
          header="Email"
          style={{ width: "20%" }}
          body={renderColumn("email")}
        ></Column>
        <Column
          field="balance"
          header="Женьги"
          style={{ width: "20%" }}
          body={renderColumn("balance")}
        ></Column>
        <Column
          field="Column 5"
          header="Действия"
          style={{ width: "10%" }}
          body={renderAction("action")}
        ></Column>
      </DataTable>
    </div>
  );
};

export default memo(StudentsTable);
