import { RingLoader } from "react-spinners";
import { classNames } from "primereact/utils";
import { ProgressBar } from "primereact/progressbar";
import { CSSProperties, FC } from "react";

import styles from "./ProgressLoader.module.scss";

type LoaderProps = {
  loading: boolean;
  progress: number;
};

export const ProgressLoader: FC<LoaderProps> = ({ loading, progress }) => {
  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
  };

  return (
    <div
      className={classNames(styles.loaderWrapper, loading ? styles.showLoader : styles.hideLoader)}
    >
      <RingLoader
        color={"#771feb"}
        loading={loading}
        cssOverride={override}
        size={150}
        speedMultiplier={1}
      />
      <div className={styles["back"]}></div>
      <ProgressBar value={progress} className={styles["progress"]}></ProgressBar>
    </div>
  );
};
