// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Students_main-wrapper__ygr71 {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: -20px;
}
.Students_main-wrapper-table__k9DZ- {
  margin-top: 10px;
}
.Students_main-wrapper-header__gf\\+Gk {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Students_main-wrapper-header__gf\\+Gk button {
  max-width: 180px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(2, 173, 2);
  border: 1px solid rgb(2, 173, 2);
  white-space: nowrap;
}
.Students_main-wrapper-header__gf\\+Gk button:hover {
  background-color: rgb(4, 112, 4) !important;
  border: 1px solid rgb(4, 112, 4) !important;
}
.Students_main-wrapper-header__gf\\+Gk button i {
  margin-right: 6px;
}`, "",{"version":3,"sources":["webpack://./src/components/Courses/UpdateCourse/tabs/students/Students.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,iBAAA;AACF;AACE;EACE,gBAAA;AACJ;AAEE;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;AAAJ;AAEI;EACE,gBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gCAAA;EACA,gCAAA;EACA,mBAAA;AAAN;AACM;EACE,2CAAA;EACA,2CAAA;AACR;AACM;EACE,iBAAA;AACR","sourcesContent":[".main-wrapper {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  margin-top: -20px;\n\n  &-table {\n    margin-top: 10px;\n  }\n\n  &-header {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n\n    button {\n      max-width: 180px;\n      width: 100%;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      background-color: rgb(2, 173, 2);\n      border: 1px solid rgb(2, 173, 2);\n      white-space: nowrap;\n      &:hover {\n        background-color: rgb(4, 112, 4) !important;\n        border: 1px solid rgb(4, 112, 4) !important;\n      }\n      i {\n        margin-right: 6px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-wrapper": `Students_main-wrapper__ygr71`,
	"main-wrapper-table": `Students_main-wrapper-table__k9DZ-`,
	"main-wrapper-header": `Students_main-wrapper-header__gf+Gk`
};
export default ___CSS_LOADER_EXPORT___;
