import { phrases } from "constants/phrases";
import { getRoles } from "utils/getRoles";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { TTarifInfo } from "typings/tarifs";
import { classNames } from "primereact/utils";
import { useNavigate } from "react-router";
import { useAppDispatch } from "utils/hooks";
import { TCourseComposition } from "typings/courses";
import { TARIFS_COURSE_AVAILABLE_VALUES } from "constants/tarifs";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { FC, useCallback, useEffect, useState } from "react";
import { getCourseCompositionAsync, getTarifById, updateTarifAsync } from "api/tarifs";

import styles from "./UpdateTarif.module.scss";
import TarifChildrens from "./TarifChildrens";
import TarifComposition from "./TarifComposition";

type UpdateTarifProps = {
  courseId: string | null | undefined;
  headCourseId: string | null | undefined;
  tarifId: string | null | undefined;
  changeTarifId: (tarifID: string | null) => void;
};

const UpdateTarif: FC<UpdateTarifProps> = (props) => {
  const [tarifInfo, setTarifInfo] = useState<TTarifInfo | null>(null);
  const [tarif, setTarif] = useState<TTarifInfo | null>(null);
  const [courseComposition, setCourseComposition] = useState<TCourseComposition | null>(null);

  const isAdmin = getRoles();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const changeComposition = (newComposition: string[]) =>
    setTarifInfo((prev) => prev && { ...prev, composition: newComposition });

  const changeLessonPermissions = (newLessonIds: number[]) =>
    setTarifInfo((prev) => prev && { ...prev, lessonIds: newLessonIds });

  const getTarifInfo = useCallback(async () => {
    try {
      if (props.tarifId) {
        const response = await getTarifById(props.tarifId);
        if (response.status === 200 || response.status === 201) {
          setTarifInfo(response.data);
          setTarif(response.data);
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {
      props.changeTarifId(null);
    }
  }, [dispatch, props.tarifId]);

  const getCourseComposition = useCallback(async () => {
    try {
      if (props.courseId) {
        const response = await getCourseCompositionAsync(props.courseId);
        if (response.status === 200 || response.status === 201) {
          setCourseComposition(response.data);
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
    }
  }, [dispatch, props.courseId]);

  const updateTarif = useCallback(async () => {
    try {
      if (props.tarifId && tarifInfo) {
        const response = await updateTarifAsync(props.tarifId, tarifInfo);
        if (response.status === 200 || response.status === 201) {
          dispatch(addSuccessMessage(phrases.tarif_success_updated));
          getTarifInfo();
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
    }
  }, [dispatch, props.tarifId, tarifInfo]);

  useEffect(() => {
    getTarifInfo();
    getCourseComposition();
  }, [getTarifInfo]);

  return (
    <div className={styles["main-wrapper"]}>
      <h1>Редактирование тарифа</h1>
      <div className={styles["header"]}>
        <span
          className={styles["back-content"]}
          onClick={() =>
            navigate(
              `/courses/update_step?step_id=${props.courseId}&course_id=${props.headCourseId}`
            )
          }
        >
          <i className="pi pi-arrow-left" />
          <p>Вернуться к степу</p>
        </span>
      </div>
      <div className={styles["content"]}>
        <div className={styles["form-row"]}>
          <p className={styles["form-row-header"]}>Название тарифа</p>
          <InputText
            id="name"
            name="name"
            placeholder="Название тарифа"
            value={tarifInfo?.name || ""}
            onChange={(e) => {
              setTarifInfo((prev) => (prev && { ...prev, name: e.target.value }) || prev);
            }}
            disabled={!isAdmin}
          />
        </div>
        <div className={styles["form-row"]}>
          <p className={styles["form-row-header"]}>Тип тарифа</p>
          <InputText
            id="type"
            name="type"
            placeholder="Введите текст"
            value={tarifInfo?.type || ""}
            onChange={(e) => {
              setTarifInfo((prev) => (prev && { ...prev, type: e.target.value }) || prev);
            }}
            disabled={!isAdmin}
          />
        </div>
        <div className={styles["form-row"]}>
          <p className={styles["form-row-header"]}>Состав тарифа</p>
          <TarifComposition
            isAdmin={isAdmin}
            composition={tarifInfo?.composition}
            changeComposition={changeComposition}
          />
        </div>
        <div className={styles["form-row"]}>
          <p className={styles["form-row-header"]}>Доступы к контенту (Уроки и модули)</p>
          <TarifChildrens
            courseComposition={courseComposition}
            isAdmin={isAdmin}
            lessonIds={tarifInfo?.lessonIds}
            changeLessonPermissions={changeLessonPermissions}
          />
        </div>
        <div className={styles["form-row"]}>
          <p className={styles["form-row-header"]}>Доступы к контенту (Домашнее задание)</p>
          <div className={styles["chek-wrap"]}>
            <Checkbox
              onChange={() => {
                setTarifInfo((prev) => prev && { ...prev, isCheckHomeWork: !prev.isCheckHomeWork });
              }}
              checked={tarifInfo?.isCheckHomeWork}
              disabled={!isAdmin}
            ></Checkbox>
            <label className="p-checkbox-label">Домашнее задание с проверкой куратора</label>
          </div>
          <div className={styles["chek-wrap"]}>
            <Checkbox
              onChange={() => {
                setTarifInfo(
                  (prev) => prev && { ...prev, isSkipStopLesson: !prev.isSkipStopLesson }
                );
              }}
              checked={tarifInfo?.isSkipStopLesson}
              disabled={!isAdmin}
            ></Checkbox>
            <label className="p-checkbox-label">Не учитывать стоп-уроки</label>
          </div>
          <div className={styles["chek-wrap"]}>
            <Checkbox
              onChange={() => {
                setTarifInfo((prev) => prev && { ...prev, isChatWithAdmin: !prev.isChatWithAdmin });
              }}
              checked={tarifInfo?.isChatWithAdmin}
              disabled={!isAdmin}
            ></Checkbox>
            <label className="p-checkbox-label">Есть чат с куратором</label>
          </div>
        </div>
        <div className={styles["form-row"]}>
          <p className={styles["form-row-header"]}>Доступ к курсу</p>
          <div className={styles["course-available-row"]}>
            <Dropdown
              options={TARIFS_COURSE_AVAILABLE_VALUES}
              onChange={(e) =>
                setTarifInfo((prev) => prev && { ...prev, countWeekAccess: e.value })
              }
              value={tarifInfo?.countWeekAccess}
              placeholder="Выберите количество недель"
              className={styles["available-dropdown"]}
              optionLabel="name"
              optionValue="value"
            />
            <p>недель</p>
          </div>
        </div>

        <div
          className={classNames(
            styles["btn-wrapper"],
            // Сортировка для сравнения 2х объектов. Сортируются lessonIds т.к. в ui конструкторе
            // после удаления одного элемента, а потом его обратного добавления - он добавляется в конец массива
            // и получается, что контент массивов одинаковый, но идексы элементов изменились.
            JSON.stringify(
              tarif && { ...tarif, lessonIds: tarif.lessonIds.sort((a, b) => a - b) }
            ) !==
              JSON.stringify(
                tarifInfo && { ...tarifInfo, lessonIds: tarifInfo.lessonIds.sort((a, b) => a - b) }
              ) && styles["btn-wrapper-visible"]
          )}
        >
          <button className={styles["save-btn"]} onClick={updateTarif}>
            Сохранить
          </button>
          <button className={styles["cancel-btn"]} onClick={() => setTarifInfo(tarif)}>
            Отменить
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateTarif;
