import { useAppSelector } from "utils/hooks";
import { FC } from "react";
// import { TLessonComponent } from "typings/lesson";
// import { LESSON_TYPE_COMPONENTS } from "constants/lesson";

// import CustomLessonNetVideoProps from "./Components/NetVideo/NetVideo";
// import CustomLessonSeparator from "./Components/Separator/Separator";
// import CustomLessonHeader from "./Components/Header/Header";
// import CustomLessonImage from "./Components/Image/Image";
// import CustomLessonVideo from "./Components/Video/Video";
// import CustomLessonAudio from "./Components/Audio/Audio";
// import CustomLessonText from "./Components/Text/Text";
// import CustomLessonFile from "./Components/File/File";

import styles from "./Preview.module.scss";
import CustomLessonImage from "./Components/Image/Image";
import CustomLessonVideo from "./Components/Video/Video";

type LessonPreviewLayoutProps = {};

const LessonPreviewLayout: FC<LessonPreviewLayoutProps> = () => {
  const { lessonInfo } = useAppSelector((state) => state.lessonConstructor);

  // const renderItem = useCallback(
  //   (el: TLessonComponent) => {
  //     switch (el.type) {
  //       case LESSON_TYPE_COMPONENTS.TEXT:
  //         return <CustomLessonText text={el.content} />;

  //       case LESSON_TYPE_COMPONENTS.IMAGE:
  //         return <CustomLessonImage url={el.content} alt="image" />;

  //       case LESSON_TYPE_COMPONENTS.VIDEO:
  //         return <CustomLessonVideo url={el.content} />;

  //       case LESSON_TYPE_COMPONENTS.AUDIO:
  //         return <CustomLessonAudio url={el.content} />;

  //       case LESSON_TYPE_COMPONENTS.FILE:
  //         return <CustomLessonFile url={el.content} />;

  //       case LESSON_TYPE_COMPONENTS.NET_VIDEO:
  //         return <CustomLessonNetVideoProps url={el.content} />;

  //       case LESSON_TYPE_COMPONENTS.SEPARATOR:
  //         return <CustomLessonSeparator />;

  //       case LESSON_TYPE_COMPONENTS.HEADER:
  //         return <CustomLessonHeader text={el.content} />;

  //       default:
  //         return <CustomLessonText text={el.content} />;
  //     }
  //   },
  //   [lessonInfo]
  // );

  return (
    <div className={styles["main-wrapper"]}>
      <h1 className={styles["title"]}>{lessonInfo.name}</h1>
      <div className={styles["preview-layout"]}>
        <div className={styles["preview-image"]}>
          {lessonInfo.imageUrl ? (
            <CustomLessonImage url={lessonInfo.imageUrl} alt="lesson_preview_image" />
          ) : (
            <div className={styles["no-image"]}>Превью не загружено</div>
          )}
        </div>
        <div>
          <div className={styles["playlist-row"]}>
            <span>Описание:</span>
            <p>{lessonInfo.description || "---"}</p>
          </div>
          <div className={styles["playlist-row"]}>
            <span>Длительность:</span>
            <p>{lessonInfo.durationMin || "---"}</p>
          </div>
          <div className={styles["playlist-row"]}>
            <span>Spotify:</span>
            <p>{lessonInfo.playlist?.links?.spotify || "---"}</p>
          </div>
          <div className={styles["playlist-row"]}>
            <span>Apple music:</span>
            <p>{lessonInfo.playlist?.links?.appleMusic || "---"}</p>
          </div>
          <div className={styles["playlist-row"]}>
            <span>Яндекс музыка:</span>
            <p>{lessonInfo.playlist?.links?.yandexMusic || "---"}</p>
          </div>
          <div className={styles["playlist-row"]}>
            <span>VK:</span>
            <p>{lessonInfo.playlist?.links?.VK || "---"}</p>
          </div>
          <div className={styles["playlist-row"]}>
            <span>Sound Cloud:</span>
            <p>{lessonInfo.playlist?.links?.soundCloud || "---"}</p>
          </div>
        </div>
      </div>
      {lessonInfo.videoUrl && <CustomLessonVideo url={lessonInfo.videoUrl} />}
      {/* {lessonInfo.blocks.map((el, index) => (
        <div key={index}>{renderItem(el)}</div>
      ))} */}
    </div>
  );
};

export default LessonPreviewLayout;
