// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video_video-wrapper__ubvU8 {
  width: 100%;
  height: 100%;
  position: relative;
}
.video_video-wrapper__ubvU8 video {
  width: 100%;
  height: inherit;
  object-fit: cover;
}
.video_video-wrapper__ubvU8 .video_fake__19B\\+\\+ {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/comon/file-storage/content/video/video.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AACF;AACE;EACE,WAAA;EACA,eAAA;EACA,iBAAA;AACJ;AAEE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;AAAJ","sourcesContent":[".video-wrapper {\r\n  width: 100%;\r\n  height: 100%;\r\n  position: relative;\r\n\r\n  video {\r\n    width: 100%;\r\n    height: inherit;\r\n    object-fit: cover;\r\n  }\r\n\r\n  .fake {\r\n    width: 100%;\r\n    height: 100%;\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"video-wrapper": `video_video-wrapper__ubvU8`,
	"fake": `video_fake__19B++`
};
export default ___CSS_LOADER_EXPORT___;
