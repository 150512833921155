// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProgressLoader_loaderWrapper__HyTkU {
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 100000;
  flex-direction: column;
  padding: 80px;
}
.ProgressLoader_loaderWrapper__HyTkU .ProgressLoader_back__uzpDd {
  width: 100%;
  height: 100%;
  position: absolute;
}
.ProgressLoader_loaderWrapper__HyTkU .ProgressLoader_progress__Bf1Vh {
  height: 25px;
  margin-top: 30px;
  width: 100%;
}
.ProgressLoader_loaderWrapper__HyTkU .ProgressLoader_progress__Bf1Vh div {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.ProgressLoader_showLoader__k6HOq {
  display: flex;
}

.ProgressLoader_hideLoader__r8tEw {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/ProgressLoader/ProgressLoader.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,sBAAA;EACA,aAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AACJ;AAEE;EACE,YAAA;EACA,gBAAA;EACA,WAAA;AAAJ;AACI;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AACR;;AAIA;EACE,aAAA;AADF;;AAIA;EACE,aAAA;AADF","sourcesContent":[".loaderWrapper {\r\n  position: absolute;\r\n  width: 100%;\r\n  height: 100%;\r\n  justify-content: center;\r\n  align-items: center;\r\n  z-index: 100000;\r\n  flex-direction: column;\r\n  padding: 80px;\r\n\r\n  .back {\r\n    width: 100%;\r\n    height: 100%;\r\n    position: absolute;\r\n  }\r\n\r\n  .progress {\r\n    height: 25px;\r\n    margin-top: 30px;\r\n    width: 100%;\r\n    div{\r\n        display: flex;\r\n        align-items: center;\r\n        justify-content: center;\r\n        color: white ;\r\n    }\r\n  }\r\n}\r\n\r\n.showLoader {\r\n  display: flex;\r\n}\r\n\r\n.hideLoader {\r\n  display: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderWrapper": `ProgressLoader_loaderWrapper__HyTkU`,
	"back": `ProgressLoader_back__uzpDd`,
	"progress": `ProgressLoader_progress__Bf1Vh`,
	"showLoader": `ProgressLoader_showLoader__k6HOq`,
	"hideLoader": `ProgressLoader_hideLoader__r8tEw`
};
export default ___CSS_LOADER_EXPORT___;
