import { phrases } from "constants/phrases";
import { TNewCourse } from "typings/courses";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "utils/hooks";
import { createNewCourse } from "api/courses";
import { THeadCourseInfo, TUpdateHeadCourse } from "typings/headCourse";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { FC, memo, useCallback, useEffect, useState } from "react";
import { getHeadCourseById, removeHeadCourse, updateHeadCourseAsync } from "api/headCourses";

import styles from "./UpdateHeadCourse.module.scss";
import ScrollToTop from "react-scroll-to-top";
import CreateCoursePopup from "popups/create-course";
import RemoveHeadCoursePopup from "popups/remove-head-course";
import UpdateHeadCourseLayout from "./UpdateHeadCourse.layout";

type UpdateCourseProps = {
  headCourseId: string | null | undefined;
  changeCourseId: (courseID: string | null) => void;
};

const UpdateHeadCourse: FC<UpdateCourseProps> = (props) => {
  const [headCourseInfo, setHeadCourseInfo] = useState<THeadCourseInfo | null>(null);
  const [isOpenCreateCoursePopup, setIsOpenCreateCoursePopup] = useState<boolean>(false);
  const [isOpenRemoveHeadCoursePopup, setIsOpenRemoveHeadCoursePopup] = useState<boolean>(false);

  const handleOpenCreateCoursePopup = () => setIsOpenCreateCoursePopup(true);
  const handleCloseCreateCoursePopup = () => setIsOpenCreateCoursePopup(false);

  const handleOpenRemoveHeadCoursePopup = () => setIsOpenRemoveHeadCoursePopup(true);
  const handleCloseRemoveHeadCoursePopup = () => setIsOpenRemoveHeadCoursePopup(false);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const getHeadCourseInfo = useCallback(async () => {
    try {
      if (props.headCourseId) {
        const response = await getHeadCourseById(props.headCourseId);
        if (response.status === 200 || response.status === 201) {
          setHeadCourseInfo(response.data);
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {
      props.changeCourseId(null);
    }
  }, [dispatch, props.headCourseId]);

  useEffect(() => {
    getHeadCourseInfo();
  }, [getHeadCourseInfo]);

  const createCourse = useCallback(
    async (courseData: TNewCourse) => {
      try {
        if (props.headCourseId) {
          const response = await createNewCourse({
            ...courseData,
            headCourseId: Number(props.headCourseId),
          });
          if (response.status === 200 || response.status === 201) {
            getHeadCourseInfo();
            navigate(
              `/courses/update_step?step_id=${response.data.id}&course_id=${props.headCourseId}`
            );
            dispatch(addSuccessMessage(phrases.step_success_added));
          } else throw new Error();
        } else throw new Error();
      } catch (err: any) {
        return dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrong));
      } finally {
        handleCloseCreateCoursePopup();
      }
    },
    [dispatch, props.headCourseId]
  );

  const deleteHeadCourse = useCallback(
    async (headCourseId: number) => {
      try {
        const response = await removeHeadCourse(headCourseId);
        if (response.status === 200 || response.status === 201) {
          dispatch(addSuccessMessage(phrases.course_success_deleted));
          navigate("/courses");
          getHeadCourseInfo();
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      } finally {
        handleCloseRemoveHeadCoursePopup();
      }
    },
    [dispatch]
  );

  const updateHeadCourse = useCallback(
    async (courseData: TUpdateHeadCourse) => {
      try {
        if (headCourseInfo) {
          const response = await updateHeadCourseAsync(headCourseInfo?.id, courseData);
          if (response.status === 200 || response.status === 201) {
            getHeadCourseInfo();
            dispatch(addSuccessMessage(phrases.course_success_updated));
          } else throw new Error();
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      }
    },
    [dispatch, headCourseInfo]
  );

  return (
    <>
      <UpdateHeadCourseLayout
        headCourseInfo={headCourseInfo}
        updateHeadCourse={updateHeadCourse}
        handleOpenRemoveHeadCoursePopup={handleOpenRemoveHeadCoursePopup}
        handleOpenCreateCoursePopup={handleOpenCreateCoursePopup}
      />

      <RemoveHeadCoursePopup
        isOpen={isOpenRemoveHeadCoursePopup}
        handleHide={handleCloseRemoveHeadCoursePopup}
        deleteHeadCourse={deleteHeadCourse}
        selectedHeadCourse={headCourseInfo}
      />

      <CreateCoursePopup
        isOpen={isOpenCreateCoursePopup}
        handleHide={handleCloseCreateCoursePopup}
        createCourse={createCourse}
      />

      <ScrollToTop
        smooth
        component={<i className="pi pi-chevron-up"></i>}
        className={styles["up-btn"]}
      />
    </>
  );
};

export default memo(UpdateHeadCourse);
