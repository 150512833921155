import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import styles from "../components/Courses/UpdateCourse/UpdateCourse.module.scss";
import UpdateCourse from "components/Courses/UpdateCourse/UpdateCourse";

const UpdateCoursesPage = () => {
  const [stepId, setStepId] = useState<string | null>();
  const [courseId, setCourseId] = useState<string | null>();

  let { search } = useLocation();

  const navigate = useNavigate();

  const changeCourseId = (stepID: string | null) => setStepId(stepID);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const stepId: string | null = params.get("step_id");
    const courseId: string | null = params.get("course_id");
    setStepId(stepId);
    setCourseId(courseId);
  }, []);

  const renderPageLayout = useCallback(() => {
    if (stepId)
      return (
        <UpdateCourse courseId={stepId} headCourseId={courseId} changeCourseId={changeCourseId} />
      );
    else
      return (
        <div className={styles["empty-page"]}>
          <i className="pi pi-minus-circle" />
          <p>К сожалению, степ не найден!</p>
          <Button
            className="p-button p-button-inverted"
            onClick={() => {
              navigate("/courses");
            }}
          >
            Вернуть к списку курсов
          </Button>
        </div>
      );
  }, [stepId]);

  return renderPageLayout();
};

export default UpdateCoursesPage;
