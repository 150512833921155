import { FC, memo } from "react";
import { useAppDispatch, useAppSelector } from "utils/hooks";
// import {
//   ADD_LESSON_CONPONENTS_SHORT_LIST,
//   CHAGE_POSITION,
//   LESSON_TYPE_COMPONENTS,
// } from "constants/lesson";

// import Separator from "./Separator/Separator";
// import SettingsContainer from "./SettingsContainer/SettingsContainer";
// import CustomEditText from "./Components/Text/Text";
// import CustomEditImage from "./Components/Image/Image";
// import CustomEditSeparator from "./Components/Separator/Separator";
// import CustomEditHeader from "./Components/Header/Header";
// import CustomEditVideo from "./Components/Video/Video";
// import CustomEditAudio from "./Components/Audio/Audio";
// import CustomEditFile from "./Components/File/File";
// import CustomEditNetVideo from "./Components/NetVideo/NetVideo";

import styles from "./Constructor.module.scss";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { updateComponent } from "slices/lessonConstructor";
import { classNames } from "primereact/utils";
import { openFileStoragePopup } from "slices/fileStoragePopupSlice";
import { IMAGE_COUNTER_LABEL_LIST, VIDEO_COUNTER_LABEL_LIST } from "constants/lesson";
import { InputNumber } from "primereact/inputnumber";
import LessonTrackList from "./TrackList";
import { Tooltip } from "primereact/tooltip";
import LessonTimeCodeList from "./TimeCode";

type LessonConstructorLayoutProps = {
  changeItemPosition: (indexItem: number, type: string) => void;
  removeItemFunc: (indexItem: number) => void;
  addItemFunc: (indexItem: number, type: string, typeItem: string) => void;
  changeComponentFunc: (el: number, value: string) => void;
  handleOpenAddLessonItemPopup: (indexItem: number, type: string) => void;
};

const LessonConstructorLayout: FC<LessonConstructorLayoutProps> = (props) => {
  const { lessonInfo } = useAppSelector((state) => state.lessonConstructor);

  const dispatch = useAppDispatch();

  // const renderLessonComponent = useCallback(
  //   (component, index) => {
  //     switch (component.type) {
  //       case LESSON_TYPE_COMPONENTS.TEXT:
  //         return (
  //           <CustomEditText
  //             component={component}
  //             changeComponentFunc={props.changeComponentFunc}
  //             index={index}
  //           />
  //         );
  //       case LESSON_TYPE_COMPONENTS.IMAGE:
  //         return <CustomEditImage component={component} index={index} />;
  //       case LESSON_TYPE_COMPONENTS.VIDEO:
  //         return <CustomEditVideo component={component} index={index} />;
  //       case LESSON_TYPE_COMPONENTS.AUDIO:
  //         return (
  //           <CustomEditAudio
  //             component={component}
  //             changeComponentFunc={props.changeComponentFunc}
  //             index={index}
  //           />
  //         );
  //       case LESSON_TYPE_COMPONENTS.FILE:
  //         return (
  //           <CustomEditFile
  //             component={component}
  //             changeComponentFunc={props.changeComponentFunc}
  //             index={index}
  //           />
  //         );
  //       case LESSON_TYPE_COMPONENTS.SEPARATOR:
  //         return <CustomEditSeparator />;
  //       case LESSON_TYPE_COMPONENTS.NET_VIDEO:
  //         return (
  //           <CustomEditNetVideo
  //             component={component}
  //             changeComponentFunc={props.changeComponentFunc}
  //             index={index}
  //           />
  //         );
  //       case LESSON_TYPE_COMPONENTS.HEADER:
  //         return (
  //           <CustomEditHeader
  //             component={component}
  //             changeComponentFunc={props.changeComponentFunc}
  //             index={index}
  //           />
  //         );
  //     }
  //   },
  //   [lessonInfo]
  // );

  const imageFileTypes = ["JPEG", "PNG", "JPG"];
  const videoFileTypes = ["MP4"];

  const handleOpenFileStoragePopup = (type: string) => {
    dispatch(
      openFileStoragePopup({
        type: type,
        fileTypes: type === "image" ? imageFileTypes : videoFileTypes,
        counterLabels: type === "image" ? IMAGE_COUNTER_LABEL_LIST : VIDEO_COUNTER_LABEL_LIST,
        isLessonEdit: true,
      })
    );
  };

  return (
    <div className={styles["main-wrapper"]}>
      {/* <div className={styles["constructor-header"]}>
        <div className={styles["constructor-header-tools"]}>
          {ADD_LESSON_CONPONENTS_SHORT_LIST.map((el, index) => (
            <span onClick={() => props.addItemFunc(0, "", el.type)} key={index}>
              <i className="pi pi-align-left" />
              <p>{el.label}</p>
            </span>
          ))}
        </div>
      </div> */}
      {/* {lessonInfo.blocks.map((el, index) => (
        <div key={index}>
          {index == 0 && (
            <Separator
              indexItem={index}
              type={CHAGE_POSITION.UP}
              handleOpenAddLessonItemPopup={props.handleOpenAddLessonItemPopup}
            />
          )}
          <SettingsContainer
            el={el}
            changeItemPosition={props.changeItemPosition}
            removeItemFunc={props.removeItemFunc}
            indexItem={index}
          >
            {renderLessonComponent(el, index)}
          </SettingsContainer>
          <Separator
            indexItem={index}
            type={CHAGE_POSITION.DOWN}
            handleOpenAddLessonItemPopup={props.handleOpenAddLessonItemPopup}
          />
        </div>
      ))} */}

      <div className={styles["input-wrapper"]}>
        <p>Заголовок</p>
        <InputText
          name="name"
          value={lessonInfo.name}
          onChange={(e) => {
            dispatch(updateComponent({ property: "name", value: e.target.value }));
          }}
          placeholder="Введите имя"
        />
      </div>
      <div className={styles["input-wrapper"]}>
        <p>Длительность</p>
        <input
          value={lessonInfo.durationMin}
          onChange={(e) => {
            console.log(e.target.value);
            dispatch(updateComponent({ property: "durationMin", value: Number(e.target.value) }));
          }}
          min={0}
          max={9999}
          placeholder="Введите длительность"
          className={classNames(styles["form-item"], styles["inputnumber"], "duration")}
          pattern="[0-9]+"
          type="number"
        />
      </div>
      <Tooltip target=".duration" mouseTrack mouseTrackLeft={10}>
        Только числовые значения
      </Tooltip>
      <div className={styles["input-wrapper"]}>
        <p>Основной текст</p>
        <InputTextarea
          value={lessonInfo.description}
          onChange={(e) => {
            dispatch(updateComponent({ property: "description", value: e.target.value }));
          }}
          rows={5}
          cols={30}
          autoResize
          placeholder="Основной текст"
        />
      </div>
      <div className={styles["input-wrapper"]}>
        <p>Видео</p>
        <div
          className={styles["uloader-wrapper"]}
          onClick={() => handleOpenFileStoragePopup("video")}
        >
          <div
            className={classNames(
              styles["video-wrapper"],
              lessonInfo.videoUrl && styles["filled-video-wrapper"]
            )}
          >
            {lessonInfo.videoUrl ? (
              <video controls width="100%" src={lessonInfo.videoUrl} />
            ) : (
              <div className={styles["empty-video-wrapper"]}>
                <span>
                  <i className="pi pi-video" />
                </span>
                <p>Загрузите видео</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles["input-wrapper"]}>
        <p>Превью</p>
        <div
          className={styles["uloader-wrapper"]}
          onClick={() => handleOpenFileStoragePopup("image")}
        >
          <div
            className={classNames(
              styles["image-wrapper"],
              lessonInfo.imageUrl && styles["filled-image-wrapper"]
            )}
          >
            {lessonInfo.imageUrl ? (
              <img alt="Preview" src={lessonInfo.imageUrl} className={styles["img"]}></img>
            ) : (
              <div className={styles["empty-image-wrapper"]}>
                <span>
                  <i className="pi pi-image" />
                </span>
                <p>Загрузите изображение</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles["input-wrapper"]}>
        <p>Spotify</p>
        <InputText
          name="spotify"
          value={lessonInfo.playlist?.links?.spotify}
          onChange={(e) => {
            dispatch(
              updateComponent({
                property: "playlist",
                value: {
                  ...lessonInfo.playlist,
                  links: { ...lessonInfo.playlist.links, spotify: e.target.value },
                },
              })
            );
          }}
          placeholder="Введите ссылку"
        />
      </div>
      <div className={styles["input-wrapper"]}>
        <p>Apple music</p>
        <InputText
          name="appleMusic"
          value={lessonInfo.playlist?.links?.appleMusic}
          onChange={(e) => {
            dispatch(
              updateComponent({
                property: "playlist",
                value: {
                  ...lessonInfo.playlist,
                  links: { ...lessonInfo.playlist.links, appleMusic: e.target.value },
                },
              })
            );
          }}
          placeholder="Введите ссылку"
        />
      </div>
      <div className={styles["input-wrapper"]}>
        <p>Яндекс музыка</p>
        <InputText
          name="yandexMusic"
          value={lessonInfo.playlist?.links?.yandexMusic}
          onChange={(e) => {
            dispatch(
              updateComponent({
                property: "playlist",
                value: {
                  ...lessonInfo.playlist,
                  links: { ...lessonInfo.playlist.links, yandexMusic: e.target.value },
                },
              })
            );
          }}
          placeholder="Введите ссылку"
        />
      </div>
      <div className={styles["input-wrapper"]}>
        <p>VK</p>
        <InputText
          name="VK"
          value={lessonInfo.playlist?.links?.VK}
          onChange={(e) => {
            dispatch(
              updateComponent({
                property: "playlist",
                value: {
                  ...lessonInfo.playlist,
                  links: { ...lessonInfo.playlist.links, VK: e.target.value },
                },
              })
            );
          }}
          placeholder="Введите ссылку"
        />
      </div>
      <div className={styles["input-wrapper"]}>
        <p>Sound Cloud</p>
        <InputText
          name="soundCloud"
          value={lessonInfo.playlist?.links?.soundCloud}
          onChange={(e) => {
            dispatch(
              updateComponent({
                property: "playlist",
                value: {
                  ...lessonInfo.playlist,
                  links: { ...lessonInfo.playlist.links, soundCloud: e.target.value },
                },
              })
            );
          }}
          placeholder="Введите ссылку"
        />
      </div>
      <div className={styles["input-wrapper"]}>
        <p>Вебинар</p>
        <span>Название</span>
        <InputText
          name="webinar-name"
          value={lessonInfo.button?.name}
          onChange={(e) => {
            dispatch(
              updateComponent({
                property: "button",
                value: { ...lessonInfo.button, name: e.target.value },
              })
            );
          }}
          placeholder="Введите имя"
          className={styles["webinar-input"]}
        />
        <span>Ссылка</span>
        <InputText
          name="webinar-url"
          value={lessonInfo.button?.url}
          onChange={(e) => {
            dispatch(
              updateComponent({
                property: "button",
                value: { ...lessonInfo.button, url: e.target.value },
              })
            );
          }}
          placeholder="Введите ссылку"
          className={styles["webinar-input"]}
        />
      </div>
      <div className={styles["input-wrapper"]}>
        <p>Плейлист</p>
        <LessonTrackList lessonInfo={lessonInfo} />
      </div>
      <div className={styles["input-wrapper"]}>
        <p>Таймкод</p>
        <LessonTimeCodeList lessonInfo={lessonInfo} />
      </div>
    </div>
  );
};

export default memo(LessonConstructorLayout);
