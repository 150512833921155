import UpdateModule from "components/Module/UpdateModule/UpdateModule";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import styles from "../components/Courses/UpdateCourse/UpdateCourse.module.scss";

const UpdateModulePage = () => {
  const [moduleId, setModuleId] = useState<string | null>();
  const [courseId, setCourseId] = useState<string | null>();
  const [headCourseId, setHeadCourseId] = useState<string | null>();

  let { search } = useLocation();

  const navigate = useNavigate();

  const changeModuleId = (courseID: string | null) => setModuleId(courseID);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const courseId: string | null = params.get("step_id");
    const moduleId: string | null = params.get("module_id");
    const headCourseId: string | null = params.get("course_id");
    setModuleId(moduleId);
    setCourseId(courseId);
    setHeadCourseId(headCourseId);
  }, []);

  const renderPageLayout = useCallback(() => {
    if (moduleId)
      return (
        <UpdateModule moduleId={moduleId} changeModuleId={changeModuleId} courseId={courseId} headCourseId={headCourseId}/>
      );
    else
      return (
        <div className={styles["empty-page"]}>
          <i className="pi pi-minus-circle" />
          <p>К сожалению, модуль не найден!</p>
          <Button
            className="p-button p-button-inverted"
            onClick={() => {
              courseId
                ? navigate(`/courses/update_course?course_id=${courseId}`)
                : navigate(`/courses`);
            }}
          >
            {courseId ? "Вернуть к курсу" : "Вернуться к списку курсов"}
          </Button>
        </div>
      );
  }, [moduleId]);

  return renderPageLayout();
};

export default UpdateModulePage;
