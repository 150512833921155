import { Column } from "primereact/column";
import { FC, memo } from "react";
import { DataTable } from "primereact/datatable";
import { classNames } from "primereact/utils";
import { TCourseStudent } from "typings/students";

import styles from "./Students.module.scss";
import formattingDate from "utils/formattingDate";

type StudentsTabProps = {
  studentsList: TCourseStudent[];
  isLoadingTable: boolean;
  handleSelectStudent: (student: TCourseStudent) => void;
  handleOpenDeleteStudentsPopup: () => void;
  isAdmin: boolean;
};

const StudentsTab: FC<StudentsTabProps> = (props) => {
  const renderColumn = (value: string) => (rowData: TCourseStudent) => {
    return (
      <div className="col-wrapper" onClick={() => {}}>
        <p className="table-text">{rowData[value]}</p>
      </div>
    );
  };

  const renderDate = (value: string) => (rowData: TCourseStudent) => {
    return (
      <div
        className="col-wrapper"
        onClick={() => {
          props.handleSelectStudent(rowData);
        }}
      >
        <p className="table-text">{formattingDate(rowData[value], "dd.mm.yyyy")}</p>
      </div>
    );
  };

  const renderAction = (rowData: TCourseStudent) => {
    return (
      <div className={styles["col-action-wrapper"]} onClick={() => {}}>
        <button
          className={classNames(styles["action-btn"], styles["delete-btn"])}
          onClick={() => {
            props.handleSelectStudent(rowData);
            props.handleOpenDeleteStudentsPopup();
          }}
        >
          <i className="pi pi-trash" />
        </button>
      </div>
    );
  };

  return (
    <div className={styles["main-wrapper"]}>
      <div className={styles["main-wrapper-table"]}>
        <DataTable
          value={props.studentsList}
          responsiveLayout="scroll"
          lazy
          showGridlines
          loading={props.isLoadingTable}
        >
          <Column
            field="studentId"
            header="ID"
            style={{ width: "10%" }}
            body={renderColumn("studentId")}
          ></Column>
          <Column
            field="fullname"
            header="Ученик"
            style={{ width: "40%" }}
            body={renderColumn("fullName")}
          ></Column>
          <Column
            field="tariffName"
            header="Тариф"
            style={{ width: "20%" }}
            body={renderColumn("tariffName")}
          ></Column>
          <Column
            field="lastActiveAt"
            header="Активность"
            style={{ width: "20%" }}
            body={renderDate("lastActiveAt")}
          ></Column>

          {props.isAdmin && (
            <Column
              field="Column 5"
              header="Действия"
              style={{ width: "10%" }}
              body={renderAction}
            ></Column>
          )}
        </DataTable>
      </div>
    </div>
  );
};

export default memo(StudentsTab);
