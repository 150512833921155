// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Access_main-wrapper__blfso {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.Access_main-wrapper-content__SiZBy {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  column-gap: 40px;
}
.Access_main-wrapper-content-left__cMB05 {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.Access_main-wrapper-content-left-container__Vy\\+7G {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1.5px solid #8f65c7;
  border-radius: 4px;
}
.Access_main-wrapper-content-left-container-header__KA08y {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1.5px solid #8f65c7;
  padding: 12px;
  background-color: rgba(147, 108, 219, 0.1);
}
.Access_main-wrapper-content-left-container-header__KA08y p {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  margin: 0;
}
.Access_main-wrapper-content-left-container-content__sXU8y {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.Access_main-wrapper-content-left-container-content-radio__HL\\+Dw {
  margin-top: 10px;
}
.Access_main-wrapper-content-left-container-content-radio__HL\\+Dw span {
  margin-left: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/Module/UpdateModule/tabs/access/Access.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;AACF;AACE;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,qBAAA;EAAA,gBAAA;AACJ;AACI;EACE,WAAA;EACA,aAAA;EACA,sBAAA;AACN;AACM;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,kBAAA;AACR;AACQ;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,kCAAA;EACA,aAAA;EACA,0CAAA;AACV;AACU;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,SAAA;AACZ;AAGQ;EACE,aAAA;EACA,aAAA;EACA,sBAAA;AADV;AAGU;EACE,gBAAA;AADZ;AAEY;EACE,gBAAA;AAAd","sourcesContent":[".main-wrapper {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n\n  &-content {\n    width: 100%;\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    column-gap: 40px;\n\n    &-left {\n      width: 100%;\n      display: flex;\n      flex-direction: column;\n\n      &-container {\n        width: 100%;\n        display: flex;\n        flex-direction: column;\n        border: 1.5px solid #8f65c7;\n        border-radius: 4px;\n\n        &-header {\n          width: 100%;\n          display: flex;\n          align-items: center;\n          border-bottom: 1.5px solid #8f65c7;\n          padding: 12px;\n          background-color: rgba(147, 108, 219, 0.1);\n\n          p {\n            font-size: 16px;\n            font-weight: 600;\n            color: #000000;\n            margin: 0;\n          }\n        }\n\n        &-content {\n          padding: 20px;\n          display: flex;\n          flex-direction: column;\n\n          &-radio {\n            margin-top: 10px;\n            span {\n              margin-left: 8px;\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-wrapper": `Access_main-wrapper__blfso`,
	"main-wrapper-content": `Access_main-wrapper-content__SiZBy`,
	"main-wrapper-content-left": `Access_main-wrapper-content-left__cMB05`,
	"main-wrapper-content-left-container": `Access_main-wrapper-content-left-container__Vy+7G`,
	"main-wrapper-content-left-container-header": `Access_main-wrapper-content-left-container-header__KA08y`,
	"main-wrapper-content-left-container-content": `Access_main-wrapper-content-left-container-content__sXU8y`,
	"main-wrapper-content-left-container-content-radio": `Access_main-wrapper-content-left-container-content-radio__HL+Dw`
};
export default ___CSS_LOADER_EXPORT___;
