import { getRoles } from "utils/getRoles";
import { RadioButton } from "primereact/radiobutton";
import { memo, useState } from "react";

import styles from "./Access.module.scss";

const ModuleAccessTab = () => {
  const [haveAccess, setHaveAccess] = useState<string | null>(null);
  const [notHaveAccess, setNotHaveAccess] = useState<string | null>(null);

  const isAdmin = getRoles();

  return (
    <div className={styles["main-wrapper"]}>
      <div className={styles["main-wrapper-content"]}>
        <div className={styles["main-wrapper-content-left"]}>
          <div className={styles["main-wrapper-content-left-container"]}>
            <div className={styles["main-wrapper-content-left-container-header"]}>
              <p>Доступ к модулю имеют:</p>
            </div>
            <div className={styles["main-wrapper-content-left-container-content"]}>
              <div
                className={styles["main-wrapper-content-left-container-content-radio"]}
                onClick={() => {}}
              >
                <RadioButton
                  checked={haveAccess === "Только те, кто купил тренинг"}
                  onChange={(e) => setHaveAccess("Только те, кто купил тренинг")}
                  name="Только те, кто купил тренинг"
                  disabled={!isAdmin}
                />
                <span>Только те, кто купил тренинг</span>
              </div>
              <div
                className={styles["main-wrapper-content-left-container-content-radio"]}
                onClick={() => {}}
              >
                <RadioButton
                  checked={haveAccess === "Все зарегистрированные пользователи"}
                  onChange={(e) => setHaveAccess("Все зарегистрированные пользователи")}
                  disabled={!isAdmin}
                />
                <span>Все зарегистрированные пользователи</span>
              </div>
              <div
                className={styles["main-wrapper-content-left-container-content-radio"]}
                onClick={() => {}}
              >
                <RadioButton
                  checked={haveAccess === "Все, у кого есть доступ к родительскому тренингу"}
                  onChange={(e) =>
                    setHaveAccess("Все, у кого есть доступ к родительскому тренингу")
                  }
                  disabled={!isAdmin}
                />
                <span>Все, у кого есть доступ к родительскому тренингу</span>
              </div>
              <div
                className={styles["main-wrapper-content-left-container-content-radio"]}
                onClick={() => {}}
              >
                <RadioButton
                  checked={haveAccess === "Те, кто прошел другой тренинг"}
                  onChange={(e) => setHaveAccess("Те, кто прошел другой тренинг")}
                  disabled={!isAdmin}
                />
                <span>Те, кто прошел другой тренинг</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles["main-wrapper-content-right"]}>
          <div className={styles["main-wrapper-content-left-container"]}>
            <div className={styles["main-wrapper-content-left-container-header"]}>
              <p>Тем, у кого нет доступа:</p>
            </div>
            <div className={styles["main-wrapper-content-left-container-content"]}>
              <div
                className={styles["main-wrapper-content-left-container-content-radio"]}
                onClick={() => {}}
              >
                <RadioButton
                  checked={notHaveAccess === "Не показывать в списке тренингов"}
                  onChange={(e) => setNotHaveAccess("Не показывать в списке тренингов")}
                  disabled={!isAdmin}
                />
                <span>Не показывать в списке тренингов</span>
              </div>
              <div
                className={styles["main-wrapper-content-left-container-content-radio"]}
                onClick={() => {}}
              >
                <RadioButton
                  checked={notHaveAccess === "Показывать в списке тренингов"}
                  onChange={(e) => setNotHaveAccess("Показывать в списке тренингов")}
                  disabled={!isAdmin}
                />
                <span>Показывать в списке тренингов</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ModuleAccessTab);
