import { FC } from "react";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { TStudent } from "typings/students";
import { DataTable } from "primereact/datatable";

import styles from "../components/Admins/Admins.module.scss";

type StudentInfoPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  selectedStudent: TStudent | null;
};

const StudentInfoPopup: FC<StudentInfoPopupProps> = ({ isOpen, handleHide, selectedStudent }) => {
  const renderColumn = (value: string) => (rowData: TStudent) => {
    return (
      <div className="col-wrapper">
        <p className={styles["col-text"]}>{rowData[value]}</p>
      </div>
    );
  };

  return (
    <Dialog
      visible={isOpen}
      style={{ width: "500px" }}
      modal
      onHide={handleHide}
      header={`Информация по студенту`}
    >
      <div className={styles["create-account-popup-wrapper"]}>
        <div className={styles["create-account-input-wrapper"]}>
          <p>Список курсов:</p>
          {selectedStudent?.courses && selectedStudent?.courses.length > 0 ? (
            <DataTable
              value={selectedStudent?.courses}
              className={styles["shedule-table"]}
              responsiveLayout="scroll"
              showGridlines
            >
              <Column
                field="id"
                header="ID"
                style={{ width: "20%" }}
                body={renderColumn("id")}
              ></Column>
              <Column
                field="name"
                header="Курс"
                style={{ width: "80%" }}
                body={renderColumn("name")}
              ></Column>
            </DataTable>
          ) : (
            <i>Нет назначенных курсов</i>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default StudentInfoPopup;
