import { useCallback, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { classNames } from "primereact/utils";
import { CSSTransition } from "react-transition-group";
import { Ripple } from "primereact/ripple";
import { Badge } from "primereact/badge";
import LogoIcon from "./assets/Logo.svg";
import LogoIconMini from "./assets/Jance-mini.png";

import styles from "./components/Login/Login.module.scss";

const AppSubmenu = (props) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const navigate = useNavigate();

  const logout = () => {
    const confirm = window.confirm("Вы уверены, что хотите выйти из системы?");
    if (confirm) {
      localStorage.clear();
      navigate("/login");
    } else return;
  };

  const onMenuItemClick = (event, item, index) => {
    if (item.disabled) {
      event.preventDefault();
      return;
    }

    if (item.command) {
      item.command({ originalEvent: event, item: item });
      event.preventDefault();
    }

    if (item.items) {
      event.preventDefault();
    }

    if (props.root) {
      props.onRootMenuItemClick({
        originalEvent: event,
      });
    } else {
      if (props.menuMode !== "sidebar") {
        const ink = getInk(event.currentTarget);
        if (ink) {
          removeClass(ink, "p-ink-active");
        }
      }
    }

    setActiveIndex(index === activeIndex ? null : index);

    props.onMenuItemClick({
      originalEvent: event,
      item: item,
    });
  };

  const onKeyDown = (event, item, index) => {
    if (event.key === "Enter") {
      onMenuItemClick(event, item, index);
    }
  };

  const getInk = (el) => {
    for (let i = 0; i < el.children.length; i++) {
      if (
        typeof el.children[i].className === "string" &&
        el.children[i].className.indexOf("p-ink") !== -1
      ) {
        return el.children[i];
      }
    }
    return null;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"),
        " "
      );
  };

  const onMenuItemMouseEnter = (index) => {
    if (props.root && props.menuActive && isHorizontalOrSlim() && !isMobile()) {
      setActiveIndex(index);
    }
  };

  const isMobile = () => {
    return window.innerWidth <= 1025;
  };

  const isHorizontalOrSlim = useCallback(() => {
    return props.menuMode === "horizontal" || props.menuMode === "slim";
  }, [props.menuMode]);

  const visible = (item) => {
    return typeof item.visible === "function" ? item.visible() : item.visible !== false;
  };

  const getLink = (item, index) => {
    const menuitemIconClassName = classNames("layout-menuitem-icon", item.icon);

    const content = (
      <>
        <i className={menuitemIconClassName}></i>
        <span className="layout-menuitem-text">{item.label}</span>
        {item.items && <i className="pi pi-fw pi-chevron-down  layout-submenu-toggler"></i>}
        {item.badge && <Badge value={item.badge} style={{ height: "100%" }} />}
        <Ripple />
      </>
    );

    const commonLinkProps = {
      style: item.style,
      target: item.target,
      onClick: (e) => onMenuItemClick(e, item, index),
      onMouseEnter: () => onMenuItemMouseEnter(index),
      onKeyDown: (e) => onKeyDown(e, item, index),
      className: classNames(item.className, "p-ripple", {
        "p-disabled": item.disabled,
      }),
    };

    if (item.logout) {
      return (
        <a
          tabIndex={item.url ? undefined : 0}
          {...commonLinkProps}
          onClick={logout}
          className={styles["logout-btn"]}
        >
          {content}
        </a>
      );
    } else if (item.to) {
      return (
        <NavLink
          to={item.to}
          {...commonLinkProps}
          className={({ isActive }) => (isActive ? "active-route" : "")}
        >
          {content}
        </NavLink>
      );
    } else {
      return (
        <a tabIndex={item.url ? undefined : 0} {...commonLinkProps}>
          {content}
        </a>
      );
    }
  };

  const getItems = () => {
    const transitionTimeout =
      isHorizontalOrSlim() && !props.root
        ? { enter: 1000, exit: 450 }
        : isHorizontalOrSlim() && !isMobile()
        ? 0
        : { enter: 1000, exit: 450 };
    return props.items.map((item, i) => {
      if (visible(item)) {
        const menuitemClassName = classNames({
          "layout-root-menuitem": props.root,
          "active-menuitem": activeIndex === i && !item.disabled,
        });
        const link = getLink(item, i);
        const tooltip = (
          <div className="layout-menu-tooltip">
            <div className="layout-menu-tooltip-arrow"></div>
            <div className="layout-menu-tooltip-text">{item.label}</div>
          </div>
        );

        return (
          <li key={item.label || i} className={menuitemClassName} role="menuitem">
            {link}
            {tooltip}
            <CSSTransition
              classNames="p-toggleable-content"
              timeout={transitionTimeout}
              in={activeIndex === i}
              unmountOnExit
            >
              <AppSubmenu
                items={visible(item) && item.items}
                menuActive={props.menuActive}
                menuMode={props.menuMode}
                parentMenuItemActive={activeIndex === i}
                onMenuItemClick={props.onMenuItemClick}
              ></AppSubmenu>
            </CSSTransition>
          </li>
        );
      }

      return null;
    });
  };

  useEffect(() => {
    if (props.resetActiveIndex && isHorizontalOrSlim()) {
      setActiveIndex(null);
    }
  }, [props.resetActiveIndex, isHorizontalOrSlim]);

  useEffect(() => {
    if (!props.menuActive && isHorizontalOrSlim()) {
      setActiveIndex(null);
    }
  }, [props.menuActive, isHorizontalOrSlim]);

  if (!props.items) {
    return null;
  }

  const items = getItems();
  return (
    <ul className={props.className} role="menu">
      {items}
    </ul>
  );
};

const AppMenu = (props) => {
  const navigate = useNavigate();

  const topItems = props.model.filter((el) => !el.bottom);
  const bottomItems = props.model.filter((el) => el.bottom);

  return (
    <div
      className={classNames("menu-wrapper", {
        "layout-sidebar-active": props.sidebarActive,
      })}
      onClick={props.onMenuClick}
    >
      <div className={"sidebar-logo"}>
        <button className="p-link" onClick={() => navigate("/")}>
          <img src={LogoIconMini} alt="freya-layout" className="logo" />
          <img src={LogoIcon} alt="freya-layout" className="logo-with-name" />
        </button>
      </div>

      <div className="layout-menu-container">
        <AppSubmenu
          items={topItems}
          className="layout-menu"
          menuActive={props.menuActive}
          onRootMenuItemClick={props.onRootMenuItemClick}
          mobileMenuActive={props.mobileMenuActive}
          onMenuItemClick={props.onMenuItemClick}
          root
          menuMode={props.menuMode}
          parentMenuItemActive
          resetActiveIndex={props.resetActiveIndex}
        />
        <AppSubmenu
          items={bottomItems}
          className="layout-menu"
          menuActive={props.menuActive}
          onRootMenuItemClick={props.onRootMenuItemClick}
          mobileMenuActive={props.mobileMenuActive}
          onMenuItemClick={props.onMenuItemClick}
          root
          menuMode={props.menuMode}
          parentMenuItemActive
          resetActiveIndex={props.resetActiveIndex}
        />
      </div>
      <button className="sidebar-button" onClick={(event) => props.onToggleMenu(event)}>
        <i className={classNames("layout-menuitem-icon", "pi pi-angle-right")}></i>
      </button>
    </div>
  );
};

export default AppMenu;
