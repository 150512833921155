import { FC } from "react";
import { BreadCrumb } from "primereact/breadcrumb";

import styles from "./BreadCrumb.module.scss";

type CustomBreadCrumbProps = {
  items: { label: string; url: string; disabled?: boolean }[];
  home: { label: string; url: string; icon: string };
};

export const CustomBreadCrumb: FC<CustomBreadCrumbProps> = (props) => {
  return <BreadCrumb model={props.items} home={props.home} className={styles["breadcrumb"]} />;
};
