import { FC, memo } from "react";
import { tableControlsType } from "typings/table-controls";
import { Button } from "primereact/button";
import { TStudent } from "typings/students";
import StudentsSettings from "./StudentsSettings";
import StudentsTable from "./table/students-table";
import styles from "./Students.module.scss";

type StudentsLayoutProps = {
  setParams: React.Dispatch<React.SetStateAction<tableControlsType>>;
  params: tableControlsType;
  studentsList: TStudent[];
  handleSelectedStudent: (student: TStudent) => void;
  totalRecords: number;
  isLoadingTable: boolean;
  handleOpenCreateStudentPopup: () => void;
  handleOpenRemoveStudentPopup: () => void;
  handleOpenEditStudentPopup: () => void;
  handleOpenStudentInfoPopup: () => void;
};

const StudentsLayout: FC<StudentsLayoutProps> = (props) => {
  return (
    <div className={styles["main-wrapper"]}>
      <div className={styles["header-container"]}>
        <h1 className={styles["page-title"]}>Студенты</h1>
        <Button className="p-button p-button-inverted" onClick={props.handleOpenCreateStudentPopup}>
          <i className="pi pi-plus" />
          Добавить студента
        </Button>
      </div>
      <StudentsSettings setParams={props.setParams} params={props.params} />
      <StudentsTable
        studentsList={props.studentsList}
        handleSelectedStudent={props.handleSelectedStudent}
        totalRecords={props.totalRecords}
        isLoadingTable={props.isLoadingTable}
        params={props.params}
        setParams={props.setParams}
        handleOpenRemoveStudentPopup={props.handleOpenRemoveStudentPopup}
        handleOpenEditStudentPopup={props.handleOpenEditStudentPopup}
        handleOpenStudentInfoPopup={props.handleOpenStudentInfoPopup}
      />
    </div>
  );
};

export default memo(StudentsLayout);
