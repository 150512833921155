// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Shedule_main-wrapper__w-Qyi {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Shedule_save-button__6C0x7 {
  border-radius: 6px;
  padding: 12px;
  width: 100%;
  max-width: 300px;
  cursor: pointer;
  color: #fff;
  display: none;
  align-items: center;
  justify-content: center;
  transition: all 0.1s;
  animation: Shedule_shows__OxKxd 1s;
  margin-top: 20px;
  background-color: rgb(2, 173, 2);
  border: 1px solid rgb(2, 173, 2);
}
.Shedule_save-button__6C0x7:hover {
  background-color: rgb(4, 112, 4) !important;
  border: 1px solid rgb(4, 112, 4) !important;
}

.Shedule_save-button-visible__mTP74 {
  display: flex;
}

@keyframes Shedule_shows__OxKxd {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Courses/UpdateCourse/tabs/shedule/Shedule.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,oBAAA;EACA,kCAAA;EACA,gBAAA;EAEA,gCAAA;EACA,gCAAA;AAAF;AAEE;EACE,2CAAA;EACA,2CAAA;AAAJ;;AAGA;EACE,aAAA;AAAF;;AAGA;EACE;IACE,UAAA;EAAF;EAGA;IACE,UAAA;EADF;AACF","sourcesContent":[".main-wrapper {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.save-button {\n  border-radius: 6px;\n  padding: 12px;\n  width: 100%;\n  max-width: 300px;\n  cursor: pointer;\n  color: #fff;\n  display: none;\n  align-items: center;\n  justify-content: center;\n  transition: all 0.1s;\n  animation: shows 1s;\n  margin-top: 20px;\n\n  background-color: rgb(2, 173, 2);\n  border: 1px solid rgb(2, 173, 2);\n\n  &:hover {\n    background-color: rgb(4, 112, 4) !important;\n    border: 1px solid rgb(4, 112, 4) !important;\n  }\n}\n.save-button-visible {\n  display: flex;\n}\n\n@keyframes shows {\n  0% {\n    opacity: 0;\n  }\n\n  100% {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-wrapper": `Shedule_main-wrapper__w-Qyi`,
	"save-button": `Shedule_save-button__6C0x7`,
	"shows": `Shedule_shows__OxKxd`,
	"save-button-visible": `Shedule_save-button-visible__mTP74`
};
export default ___CSS_LOADER_EXPORT___;
