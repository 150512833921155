// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Shedule_shedule-table__RaACu {
  width: 100%;
}
.Shedule_shedule-table__RaACu .Shedule_available-dropdown__ZdZSP {
  width: 100%;
}
.Shedule_shedule-table__RaACu .Shedule_calendar__lJUpy {
  width: 100%;
}

.Shedule_available-data-text__XEZoO {
  width: 100%;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/comon/shedule/Shedule.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,WAAA;AACJ;AAEE;EACE,WAAA;AAAJ;;AAIA;EACE,WAAA;EACA,kBAAA;AADF","sourcesContent":[".shedule-table {\n  width: 100%;\n\n  .available-dropdown {\n    width: 100%;\n  }\n\n  .calendar {\n    width: 100%;\n  }\n}\n\n.available-data-text {\n  width: 100%;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shedule-table": `Shedule_shedule-table__RaACu`,
	"available-dropdown": `Shedule_available-dropdown__ZdZSP`,
	"calendar": `Shedule_calendar__lJUpy`,
	"available-data-text": `Shedule_available-data-text__XEZoO`
};
export default ___CSS_LOADER_EXPORT___;
