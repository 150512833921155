import { Button } from "primereact/button";
import { phrases } from "constants/phrases";
import { getRoles } from "utils/getRoles";
import { TCourseInfo } from "typings/courses";
import { useAppDispatch } from "utils/hooks";
import { TCourseStudent } from "typings/students";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { FC, memo, useCallback, useEffect, useState } from "react";
import { addStudentsOnCourse, getAllStudentsCourse, removeStudentFromCourse } from "api/students";

import Students from "components/comon/students/Students";
import AddStudentOnCoursePopup from "popups/add-students-on-course";
import RemoveStudentFromCoursePopup from "popups/remove-student-from-course";

import styles from "./Students.module.scss";

type CourseStudentsTabProps = {
  courseInfo: TCourseInfo | null;
};

const CourseStudentsTab: FC<CourseStudentsTabProps> = (props) => {
  const [studentsList, setStudentsList] = useState<TCourseStudent[]>([]);
  const [selectedStudent, setSelectedStudent] = useState<TCourseStudent | null>(null);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const [isOpenAddStudentsPopup, setIsOpenAddStudentsPopup] = useState<boolean>(false);
  const [isOpenDeleteStudentsPopup, setIsOpenDeleteStudentsPopup] = useState<boolean>(false);

  const isAdmin = getRoles();

  const dispatch = useAppDispatch();

  const handleSelectStudent = (student: TCourseStudent) => setSelectedStudent(student);

  const handleOpenAddStudentsPopup = () => setIsOpenAddStudentsPopup(true);
  const handleCloseAddStudentsPopup = () => setIsOpenAddStudentsPopup(false);

  const handleOpenDeleteStudentsPopup = () => setIsOpenDeleteStudentsPopup(true);
  const handleCloseDeleteStudentsPopup = () => setIsOpenDeleteStudentsPopup(false);

  const getStudentsList = useCallback(async () => {
    setIsLoadingTable(true);
    try {
      if (props.courseInfo) {
        const response = await getAllStudentsCourse(props.courseInfo.id);
        if (response.status === 200 || response.status === 201) {
          setStudentsList(response.data);
          setIsLoadingTable(false);
        } else throw new Error();
      } else return;
    } catch (err: any) {
      dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
    }
  }, [dispatch, props.courseInfo]);

  useEffect(() => {
    getStudentsList();
  }, [getStudentsList]);

  const deleteStudentFromCourse = useCallback(async () => {
    setIsLoadingTable(true);
    try {
      if (selectedStudent && props.courseInfo) {
        const response = await removeStudentFromCourse(
          selectedStudent.studentId,
          props.courseInfo?.id
        );
        if (response.status === 200 || response.status === 201) {
          dispatch(addSuccessMessage(phrases.student_sucess_deleted));
          getStudentsList();
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
    } finally {
      handleCloseDeleteStudentsPopup();
      setIsLoadingTable(false);
    }
  }, [dispatch, selectedStudent, props.courseInfo]);

  const addStudents = useCallback(
    async (selectedTarif: number, studentIds: number[]) => {
      setIsLoadingTable(true);
      try {
        if (props.courseInfo) {
          const response = await addStudentsOnCourse(selectedTarif, studentIds);
          if (response.status === 200 || response.status === 201) {
            getStudentsList();
            dispatch(
              addSuccessMessage(
                studentIds.length === 1
                  ? phrases.student_success_added
                  : phrases.students_success_added
              )
            );
          } else throw new Error();
        } else return;
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrong));
      } finally {
        handleCloseAddStudentsPopup();
        setIsLoadingTable(false);
      }
    },
    [dispatch, props.courseInfo]
  );

  return (
    <>
      <div className={styles["main-wrapper"]}>
        {isAdmin && (
          <span className={styles["main-wrapper-header"]}>
            <Button onClick={handleOpenAddStudentsPopup}>
              <i className="pi pi-plus" />
              Добавить ученика
            </Button>
          </span>
        )}
        <div className={styles["main-wrapper-table"]}>
          <Students
            studentsList={studentsList}
            isLoadingTable={isLoadingTable}
            handleSelectStudent={handleSelectStudent}
            handleOpenDeleteStudentsPopup={handleOpenDeleteStudentsPopup}
            isAdmin={isAdmin}
          />
        </div>
      </div>
      <AddStudentOnCoursePopup
        isOpen={isOpenAddStudentsPopup}
        handleHide={handleCloseAddStudentsPopup}
        addStudents={addStudents}
        courseId={props.courseInfo?.id}
      />
      <RemoveStudentFromCoursePopup
        isOpen={isOpenDeleteStudentsPopup}
        handleHide={handleCloseDeleteStudentsPopup}
        selectedStudent={selectedStudent}
        deleteStudent={deleteStudentFromCourse}
      />
    </>
  );
};

export default memo(CourseStudentsTab);
