import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { TShortCourse } from "typings/courses";
import { useAppSelector } from "utils/hooks";
import { getShortListCoursesModules } from "api/courses";
import { FC, useCallback, useEffect, useState } from "react";

import styles from "../components/Lesson/LessonUpdate/UpdateLesson.module.scss";

type CopyLessonPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  copyLesson: (name: string, copyCourse: number) => void;
  courseId: string | null | undefined;
};

const CopyLessonPopup: FC<CopyLessonPopupProps> = (props) => {
  const [allCoursesList, setAllCouresesList] = useState<TShortCourse[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<number | null>(null);
  const [lessonName, setLessonName] = useState<string>("");

  const { lessonInfo } = useAppSelector((state) => state.lessonConstructor);

  const closePopup = () => {
    setSelectedCourse(null);
    props.handleHide();
  };

  useEffect(() => {
    if (lessonInfo) setLessonName(lessonInfo.name);
  }, [lessonInfo]);

  const getCoursesModulesList = useCallback(async () => {
    try {
      const response = await getShortListCoursesModules();
      if (response.status === 200 || response.status === 201) {
        const modulesList: TShortCourse[] = [];
        const coursesList: TShortCourse[] = [];

        response.data.map((el) => {
          el.courses.map((course) => {
            coursesList.push({ id: course.id, index: course.index, name: course.name });
          });
        });

        response.data.map((el) => {
          el.courses.map((course) => {
            course.submodules.map((module) => {
              modulesList.push({
                id: module.id,
                index: module.index,
                name: "-- " + module.name,
              });
            });
          });
        });
        setAllCouresesList([...modulesList, ...coursesList]);
      } else throw new Error();
    } catch (err: any) {}
  }, []);

  useEffect(() => {
    props.isOpen && getCoursesModulesList();
  }, [getCoursesModulesList, props.isOpen]);

  return (
    <Dialog
      visible={props.isOpen}
      style={{ width: "500px" }}
      modal
      onHide={closePopup}
      header={`Копировать урок`}
    >
      <div className={styles["popup-wrapper"]}>
        <span className={styles["input-wrapper"]}>
          <p>Новое название урока</p>
          <InputText
            name="name"
            value={lessonName}
            onChange={(e) => setLessonName(e.target.value)}
            placeholder="Введите название урока"
          />
        </span>
        <span className={styles["input-wrapper"]}>
          <p>Поместить копию в</p>
          <Dropdown
            options={allCoursesList}
            onChange={(e) => setSelectedCourse(e.value)}
            value={selectedCourse}
            placeholder="Выберите куда копировать урок"
            className={styles["available-dropdown"]}
            optionLabel="name"
            optionValue="id"
          />
        </span>

        <div className={styles["btn-wrap"]}>
          <button className={styles["cancel-btn"]} onClick={closePopup}>
            Отмена
          </button>
          <button
            className={styles["copy-btn"]}
            onClick={() => {
              selectedCourse && props.copyLesson(lessonName, selectedCourse);
              closePopup();
            }}
            disabled={!lessonName || !selectedCourse}
          >
            Копировать
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default CopyLessonPopup;
