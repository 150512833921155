import { TNewStudent } from "typings/students";
import http from "./https";

type getAllStudentsParamsType = {
  page?: number;
  limit?: number;
  search?: string;
};

export const getAllStudents = (params: getAllStudentsParamsType) =>
  http.get(
    `/api/students`,
    {},
    {
      params,
    }
  );

export const createNewStudent = (studentData: TNewStudent) =>
  http.post(`/api/students`, studentData);

export const removeStudent = (studentId: number) =>
  http.remove(`/api/students?id=${studentId}`, {});

export const updateStudentAsync = (studentId: number, studentData: TNewStudent) =>
  http.patch(`/api/students/${studentId}`, studentData);

export const getAllStudentsCourse = (courseId: number) => http.get(`api/accesses/${courseId}`);

export const removeStudentFromCourse = (studentId: number, courseId: number) =>
  http.remove(`/api/accesses/${courseId}?studentId=${studentId}`, {});

export const getShortListStudents = (courseId: number, params: getAllStudentsParamsType) =>
  http.get(
    `api/students/list-without-access/${courseId}`,
    {},
    {
      params,
    }
  );

export const addStudentsOnCourse = (tariffId: number, studentIds: number[]) =>
  http.post(`/api/tariffs/${tariffId}/add-students`, { studentIds });
