import { TLesson } from "typings/lesson";
import { phrases } from "constants/phrases";
import { useNavigate } from "react-router";
import { copyLesonAsync, removeLesson } from "api/lesson";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { getLessonInfoThunk, updateLessonInfoThunk } from "slices/lessonConstructor";
import { FC, memo, useCallback, useEffect, useState } from "react";

import CopyLessonPopup from "popups/copy-lesson";
import RemoveLessonPopup from "popups/remove-lesson";
import UpdateLessonLayout from "./UpdateLesson.layout";

type UpdateLessonProps = {
  moduleId: string | null | undefined;
  courseId: string | null | undefined;
  lessonId: string | null | undefined;
  headCourseId: string | null | undefined;
  changeLessonId: (lessonID: string | null) => void;
};

const UpdateLesson: FC<UpdateLessonProps> = (props) => {
  const [isOpenRemoveLessonPopup, setIsOpenRemoveLessonPopup] = useState<boolean>(false);
  const [isOpenCopyLessonPopup, setIsOpenCopyLessonPopup] = useState<boolean>(false);

  const { lessonInfo }: { lessonInfo: TLesson } = useAppSelector(
    (state) => state.lessonConstructor
  );

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleOpenRemoveLessonPopup = () => setIsOpenRemoveLessonPopup(true);
  const handleCloseRemoveLessonPopup = () => setIsOpenRemoveLessonPopup(false);

  const handleOpenCopyLessonPopup = () => setIsOpenCopyLessonPopup(true);
  const handleCloseCopyLessonPopup = () => setIsOpenCopyLessonPopup(false);

  const getLessonInfo = useCallback(async () => {
    try {
      if (props.lessonId) {
        const response = await dispatch(getLessonInfoThunk(props.lessonId));
        if (!response.payload) throw new Error();
      } else throw new Error();
    } catch (err: any) {
      props.changeLessonId(null);
    }
  }, [dispatch, props.lessonId]);

  useEffect(() => {
    getLessonInfo();
  }, [getLessonInfo]);

  const updateLesson = useCallback(
    async (lessonData: TLesson) => {
      try {
        if (props.lessonId) {
          const response = await dispatch(updateLessonInfoThunk(lessonData));
          if (response.payload.error)
            throw new Error(response.payload.message || phrases.smthWentWrongText);
          else {
            getLessonInfo();
            dispatch(addSuccessMessage(phrases.lesson_success_updated));
          }
        } else throw new Error(phrases.smthWentWrongText);
      } catch (err: any) {
        dispatch(
          addErrorMessage(
            err.message && err.message.includes("Need task")
              ? "Необходимо добавить задание"
              : err.message && err.message.includes("Item additional fields task not valid")
              ? "Неккоректно заполнены данные дополнительных полей "
              : phrases.smthWentWrongText
          )
        );
      }
    },
    [dispatch]
  );

  const deleteLesson = useCallback(
    async (lessonId: number) => {
      try {
        const response = await removeLesson(lessonId);
        if (response.status === 200 || response.status === 201) {
          dispatch(addSuccessMessage(phrases.lesson_success_deleted));
          props.moduleId
            ? navigate(
                `/courses/update_module?module_id=${props.moduleId}&course_id=${props.headCourseId}&step_id=${props.courseId}`
              )
            : props.courseId
            ? navigate(
                `/courses/update_step?course_id=${props.headCourseId}&step_id=${props.courseId}`
              )
            : navigate(`/courses`);
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      } finally {
        handleCloseRemoveLessonPopup();
      }
    },
    [dispatch]
  );

  const copyLesson = useCallback(
    async (name: string, copyCourse: number) => {
      try {
        if (props.lessonId && props.courseId) {
          const response = await copyLesonAsync(props.lessonId, {
            name,
            courseId: copyCourse,
          });
          if (response.status === 200 || response.status === 201) {
            dispatch(addSuccessMessage(phrases.lesson_success_copy));
          } else throw new Error();
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      } finally {
        handleCloseRemoveLessonPopup();
      }
    },
    [dispatch, props.lessonId, props.courseId]
  );

  return (
    <>
      <UpdateLessonLayout
        moduleId={props.moduleId}
        courseId={props.courseId}
        headCourseId={props.headCourseId}
        updateLesson={updateLesson}
        handleOpenRemoveLessonPopup={handleOpenRemoveLessonPopup}
        lessonInfo={lessonInfo}
        handleOpenCopyLessonPopup={handleOpenCopyLessonPopup}
      />

      <RemoveLessonPopup
        isOpen={isOpenRemoveLessonPopup}
        handleHide={handleCloseRemoveLessonPopup}
        deleteLesson={deleteLesson}
        selectedLesson={lessonInfo}
      />
      <CopyLessonPopup
        isOpen={isOpenCopyLessonPopup}
        handleHide={handleCloseCopyLessonPopup}
        courseId={props.courseId}
        copyLesson={copyLesson}
      />
    </>
  );
};

export default memo(UpdateLesson);
