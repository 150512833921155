import UpdateLesson from "components/Lesson/LessonUpdate/UpdateLesson";
import { Button } from "primereact/button";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import styles from "../components/Lesson/LessonUpdate/UpdateLesson.module.scss";

const UpdateLessonPage = () => {
  const [moduleId, setModuleId] = useState<string | null>();
  const [courseId, setCourseId] = useState<string | null>();
  const [headCourseId, setHeadCourseId] = useState<string | null>();
  const [lessonId, setLessonId] = useState<string | null>();

  let { search } = useLocation();

  const navigate = useNavigate();

  const changeLessonId = (lessonID: string | null) => setLessonId(lessonID);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const courseId: string | null = params.get("step_id");
    const headCourseId: string | null = params.get("course_id");
    const moduleId: string | null = params.get("module_id");
    const lessonId: string | null = params.get("lesson_id");
    setModuleId(moduleId);
    setCourseId(courseId);
    setLessonId(lessonId);
    setHeadCourseId(headCourseId);
  }, []);

  const renderPageLayout = useCallback(() => {
    if (lessonId)
      return (
        <UpdateLesson
          moduleId={moduleId}
          changeLessonId={changeLessonId}
          courseId={courseId}
          lessonId={lessonId}
          headCourseId={headCourseId}
        />
      );
    else
      return (
        <div className={styles["empty-page"]}>
          <i className="pi pi-minus-circle" />
          <p>К сожалению, урок не найден!</p>
          <Button
            className="p-button p-button-inverted"
            onClick={() => {
              moduleId
                ? navigate(`/courses/update_module?module_id=${moduleId}?course_id=${courseId}`)
                : courseId
                ? navigate(`/courses/update_course?course_id=${courseId}`)
                : navigate(`/courses`);
            }}
          >
            {moduleId
              ? "Вернуть к модулю"
              : courseId
              ? "Вернуться к курсу"
              : "Вернуться к списку курсов"}
          </Button>
        </div>
      );
  }, [lessonId]);

  return renderPageLayout();
};

export default UpdateLessonPage;
