import { Toast } from "primereact/toast";
import { Loader } from "components/Loader/Loader";
import { ToastState } from "typings/toast";
import { clearMessage } from "slices/toastSlice";
import { useEffect, useRef } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "utils/hooks";

import LoginPage from "./pages/LoginPage";
import PrivateRoute from "PrivateRoute";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./App.scss";

import AdminRoute from "AdminRoute";
import AdminsPage from "./pages/AdminsPage";
import StudentsPage from "pages/StudentsPage";
import HeadCoursesPage from "pages/HeadCoursesPage";
import UpdateTarifPage from "pages/UpdateTarif";
import UpdateModulePage from "pages/UpdateModulePage";
import UpdateLessonPage from "pages/UpdateLessonPage";
import UpdateCoursesPage from "pages/UpdateCoursesPage";
import UpdateHeadCoursesPage from "pages/UpdateHeadCoursesPage";

const App = () => {
  const toastRef = useRef(null);
  const dispatch = useAppDispatch();
  const toastState: ToastState = useAppSelector((state) => state.toast);
  const loaderState: { loading: boolean } = useAppSelector((state) => state.loader);

  const IS_CURATOR = localStorage.getItem("isCurator");

  useEffect(() => {
    if (!toastRef) return;
    handleMessage(toastState);
  }, [toastState]);

  const handleMessage = (toastState: ToastState) => {
    if (toastState.summary.length > 1) {
      //@ts-ignore
      toastRef.current.show({
        severity: toastState.severity,
        summary: toastState.summary,
        detail: toastState.detail,
      });
    }
  };

  return (
    <>
      <Loader loading={loaderState.loading} />
      <Toast ref={toastRef} onHide={() => dispatch(clearMessage())} />
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route element={<PrivateRoute />}>
          <Route element={<AdminRoute />}>
            <Route
              path="/"
              element={<Navigate to={IS_CURATOR === "true" ? "/courses" : "/users"} />}
            />
            <Route path="/users" element={<AdminsPage />} />
            <Route path="/students" element={<StudentsPage />} />
          </Route>
          <Route path="/courses" element={<HeadCoursesPage />} />
          <Route path="/courses/update_course" element={<UpdateHeadCoursesPage />} />
          <Route path="/courses/update_step" element={<UpdateCoursesPage />} />
          <Route path="/courses/update_step/update-tarif" element={<UpdateTarifPage />} />
          <Route path="/courses/update_module" element={<UpdateModulePage />} />
          <Route path="/courses/update_lesson" element={<UpdateLessonPage />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
