// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.audio_audio-wrapper__b3RkN {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.audio_audio-wrapper__b3RkN i {
  font-size: 68px;
  color: rgb(98, 98, 253);
}`, "",{"version":3,"sources":["webpack://./src/components/comon/file-storage/content/audio/audio.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACE;EACE,eAAA;EACA,uBAAA;AACJ","sourcesContent":[".audio-wrapper {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  i {\n    font-size: 68px;\n    color: rgb(98, 98, 253);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"audio-wrapper": `audio_audio-wrapper__b3RkN`
};
export default ___CSS_LOADER_EXPORT___;
