import { Column } from "primereact/column";
import { FC, memo } from "react";
import { DataTable } from "primereact/datatable";
import { classNames } from "primereact/utils";
import { TShortListTarifs } from "typings/tarifs";

import styles from "./CourseTarifsTable.module.scss";

type CourseTarifsTableProps = {
  tarifsList: TShortListTarifs[];
  isLoadingTable: boolean;
  handleSelectTarif: (tarif: TShortListTarifs) => void;
  handleOpenDeleteTarifPopup: () => void;
  isAdmin: boolean;
  redirectOnEditTarifPage: (tarifId: number) => void;
};

const CourseTarifsTable: FC<CourseTarifsTableProps> = (props) => {
  const renderColumn = (value: string) => (rowData: TShortListTarifs) => {
    return (
      <div className="col-wrapper" onClick={() => {}}>
        <p className="table-text">{rowData[value]}</p>
      </div>
    );
  };

  const renderAction = (rowData: TShortListTarifs) => {
    return (
      <div className={styles["col-action-wrapper"]} onClick={() => {}}>
        <button
          className={classNames(styles["action-btn"], styles["edit-btn"])}
          onClick={() => props.redirectOnEditTarifPage(rowData.id)}
        >
          <i className="pi pi-pencil" />
        </button>

        <button
          className={classNames(styles["action-btn"], styles["delete-btn"])}
          onClick={() => {
            props.handleSelectTarif(rowData);
            props.handleOpenDeleteTarifPopup();
          }}
        >
          <i className="pi pi-trash" />
        </button>
      </div>
    );
  };

  return (
    <div className={styles["main-wrapper"]}>
      <div className={styles["main-wrapper-table"]}>
        <DataTable
          value={props.tarifsList}
          responsiveLayout="scroll"
          lazy
          showGridlines
          loading={props.isLoadingTable}
        >
          <Column
            field="name"
            header="Название"
            style={{ width: "80%" }}
            body={renderColumn("name")}
          ></Column>

          {props.isAdmin && (
            <Column
              field="Column 5"
              header="Действия"
              style={{ width: "10%" }}
              body={renderAction}
            ></Column>
          )}
        </DataTable>
      </div>
    </div>
  );
};

export default memo(CourseTarifsTable);
