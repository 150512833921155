import { FC, memo } from "react";
import styles from "./Admins.module.scss";
import { tableControlsType } from "typings/table-controls";
import AdminsTable from "./table/admins-table";
import AdminsSettings from "./AdminsSettings";
import { TAdmin } from "typings/admins";
import { Button } from "primereact/button";

type AdminsLayoutProps = {
  setParams: React.Dispatch<React.SetStateAction<tableControlsType>>;
  params: tableControlsType;
  adminsList: TAdmin[];
  handleSelectedAdmin: (norm: TAdmin) => void;
  totalRecords: number;
  isLoadingTable: boolean;
  handleOpenCreateAdminPopup: () => void;
  handleOpenRemoveAdminPopup: () => void;
  handleOpenEditAdminPopup: () => void;
  handleOpenAdminInfoPopup: () => void;
};

const AdminsLayout: FC<AdminsLayoutProps> = (props) => {
  return (
    <div className={styles["main-wrapper"]}>
      <div className={styles["header-container"]}>
        <h1 className={styles["page-title"]}>Пользователи</h1>
        <Button className="p-button p-button-inverted" onClick={props.handleOpenCreateAdminPopup}>
          <i className="pi pi-plus" />
          Добавить пользователя
        </Button>
      </div>
      <AdminsSettings setParams={props.setParams} params={props.params} />
      <AdminsTable
        adminsList={props.adminsList}
        handleSelectedAdmin={props.handleSelectedAdmin}
        totalRecords={props.totalRecords}
        isLoadingTable={props.isLoadingTable}
        params={props.params}
        setParams={props.setParams}
        handleOpenRemoveAdminPopup={props.handleOpenRemoveAdminPopup}
        handleOpenEditAdminPopup={props.handleOpenEditAdminPopup}
        handleOpenAdminInfoPopup={props.handleOpenAdminInfoPopup}
      />
    </div>
  );
};

export default memo(AdminsLayout);
