// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tarifs_main-wrapper__BnsIz {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: -20px;
}
.Tarifs_main-wrapper-table__9OKId {
  margin-top: 10px;
}
.Tarifs_main-wrapper-header__xIyH6 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Tarifs_main-wrapper-header__xIyH6 button {
  max-width: 180px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(2, 173, 2);
  border: 1px solid rgb(2, 173, 2);
  white-space: nowrap;
}
.Tarifs_main-wrapper-header__xIyH6 button:hover {
  background-color: rgb(4, 112, 4) !important;
  border: 1px solid rgb(4, 112, 4) !important;
}
.Tarifs_main-wrapper-header__xIyH6 button i {
  margin-right: 6px;
}`, "",{"version":3,"sources":["webpack://./src/components/Courses/UpdateCourse/tabs/tarifs/Tarifs.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,sBAAA;EACA,iBAAA;AACJ;AACI;EACE,gBAAA;AACN;AAEI;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;AAAN;AAEM;EACE,gBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gCAAA;EACA,gCAAA;EACA,mBAAA;AAAR;AACQ;EACE,2CAAA;EACA,2CAAA;AACV;AACQ;EACE,iBAAA;AACV","sourcesContent":[".main-wrapper {\r\n    width: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    margin-top: -20px;\r\n  \r\n    &-table {\r\n      margin-top: 10px;\r\n    }\r\n  \r\n    &-header {\r\n      width: 100%;\r\n      display: flex;\r\n      align-items: center;\r\n      justify-content: flex-end;\r\n  \r\n      button {\r\n        max-width: 180px;\r\n        width: 100%;\r\n        display: flex;\r\n        align-items: center;\r\n        justify-content: center;\r\n        background-color: rgb(2, 173, 2);\r\n        border: 1px solid rgb(2, 173, 2);\r\n        white-space: nowrap;\r\n        &:hover {\r\n          background-color: rgb(4, 112, 4) !important;\r\n          border: 1px solid rgb(4, 112, 4) !important;\r\n        }\r\n        i {\r\n          margin-right: 6px;\r\n        }\r\n      }\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-wrapper": `Tarifs_main-wrapper__BnsIz`,
	"main-wrapper-table": `Tarifs_main-wrapper-table__9OKId`,
	"main-wrapper-header": `Tarifs_main-wrapper-header__xIyH6`
};
export default ___CSS_LOADER_EXPORT___;
