import { FC } from "react";
import { Dialog } from "primereact/dialog";
import { ADD_LESSON_CONPONENTS_POPUP } from "constants/lesson";
import styles from "../components/Lesson/Constructor/Constructor.module.scss";

type AddLessonComponentPopupPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  addItemFunc: (indexItem: number, type: string, typeItem: string) => void;
  index: number;
  type: string;
};

const AddLessonComponentPopup: FC<AddLessonComponentPopupPopupProps> = (props) => {
  return (
    <Dialog
      visible={props.isOpen}
      style={{ width: "1000px" }}
      modal
      onHide={props.handleHide}
      header={`Добавление элементов урока`}
    >
      <div className={styles["add-item-wrapper"]}>
        {ADD_LESSON_CONPONENTS_POPUP.map((el, index) => (
          <div
            className={styles["add-item-wrapper-card"]}
            onClick={() => {
              props.addItemFunc(props.index, props.type, el.type);
              props.handleHide();
            }}
            key={index}
          >
            <div className={styles["add-item-wrapper-card-icon"]}>
              <i className={el.icon} />
            </div>
            <span>{el.label}</span>
          </div>
        ))}
      </div>
    </Dialog>
  );
};

export default AddLessonComponentPopup;
