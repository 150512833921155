import { InputText } from "primereact/inputtext";
import { TAxiosGetCourseList } from "typings/courses";
import { FC, useEffect, useState } from "react";

import styles from "./HeadCourses.module.scss";
import useDebounce from "utils/use-debounce";

type CoursesSettingsProps = {
  setParams: React.Dispatch<React.SetStateAction<TAxiosGetCourseList>>;
  params: TAxiosGetCourseList;
};

const HeadCoursesSettings: FC<CoursesSettingsProps> = ({ setParams, params }) => {
  const [searchLocalValue, setSearchLocalValue] = useState<string>("");

  const debouncedSearchValue = useDebounce(searchLocalValue, 500);

  useEffect(() => {
    if (debouncedSearchValue !== params?.search) {
      setParams((prev) => ({ ...prev, search: debouncedSearchValue }));
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (searchLocalValue !== params?.search && typeof params?.search === "string") {
      setSearchLocalValue(params?.search);
    }
  }, [params?.search]);

  return (
    <div className={styles["courses-settings-wrapper"]}>
      <span className="p-input-icon-left p-main-input">
        <i className="pi pi-search" />
        <InputText
          value={searchLocalValue}
          type="text"
          placeholder="Поиск"
          onChange={(e) => setSearchLocalValue(e.target.value)}
        />
      </span>
    </div>
  );
};

export default HeadCoursesSettings;
