import { TLesson } from "typings/lesson";
import { CHAGE_POSITION } from "constants/lesson";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getLessonById, updateLessonAsync } from "api/lesson";

const initialState: { lessonInfo: TLesson; loadingStatus: string } = {
  loadingStatus: "idle",
  lessonInfo: {
    id: 0,
    createdAt: "",
    updatedAt: "",
    index: 0,
    name: "",
    description: "",
    imageUrl: "",
    videoUrl: "",
    durationMin: 0,
    blocks: [],
    startAt: "",
    visibility: "",
    isStopLesson: false,
    isTask: false,
    task: "",
    taskDescription: "",
    isTextTask: false,
    additionalFieldsTask: [],
    isHideMainResponseField: false,
    isAnswerVisibleEveryone: false,
    isStudentCanHideAnswer: false,
    isAcceptAnswerAuto: false,
    isOpenRegardlessOfStopLesson: false,
    isProhibitStudentsFromShowingTheirAnswer: false,
    playlist: {
      links: {
        spotify: "",
        appleMusic: "",
        yandexMusic: "",
        VK: "",
        soundCloud: "",
      },
      list: [],
    },
    button: {
      name: "",
      url: "",
    },
    settingsAddingPoints: {
      defaultCountPoints: 0,
      defaultPointsAt: 0,
      conditionByDate: true,
      conditionAddingPoints: [
        {
          countPoints: 0,
          countHours: 0,
          dateBefore: "",
        },
      ],
    },
    timeTrack: [],
  },
};

export const getLessonInfoThunk = createAsyncThunk("lesonInfo", async (lessonId: string) => {
  const { data } = await getLessonById(lessonId);
  return data;
});

export const updateLessonInfoThunk = createAsyncThunk(
  "updateLesonInfo",
  async (lessonData: TLesson, { rejectWithValue }) => {
    try {
      const correctLessonBlocksArray = lessonData.blocks.map((el, index) => ({
        ...el,
        index: index,
      }));
      const { data } = await updateLessonAsync({ ...lessonData, blocks: correctLessonBlocksArray });
      return data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

const lessonConstructorSlice = createSlice({
  name: "lessonConstructor",
  initialState,
  reducers: {
    changePosition(state, action) {
      const swap = (index1: number, index2: number) => {
        state.lessonInfo.blocks[index1] = state.lessonInfo.blocks.splice(
          index2,
          1,
          state.lessonInfo.blocks[index1]
        )[0];
      };

      if (
        (action.payload.indexItem === 0 && action.payload.type === CHAGE_POSITION.UP) ||
        (action.payload.indexItem === state.lessonInfo.blocks.length - 1 &&
          action.payload.type === CHAGE_POSITION.DOWN)
      )
        return;
      if (action.payload.type === CHAGE_POSITION.UP)
        swap(action.payload.indexItem, action.payload.indexItem - 1);
      else if (action.payload.type === CHAGE_POSITION.DOWN)
        swap(action.payload.indexItem, action.payload.indexItem + 1);
      else return;
    },

    removeItem(state, action) {
      state.lessonInfo.blocks.splice(action.payload.indexItem, 1);
    },

    addItem(state, action) {
      const newItem = {
        type: action.payload.typeItem,
        content: ``,
        index: 0,
      };

      if (action.payload.type === CHAGE_POSITION.UP && action.payload.indexItem !== 0)
        state.lessonInfo.blocks.splice(action.payload.indexItem - 1, 0, newItem);
      else if (action.payload.type === CHAGE_POSITION.DOWN)
        state.lessonInfo.blocks.splice(action.payload.indexItem + 1, 0, newItem);
      else state.lessonInfo.blocks.splice(0, 0, newItem);
    },

    changeComponent(state, action) {
      state.lessonInfo.blocks = state.lessonInfo.blocks.map((el, index) => {
        if (index === action.payload.el) {
          return { ...el, content: action.payload.value };
        } else return el;
      });
    },

    updateComponent(state, action) {
      state.lessonInfo = { ...state.lessonInfo, [action.payload.property]: action.payload.value };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLessonInfoThunk.pending, (state) => {
        state.loadingStatus = "loading";
      })
      .addCase(getLessonInfoThunk.fulfilled, (state, action) => {
        state.lessonInfo = action.payload;
        state.loadingStatus = "idle";
      })
      .addCase(getLessonInfoThunk.rejected, (state, action) => {
        state.loadingStatus = "failed";
      });

    builder
      .addCase(updateLessonInfoThunk.pending, (state) => {
        state.loadingStatus = "loading";
      })
      .addCase(updateLessonInfoThunk.fulfilled, (state, action) => {
        state = action.payload;
      })
      .addCase(updateLessonInfoThunk.rejected, (state, action) => {
        state.loadingStatus = "failed";
      });
  },
});

export const { changePosition, removeItem, addItem, changeComponent, updateComponent } =
  lessonConstructorSlice.actions;

export default lessonConstructorSlice;
