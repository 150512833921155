import axios from "axios";
import { API_URL } from "../constants/config";

const http = axios.create({
  baseURL: API_URL,
});

const clearTokens = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  window.location.reload();
};

let refreshingFunc: any = undefined;

function isUnauthorizedError(error) {
  const {
    response: { status },
  } = error;
  return status === 401;
}

export async function renewToken(accessToken: string) {
  const refreshToken = localStorage.getItem("refreshToken");

  if (!refreshToken) throw new Error("refresh token does not exist");

  const response = await http.post("api/account/refresh-token", {
    accessToken,
    refreshToken,
  });

  const token = response.data.accessToken;

  const newRefreshToken = response.data.refreshToken;

  return [token, newRefreshToken];
}

http.interceptors.request.use(
  (config) => {
    const key = localStorage.getItem("accessToken");
    if (key) {
      config.headers = {
        Authorization: `Bearer ${key}`,
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalConfig = error.config;
    const token = localStorage.getItem("accessToken");

    if (!token || !isUnauthorizedError(error)) {
      return Promise.reject(error);
    }

    try {
      if (!refreshingFunc) refreshingFunc = renewToken(token);

      const [newToken, newRefreshToken] = await refreshingFunc;

      localStorage.setItem("accessToken", newToken);
      localStorage.setItem("refreshToken", newRefreshToken);

      originalConfig.headers.Authorization = `Bearer ${newToken}`;

      try {
        return await axios.request(originalConfig);
      } catch (innerError) {
        if (isUnauthorizedError(innerError)) {
          throw innerError;
        }
      }
    } catch (err) {
      clearTokens();
    } finally {
      refreshingFunc = undefined;
    }
  }
);

const get = (url: string, headers = {}, params = {}) => {
  return http.get(url, {
    ...params,
    headers: {
      ...headers,
    },
  });
};

const post = (url: string, data: any, headers = {}, params = {}) => {
  return http.post(url, data, {
    ...params,
    headers: {
      ...headers,
    },
  });
};

const put = (url: string, data: any, headers = {}) => {
  return http.put(url, data, {
    headers: {
      ...headers,
    },
  });
};

const remove = (url: string, data: any, headers = {}) => {
  return http.delete(url, {
    headers: {
      ...headers,
    },
    data,
  });
};
const patch = (url: string, data: any, headers = {}) => {
  return http.patch(url, data, {
    headers: {
      ...headers,
    },
  });
};

const module = {
  http,
  get,
  post,
  put,
  remove,
  patch,
};

export default module;
