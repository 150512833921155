import { TNewHeadCourse, TUpdateHeadCourse } from "typings/headCourse";
import { TAxiosGetCourseList } from "typings/courses";

import http from "./https";

export const getAllHeadCourses = (params: TAxiosGetCourseList) =>
  http.get(
    "/api/head-courses",
    {},
    {
      params,
    }
  );

export const createNewHeadCourse = (headCourseData: TNewHeadCourse) =>
  http.post("/api/head-courses", headCourseData);

export const removeHeadCourse = (headCourseId: number) =>
  http.remove(`/api/head-courses/${headCourseId}`, {});

export const getHeadCourseById = (headCourseId: string) =>
  http.get(`/api/head-courses/${headCourseId}`);

export const updateHeadCourseAsync = (headCourseId: number, headCourseData: TUpdateHeadCourse) =>
  http.patch(`/api/head-courses/${headCourseId}`, headCourseData);
