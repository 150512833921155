import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";
import { openFileStoragePopup } from "slices/fileStoragePopupSlice";
import { FC, useEffect, useState } from "react";
import { IMAGE_COUNTER_LABEL_LIST } from "constants/lesson";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { TCourseAdditionalSettingsTeacher } from "typings/courses";

import styles from "./AdditionalSettings.module.scss";

type AdditionalSettingsTeachersProps = {
  teachersList: TCourseAdditionalSettingsTeacher[];
  changeTeachers: React.Dispatch<React.SetStateAction<TCourseAdditionalSettingsTeacher[]>>;
};

const AdditionalSettingsTeachers: FC<AdditionalSettingsTeachersProps> = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const fileTypes = ["JPEG", "PNG", "JPG"];

  const dispatch = useAppDispatch();

  const { selectedUrl, changedField }: { selectedUrl: string; changedField: string } =
    useAppSelector((state) => state.fileStoragePopup);

  const addNewItem = () => {
    props.changeTeachers((prev) => [...prev, { avatar: "", text: "", fio: "" }]);
  };

  const removeItem = (removeIndex: number) => {
    const newArray = props.teachersList.filter((el, index) => index !== removeIndex);
    props.changeTeachers(newArray);
  };

  const changeItem = (updateIndex: number, fieldName: string, value: string | number | null) => {
    const newArray = props.teachersList.map((el, index) => {
      if (index === updateIndex) {
        return { ...el, [fieldName]: value };
      } else return el;
    });
    props.changeTeachers(newArray);
  };

  const handleOpenFileStoragePopup = (currentIndex: number) => {
    dispatch(
      openFileStoragePopup({
        type: "image",
        fileTypes,
        counterLabels: IMAGE_COUNTER_LABEL_LIST,
        isSingleType: true,
        changedField: "teachers",
      })
    );
    setCurrentIndex(currentIndex);
  };

  useEffect(() => {
    if (selectedUrl && currentIndex >= 0 && changedField === "teachers")
      changeItem(currentIndex, "avatar", selectedUrl);
  }, [currentIndex, selectedUrl, changedField]);

  return (
    <div className={styles["list-container"]}>
      {props.teachersList.map((el, index) => (
        <div
          className={classNames(
            styles["list-item"],
            index + 1 !== props.teachersList.length && styles["separator"]
          )}
          key={index}
        >
          <div>
            <InputText
              value={el.fio}
              onChange={(e) => {
                changeItem(index, "fio", e.target.value);
              }}
              placeholder="Имя"
              className={styles["form-item"]}
            />

            <div
              className={styles["uloader-wrapper"]}
              onClick={() => handleOpenFileStoragePopup(index)}
            >
              <div
                className={classNames(
                  styles["image-wrapper"],
                  el.avatar && styles["filled-image-wrapper"]
                )}
              >
                {el.avatar ? (
                  <img alt="Preview" src={el.avatar} className={styles["img"]}></img>
                ) : (
                  <div className={styles["empty-image-wrapper"]}>
                    <span>
                      <i className="pi pi-image" />
                    </span>
                    <p>Загрузите изображение</p>
                  </div>
                )}
              </div>
            </div>

            <InputTextarea
              value={el.text}
              onChange={(e) => {
                changeItem(index, "text", e.target.value);
              }}
              rows={3}
              cols={30}
              autoResize
              placeholder="Комментарий"
              className={styles["form-item"]}
            />
          </div>
          <button className={styles["remove-btn"]} onClick={() => removeItem(index)}>
            <i className="pi pi-trash" />
          </button>
        </div>
      ))}
      <button className={styles["add-btn"]} onClick={addNewItem}>
        Добавить преподавателя
      </button>
    </div>
  );
};

export default AdditionalSettingsTeachers;
