import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { TModuleInfo } from "typings/module";
import { TShortCourse } from "typings/courses";
import { getShortListCoursesModules } from "api/courses";
import { FC, useCallback, useEffect, useState } from "react";

import styles from "../components/Lesson/LessonUpdate/UpdateLesson.module.scss";

type CopyModulePopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  copyModule: (name: string, copyCourse: number) => void;
  moduleInfo: TModuleInfo | null;
};

const CopyModulePopup: FC<CopyModulePopupProps> = (props) => {
  const [allCoursesList, setAllCouresesList] = useState<TShortCourse[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<number | null>(null);
  const [moduleName, setModuleName] = useState<string>("");

  const closePopup = () => {
    setSelectedCourse(null);
    props.handleHide();
  };

  useEffect(() => {
    if (props.moduleInfo) setModuleName(props.moduleInfo.name);
  }, [props.moduleInfo]);

  const getCoursesList = useCallback(async () => {
    try {
      const response = await getShortListCoursesModules();
      if (response.status === 200 || response.status === 201) {
        const coursesList: TShortCourse[] = [];

        response.data.map((el) =>
          el.courses.map((step) => {
            coursesList.push({ id: step.id, index: step.index, name: step.name });
          })
        );

        setAllCouresesList(coursesList);
      } else throw new Error();
    } catch (err: any) {}
  }, []);

  useEffect(() => {
    props.isOpen && getCoursesList();
  }, [getCoursesList, props.isOpen]);

  return (
    <Dialog
      visible={props.isOpen}
      style={{ width: "500px" }}
      modal
      onHide={closePopup}
      header={`Копировать моуль`}
    >
      <div className={styles["popup-wrapper"]}>
        <span className={styles["input-wrapper"]}>
          <p>Новое название модуля</p>
          <InputText
            name="name"
            value={moduleName}
            onChange={(e) => setModuleName(e.target.value)}
            placeholder="Введите название модуля"
          />
        </span>
        <span className={styles["input-wrapper"]}>
          <p>Поместить копию в</p>
          <Dropdown
            options={allCoursesList}
            onChange={(e) => setSelectedCourse(e.value)}
            value={selectedCourse}
            placeholder="Выберите куда копировать модуль"
            className={styles["available-dropdown"]}
            optionLabel="name"
            optionValue="id"
          />
        </span>

        <div className={styles["btn-wrap"]}>
          <button className={styles["cancel-btn"]} onClick={closePopup}>
            Отмена
          </button>
          <button
            className={styles["copy-btn"]}
            onClick={() => {
              selectedCourse && props.copyModule(moduleName, selectedCourse);
              closePopup();
            }}
            disabled={!moduleName || !selectedCourse}
          >
            Копировать
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default CopyModulePopup;
