import { TLesson } from "typings/lesson";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useAppDispatch } from "utils/hooks";
import { updateComponent } from "slices/lessonConstructor";
import { FC, useCallback } from "react";

import styles from "./Constructor.module.scss";

type LessonTimeCodeListProps = {
  lessonInfo: TLesson;
};

const LessonTimeCodeList: FC<LessonTimeCodeListProps> = (props) => {
  const dispatch = useAppDispatch();

  const addNewItem = () => {
    dispatch(
      updateComponent({
        property: "timeTrack",
        value: props.lessonInfo.timeTrack
          ? [...props.lessonInfo.timeTrack, { time: "", text: "" }]
          : [{ time: "", text: "" }],
      })
    );
  };

  const removeItem = (removeIndex: number) => {
    const newArray = props.lessonInfo.timeTrack.filter((el, index) => index !== removeIndex);

    dispatch(
      updateComponent({
        property: "timeTrack",
        value: newArray,
      })
    );
  };

  const changeItem = useCallback(
    (updateIndex: number, value: string, field: string) => {
      const newArray = props.lessonInfo.timeTrack.map((el, index) => {
        if (index === updateIndex) {
          return {
            ...el,
            [field]: value,
          };
        } else return el;
      });

      dispatch(
        updateComponent({
          property: "timeTrack",
          value: newArray,
        })
      );
    },
    [props.lessonInfo.timeTrack, dispatch]
  );

  return (
    <div className={styles["tracklist"]}>
      {(props.lessonInfo.timeTrack &&
        props.lessonInfo.timeTrack.length > 0 &&
        props.lessonInfo.timeTrack.map((el, index) => (
          <div className={styles["list-item"]} key={index}>
            <InputText
              value={el.time}
              onChange={(e) => {
                changeItem(index, e.target.value, "time");
              }}
              placeholder="Время"
              className={styles["form-item"]}
            />
            <InputText
              value={el.text}
              onChange={(e) => {
                changeItem(index, e.target.value, "text");
              }}
              placeholder="Текст"
              className={classNames(styles["form-item"], styles["form-second-item"])}
            />
            <button className={styles["remove-btn"]} onClick={() => removeItem(index)}>
              <i className="pi pi-trash" />
            </button>
          </div>
        ))) || <span className={styles["empty-list"]}>Список пуст</span>}
      <button className={styles["add-btn"]} onClick={addNewItem}>
        Добавить
      </button>
    </div>
  );
};

export default LessonTimeCodeList;
