export const CORSES = [
  {
    label: "Курс 1",
    value: "Курс 1",
  },

  {
    label: "Курс 2",
    value: "Курс 2",
  },
  {
    label: "Курс 3",
    value: "Курс 3",
  },
];

export const UPDATE_COURSE_PAGE_MENU: {
  label: string;
  name: string;
}[] = [
  {
    label: "Содержание",
    name: "content",
  },
  {
    label: "Настройки",
    name: "settings",
  },
  {
    label: "Расписание",
    name: "schedule",
  },
  {
    label: "Ученики",
    name: "students",
  },
  {
    label: "Тарифы",
    name: "tarifs",
  },
];

export const STUDENTS_LIST = [
  {
    id: "1",
    fio: "Екатерина Волкова",
    status: "",
    created: "Пн 12 Июн",
  },
  {
    id: "2",
    fio: "Екатерина Волкова",
    status: "",
    created: "Пн 12 Июн",
  },
  {
    id: "3",
    fio: "Екатерина Волкова",
    status: "",
    created: "Пн 12 Июн",
  },
  {
    id: "4",
    fio: "Екатерина Волкова",
    status: "",
    created: "Пн 12 Июн",
  },
  {
    id: "5",
    fio: "Екатерина Волкова",
    status: "",
    created: "Пн 12 Июн",
  },
];

export const COURSES_CONTENT = [
  {
    id: "1",
    module_name: "Модуль 0. Вводный",
    lesson_counter: 3,
    lessons: [
      {
        id: "1",
        name: "Урок 1. Послание от матери пластики.",
      },
      {
        id: "2",
        name: "Урок 2. Деньги, спец. задания и бонусы.",
      },
      {
        id: "3",
        name: "Урок 3. Пластичный бот",
      },
    ],
  },
  {
    id: "2",
    module_name: "Модуль 1. Изоляции",
    lesson_counter: 4,
    lessons: [
      {
        id: "1",
        name: "Урок 1. Послание от матери пластики.",
      },
      {
        id: "2",
        name: "Урок 3. Пластичный бот",
      },
      {
        id: "3",
        name: "Урок 1. Послание от матери пластики.",
      },
      {
        id: "4",
        name: "Урок 3. Пластичный бот",
      },
    ],
  },
  {
    id: "3",
    module_name: "Модуль 3. Волны",
    lesson_counter: 6,
    lessons: [
      {
        id: "1",
        name: "Урок 3. Пластичный бот",
      },
      {
        id: "2",
        name: "Урок 3. Пластичный бот",
      },
      {
        id: "3",
        name: "Урок 3",
      },
      {
        id: "4",
        name: "Урок 3. Пластичный бот",
      },
      {
        id: "5",
        name: "Урок 5",
      },
      {
        id: "6",
        name: "Урок 6",
      },
    ],
  },
];
