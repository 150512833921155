export const phrases = {
  smthWentWrong: "Что-то пошло не так! Проверьте данные и повторите попытку",
  welocome: "Добро пожаловать",
  tableNotifications1: "Нажмите 'Enter' чтобы перейти к нужной странице.",
  enter: "Enter",
  smthWentWrongText: "Что-то пошло не так!",
  dontAAccess: "У вас нет доступа!",

  admin_success_added: "Администратор успешно добавлен",
  user_success_added: "Пользователь успешно добавлен",
  admin_success_updated: "Администратор успешно обновлен",
  user_success_updated: "Пользователь успешно обновлен",
  admin_sucess_deleted: "Администратор успешно удален",
  user_sucess_deleted: "Пользователь успешно удален",
  login_sucess: "Авторизация прошла успешно",
  incorrect_login_or_pwd: "Неверный логин/пароль",
  success_save_change: "Изменения успешно сохранены",

  student_success_added: "Студент успешно добавлен",
  students_success_added: "Студенты успешно добавлены",
  student_sucess_deleted: "Студент успешно удален",
  student_success_updated: "Студент успешно обновлен",

  teacher_success_added: "Преподаватель успешно добавлен",
  teachers_success_added: "Преподаватели успешно добавлены",

  send_pwd: "Новый пароль отправлен вам на почту.",

  course_success_added: "Курс успешно добавлен",
  course_success_updated: "Курс успешно обновлен",
  course_success_deleted: "Курс успешно удален",
  course_success_copy: "Курс успешно скопирован",

  module_success_added: "Модуль успешно добавлен",
  module_success_updated: "Модуль успешно обновлен",
  module_success_deleted: "Модуль успешно удален",
  module_success_copy: "Модуль успешно скопирован",

  lesson_success_added: "Урок успешно добавлен",
  lesson_success_updated: "Урок успешно обновлен",
  lesson_success_deleted: "Урок успешно удален",
  lesson_success_copy: "Урок успешно скопирован",

  shedule_success_updated: "Расписание успешно обновлено",

  file_success_uploaded: "Файл успешно загружен",
  file_success_deleted: "Файл успешно удален",

  step_success_added: "Степ успешно добавлен",
  step_success_updated: "Степ успешно обновлен",
  step_success_deleted: "Степ успешно удален",
  step_success_copy: "Степ успешно скопирован",

  tarif_success_added: "Тариф успешно добавлен",
  tarif_success_deleted: "Тариф успешно удален",
  tarif_success_updated: "Тариф успешно обновлен",
};
