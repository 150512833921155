import { FC } from "react";
import { Dialog } from "primereact/dialog";
import { TShortListTarifs } from "typings/tarifs";

import styles from "../components/comon/course-tarifs-table/CourseTarifsTable.module.scss";

type RemoveTarifPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  selectedTarif: TShortListTarifs | null;
  deleteTarif: () => void;
};

const RemoveTarifPopup: FC<RemoveTarifPopupProps> = (props) => {
  return (
    <Dialog
      visible={props.isOpen}
      style={{ width: "345px" }}
      modal
      onHide={props.handleHide}
      header={`Удаление тарифа`}
    >
      <div className={styles["remove-tarif-popup-wrapper"]}>
        <i className="pi pi-info-circle"></i>

        <h1>Вы уверены, что хотите удалить следующий тариф?</h1>

        <span>{props.selectedTarif?.name || "Тариф (без имени)"}</span>

        <div className={styles["remove-tarif-btn-wrap"]}>
          <button className={styles["cancel-btn"]} onClick={props.handleHide}>
            Отмена
          </button>
          <button className={styles["remove-btn"]} onClick={props.deleteTarif}>
            Удалить
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default RemoveTarifPopup;
