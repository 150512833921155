import { FC, useCallback, useEffect, useState } from "react";
import { tableControlsType } from "typings/table-controls";
import { useAppDispatch } from "utils/hooks";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { phrases } from "constants/phrases";
import { createNewAdmin, getAllAdmins, removeAdmin, updateAdminAsync } from "api/admins";
import { ERRORS } from "constants/errors";
import { TAdmin, TNewAdmin } from "typings/admins";
import CreateAdminPopup from "popups/create-admin";
import RemoveAdminPopup from "popups/remove-admin";
import EditAdminPopup from "popups/edit-admin";
import AdminsLayout from "./Admins.layout";
import AdminInfoPopup from "popups/admin-info";

type AdminsProps = {};

const defaultParams: tableControlsType = {
  first: 0,
  rows: 15,
  page: 0,
  search: "",
};

const Admins: FC<AdminsProps> = () => {
  const [adminsList, setAdminsList] = useState<TAdmin[]>([]);
  const [params, setParams] = useState<tableControlsType>(defaultParams);
  const [selectedAdmin, setSelectedAdmin] = useState<TAdmin | null>(null);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const [isOpenCreateAdminPopup, setIsOpenCreateAdminPopup] = useState<boolean>(false);
  const [isOpenEditAdminPopup, setIsOpenEditAdminPopup] = useState<boolean>(false);
  const [isOpenRemoveAdminPopup, setIsOpenRemoveAdminPopup] = useState<boolean>(false);
  const [isOpenAdminInfoPopup, setIsOpenAdminInfoPopup] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const handleSelectedAdmin = (admin: TAdmin) => setSelectedAdmin(admin);

  const handleOpenCreateAdminPopup = () => setIsOpenCreateAdminPopup(true);
  const handleCloseCreateAdminPopup = () => setIsOpenCreateAdminPopup(false);

  const handleOpenEditAdminPopup = () => setIsOpenEditAdminPopup(true);
  const handleCloseEditAdminPopup = () => setIsOpenEditAdminPopup(false);

  const handleOpenRemoveAdminPopup = () => setIsOpenRemoveAdminPopup(true);
  const handleCloseRemoveAdminPopup = () => setIsOpenRemoveAdminPopup(false);

  const handleOpenAdminInfoPopup = () => setIsOpenAdminInfoPopup(true);
  const handleCloseAdminInfoPopup = () => setIsOpenAdminInfoPopup(false);

  const changeSelectedAdmin = (name: string, value: string | boolean) => {
    setSelectedAdmin((prev: any) => ({ ...prev, [name]: value }));
  };

  const getAdminsList = useCallback(
    async (params) => {
      setIsLoadingTable(true);
      try {
        const response = await getAllAdmins(params);
        if (response.status === 200 || response.status === 201) {
          setAdminsList(response.data.data);
          setTotalRecords(response.data.pagination.totalItems);
          setIsLoadingTable(false);
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    getAdminsList({
      page: params.page,
      limit: params.rows,
      search: params.search || undefined,
    });
  }, [params, getAdminsList]);

  const createAdmin = useCallback(
    async (adminData: TNewAdmin) => {
      setIsLoadingTable(true);
      try {
        const response = await createNewAdmin(adminData);
        if (response.status === 200 || response.status === 201) {
          getAdminsList({
            page: params.page,
            limit: params.rows,
            search: params.search || undefined,
          });
          dispatch(addSuccessMessage(phrases.user_success_added));
        } else throw new Error();
      } catch (err: any) {
        if (err.response.data.message.includes(ERRORS.invalid_email.backend_value))
          return dispatch(addErrorMessage(ERRORS.invalid_email.alert));
        else if (err.response.data.message.includes(ERRORS.uniq_email_required.backend_value))
          return dispatch(addErrorMessage(ERRORS.uniq_email_required.alert));
        else return dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrong));
      } finally {
        handleCloseCreateAdminPopup();
        setIsLoadingTable(false);
      }
    },
    [dispatch]
  );

  const deleteAdmin = useCallback(async () => {
    setIsLoadingTable(true);
    try {
      if (selectedAdmin) {
        const response = await removeAdmin(selectedAdmin.id);
        if (response.status === 200 || response.status === 201) {
          dispatch(addSuccessMessage(phrases.user_sucess_deleted));
          getAdminsList({
            page: params.page,
            limit: params.rows,
            search: params.search || undefined,
          });
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
    } finally {
      handleCloseRemoveAdminPopup();
      setIsLoadingTable(false);
    }
  }, [dispatch, selectedAdmin]);

  const updateAdmin = useCallback(
    async (adminData: TNewAdmin) => {
      setIsLoadingTable(true);
      try {
        if (selectedAdmin) {
          const response = await updateAdminAsync(selectedAdmin?.id, adminData);
          if (response.status === 200 || response.status === 201) {
            getAdminsList({
              page: params.page,
              limit: params.rows,
              search: params.search || undefined,
            });
            dispatch(addSuccessMessage(phrases.user_success_updated));
          } else throw new Error();
        } else return;
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      } finally {
        handleCloseEditAdminPopup();
        setIsLoadingTable(false);
      }
    },
    [dispatch, selectedAdmin]
  );

  return (
    <>
      <AdminsLayout
        params={params}
        setParams={setParams}
        adminsList={adminsList}
        handleSelectedAdmin={handleSelectedAdmin}
        totalRecords={totalRecords}
        isLoadingTable={isLoadingTable}
        handleOpenCreateAdminPopup={handleOpenCreateAdminPopup}
        handleOpenRemoveAdminPopup={handleOpenRemoveAdminPopup}
        handleOpenEditAdminPopup={handleOpenEditAdminPopup}
        handleOpenAdminInfoPopup={handleOpenAdminInfoPopup}
      />
      <CreateAdminPopup
        isOpen={isOpenCreateAdminPopup}
        handleHide={handleCloseCreateAdminPopup}
        createAdmin={createAdmin}
      />
      {selectedAdmin && (
        <EditAdminPopup
          isOpen={isOpenEditAdminPopup}
          handleHide={handleCloseEditAdminPopup}
          selectedAdmin={selectedAdmin}
          changeSelectedAdmin={changeSelectedAdmin}
          updateAdmin={updateAdmin}
        />
      )}
      <RemoveAdminPopup
        isOpen={isOpenRemoveAdminPopup}
        handleHide={handleCloseRemoveAdminPopup}
        selectedAdmin={selectedAdmin}
        deleteAdmin={deleteAdmin}
      />

      <AdminInfoPopup
        isOpen={isOpenAdminInfoPopup}
        handleHide={handleCloseAdminInfoPopup}
        selectedAdmin={selectedAdmin}
      />
    </>
  );
};

export default Admins;
