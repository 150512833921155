// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BreadCrumb_breadcrumb__k9ZVH {
  width: 100%;
  min-height: 40px;
  border: none;
  background-color: transparent;
  padding: 20px 0px;
}
.BreadCrumb_breadcrumb__k9ZVH li,
.BreadCrumb_breadcrumb__k9ZVH span,
.BreadCrumb_breadcrumb__k9ZVH a {
  font-size: 16px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/BreadCrumb/BreadCrumb.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,YAAA;EACA,6BAAA;EACA,iBAAA;AACF;AACE;;;EAGE,0BAAA;AACJ","sourcesContent":[".breadcrumb {\r\n  width: 100%;\r\n  min-height: 40px;\r\n  border: none;\r\n  background-color: transparent;\r\n  padding: 20px 0px;\r\n\r\n  li,\r\n  span,\r\n  a {\r\n    font-size: 16px !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumb": `BreadCrumb_breadcrumb__k9ZVH`
};
export default ___CSS_LOADER_EXPORT___;
