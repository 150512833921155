// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_loaderWrapper__XU8WQ {
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 100000;
  flex-direction: column;
}
.Loader_loaderWrapper__XU8WQ p {
  font-size: 32px;
  color: #771feb;
  margin-top: 20px;
  width: 100%;
  text-align: center;
  padding-left: 20px;
}

.Loader_showLoader__7GpVv {
  display: flex;
}

.Loader_hideLoader__9G9VZ {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,sBAAA;AACF;AACE;EACE,eAAA;EACA,cAAA;EACA,gBAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;AACJ;;AAGA;EACE,aAAA;AAAF;;AAGA;EACE,aAAA;AAAF","sourcesContent":[".loaderWrapper {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  justify-content: center;\n  align-items: center;\n  z-index: 100000;\n  flex-direction: column;\n\n  p {\n    font-size: 32px;\n    color: #771feb;\n    margin-top: 20px;\n    width: 100%;\n    text-align: center;\n    padding-left: 20px;\n  }\n}\n\n.showLoader {\n  display: flex;\n}\n\n.hideLoader {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderWrapper": `Loader_loaderWrapper__XU8WQ`,
	"showLoader": `Loader_showLoader__7GpVv`,
	"hideLoader": `Loader_hideLoader__9G9VZ`
};
export default ___CSS_LOADER_EXPORT___;
