import { phrases } from "constants/phrases";
import { TShedule } from "typings/courses";
import { getRoles } from "utils/getRoles";
import { classNames } from "primereact/utils";
import { TModuleInfo } from "typings/module";
import { useAppDispatch } from "utils/hooks";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { getModuleSheduleAsync, updateModuleShedule } from "api/module";
import { FC, memo, useCallback, useEffect, useState } from "react";

import Shedule from "components/comon/shedule/Shedule";

import styles from "./Shedule.module.scss";

type ModuleSheduleTabProps = {
  moduleInfo: TModuleInfo | null;
};

const ModuleSheduleTab: FC<ModuleSheduleTabProps> = (props) => {
  const [shedule, setShedule] = useState<TShedule[]>([]);
  const [initShedule, setInitShedule] = useState<TShedule[]>([]);

  const isAdmin = getRoles();

  const dispatch = useAppDispatch();

  const getModuleShedule = useCallback(async () => {
    try {
      if (props.moduleInfo) {
        const response = await getModuleSheduleAsync(props.moduleInfo.id);
        if (response.status === 200 || response.status === 201) {
          setShedule(response.data);
          setInitShedule(response.data);
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {}
  }, [dispatch, props.moduleInfo]);

  useEffect(() => {
    getModuleShedule();
  }, [getModuleShedule]);

  const changeSheduleDate = (lessonId: number, newDate: string) => {
    const updatedShedule = shedule.map((el) => {
      if (el.id === lessonId) {
        return { ...el, startAt: newDate };
      } else return el;
    });
    setShedule(updatedShedule);
  };

  const changeSheduleVisible = (lessonId: number, visibility: string) => {
    const updatedShedule = shedule.map((el) => {
      if (el.id === lessonId) {
        return { ...el, visibility: visibility };
      } else return el;
    });
    setShedule(updatedShedule);
  };

  const updateShedule = useCallback(async () => {
    try {
      if (props.moduleInfo) {
        const response = await updateModuleShedule(props.moduleInfo?.id, shedule);
        if (response.status === 200 || response.status === 201) {
          dispatch(addSuccessMessage(phrases.shedule_success_updated));
          getModuleShedule();
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
    }
  }, [dispatch, props.moduleInfo, shedule]);

  return (
    <div className={styles["main-wrapper"]}>
      <Shedule
        shedule={shedule}
        changeSheduleDate={changeSheduleDate}
        changeSheduleVisible={changeSheduleVisible}
        isAdmin={isAdmin}
      />
      {isAdmin && (
        <button
          className={classNames(
            styles["save-button"],
            initShedule !== shedule && styles["save-button-visible"]
          )}
          onClick={updateShedule}
        >
          Сохранить изменения
        </button>
      )}
    </div>
  );
};

export default memo(ModuleSheduleTab);
