import { getRoles } from "utils/getRoles";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";
import { TModuleInfo, TUpdateModule } from "typings/module";
import { FC, memo, useEffect, useState } from "react";

import styles from "./Settings.module.scss";

type ModuleSettingsTabProps = {
  moduleInfo: TModuleInfo | null;
  updateModule: (moduleData: TUpdateModule) => void;
};

const ModuleSettingsTab: FC<ModuleSettingsTabProps> = (props) => {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [tag, setTag] = useState<string>("");

  const isAdmin = getRoles();

  useEffect(() => {
    if (props.moduleInfo) {
      setName(props.moduleInfo.name);
      setDescription(props.moduleInfo.description);
      setTag(props.moduleInfo.tag);
    }
  }, [props.moduleInfo]);

  return (
    <div className={styles["main-wrapper"]}>
      <div className={styles["main-wrapper-content"]}>
        <div className={styles["main-wrapper-content-left"]}>
          <span className={styles["main-wrapper-content-left-label"]}>
            <p>Название</p>
            <InputText
              id="name"
              name="name"
              placeholder="Название"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={!isAdmin}
            />
          </span>
          <span className={styles["main-wrapper-content-left-label"]}>
            <p>Описание</p>
            <InputTextarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={5}
              cols={30}
              autoResize
              placeholder="Описание"
              disabled={!isAdmin}
            />
          </span>
          <span className={styles["main-wrapper-content-left-label"]}>
            <p>Тег</p>
            <InputText
              id="tag"
              name="tag"
              placeholder="Тег"
              value={tag}
              onChange={(e) => setTag(e.target.value)}
              disabled={!isAdmin}
            />
          </span>
        </div>
        <div className={styles["main-wrapper-content-right"]}></div>
      </div>
      {isAdmin && (
        <button
          className={classNames(
            styles["save-button"],
            (name !== props.moduleInfo?.name ||
              description !== props.moduleInfo?.description ||
              tag !== props.moduleInfo?.tag) &&
              styles["save-button-visible"]
          )}
          onClick={() => props.updateModule({ name, description, tag })}
        >
          Сохранить изменения
        </button>
      )}
    </div>
  );
};

export default memo(ModuleSettingsTab);
