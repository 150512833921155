import { useEffect, useRef } from "react";
import AppMenu from "./AppMenu";

const AppTopbar = (props) => {
  const searchPanelDesktop = useRef(null);
  const searchPanelMobile = useRef(null);

  useEffect(() => {
    if (props.searchActive) {
      if (window.innerWidth >= 576)
        //@ts-ignore
        searchPanelDesktop.current.focus();
      //@ts-ignore
      else searchPanelMobile.current.focus();
    }
  }, [props.searchActive]);

  return (
    <AppMenu
      menuMode={props.menuMode}
      sidebarStatic={props.sidebarStatic}
      model={props.menu}
      menuActive={props.menuActive}
      onRootMenuItemClick={props.onRootMenuItemClick}
      onMobileMenuActive={props.onMobileMenuActive}
      onMenuItemClick={props.onMenuItemClick}
      onSidebarMouseOver={props.onSidebarMouseOver}
      onSidebarMouseLeave={props.onSidebarMouseLeave}
      onToggleMenu={props.onToggleMenu}
      resetActiveIndex={props.resetActiveIndex}
      onMenuClick={props.onMenuClick}
    />
  );
};

export default AppTopbar;
