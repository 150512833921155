import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { getRoles } from "utils/getRoles";
import { classNames } from "primereact/utils";
import { useNavigate } from "react-router";
import { TabPanel, TabView } from "primereact/tabview";
import { UPDATE_MODULE_PAGE_MENU } from "constants/module";
import { TModuleInfo, TUpdateModule } from "typings/module";
import { FC, memo, useCallback, useMemo, useRef, useState } from "react";

// import ModuleStudentsTab from "./tabs/students/Students";
import ModuleContentTab from "./tabs/content/Content";
import ModuleSettingsTab from "./tabs/settings/Settings";
import ModuleSheduleTab from "./tabs/shedule/Shedule";
import ModuleAccessTab from "./tabs/access/Access";

import styles from "./UpdateModule.module.scss";

type UpdateModuleLayoutProps = {
  moduleInfo: TModuleInfo | null;
  courseId: string | null | undefined;
  headCourseId: string | null | undefined;
  updateModule: (module: TUpdateModule) => void;
  handleOpenCreateLessonPopup: () => void;
  handleOpenRemoveModulePopup: () => void;
  handleOpenChangePlacesPopup: (type: string) => void;
  handleOpenCopyModulePopup: () => void;
};

const UpdateModuleLayout: FC<UpdateModuleLayoutProps> = (props) => {
  const [openMenuTab, setOpenMenuTab] = useState<any>(UPDATE_MODULE_PAGE_MENU[0].name);

  const isAdmin = getRoles();

  const actionPageMenuRef = useRef(null);

  const navigate = useNavigate();

  const activeMenuIndex = useMemo(
    () => UPDATE_MODULE_PAGE_MENU.findIndex((el) => el.name === openMenuTab),
    [openMenuTab]
  );

  const renderActiveTab = useCallback(() => {
    switch (openMenuTab) {
      case UPDATE_MODULE_PAGE_MENU[0].name:
        return props.moduleInfo && props.moduleInfo.lessons.length < 1 ? (
          <div className={styles["empty-content"]}>
            <i className="pi pi-info-circle" />
            <p>Модуль пуст. Вы еще не добавили ни одного урока!</p>
          </div>
        ) : (
          <ModuleContentTab
            moduleInfo={props.moduleInfo}
            courseId={props.courseId}
            headCourseId={props.headCourseId}
          />
        );
      case UPDATE_MODULE_PAGE_MENU[1].name:
        return (
          <ModuleSettingsTab updateModule={props.updateModule} moduleInfo={props.moduleInfo} />
        );
      case UPDATE_MODULE_PAGE_MENU[2].name:
        return <ModuleAccessTab />;
      case UPDATE_MODULE_PAGE_MENU[3].name:
        return <ModuleSheduleTab moduleInfo={props.moduleInfo} />;
      // case UPDATE_MODULE_PAGE_MENU[4].name:
      //   return <ModuleStudentsTab moduleInfo={props.moduleInfo} />;
      default:
        return props.moduleInfo && props.moduleInfo.lessons.length < 1 ? (
          <div className={styles["empty-content"]}>
            <i className="pi pi-info-circle" />
            <p>Модуль пуст. Вы еще не добавили ни одного урока!</p>
          </div>
        ) : (
          <ModuleContentTab
            moduleInfo={props.moduleInfo}
            courseId={props.courseId}
            headCourseId={props.headCourseId}
          />
        );
    }
  }, [openMenuTab, props.moduleInfo]);

  const ACTION_PAGE_BTN = [
    {
      label: "Редактировать модуль",
      icon: "pi pi-pencil",
      command: () => {
        setOpenMenuTab(UPDATE_MODULE_PAGE_MENU[1].name);
      },
    },
    {
      label: "Настроить расписание",
      icon: "pi pi-calendar",
      command: () => {
        setOpenMenuTab(UPDATE_MODULE_PAGE_MENU[3].name);
      },
    },
    {
      label: "Копировать модуль",
      icon: "pi pi-copy",
      command: props.handleOpenCopyModulePopup,
    },
    {
      label: "Изменить порядок уроков",
      icon: "pi pi-sort-alt",
      command: () => {
        setOpenMenuTab(UPDATE_MODULE_PAGE_MENU[0].name);
        props.handleOpenChangePlacesPopup("lessons");
      },
    },
    {
      label: "Удалить модуль",
      icon: "pi pi-trash",
      command: props.handleOpenRemoveModulePopup,
    },
  ];

  return (
    <div className={styles["main-wrapper"]}>
      <div className={styles["main-wrapper-header"]}>
        <div className={styles["main-wrapper-header-title"]}>
          <span>{props.moduleInfo?.name}</span>
          <span
            className={styles["back-content"]}
            onClick={() =>
              navigate(
                `/courses/update_step?step_id=${props.courseId}&course_id=${props.headCourseId}`
              )
            }
          >
            <i className="pi pi-arrow-left" />
            <p>Вернуться к степу</p>
          </span>
        </div>
        <div className={styles["actions-container"]}>
          {isAdmin && (
            <div className={styles["main-wrapper-header-actions"]}>
              <Menu model={ACTION_PAGE_BTN} popup ref={actionPageMenuRef} id="actionPageMenuRef" />
              <Button
                label="Действия"
                icon="pi pi-bolt"
                //@ts-ignore
                onClick={(event) => actionPageMenuRef.current.toggle(event)}
                aria-controls="actionPageMenuRef"
                aria-haspopup
                className={classNames(
                  styles["main-wrapper-header-actions-button"],
                  styles["main-wrapper-header-actions-action-button"],
                  openMenuTab !== UPDATE_MODULE_PAGE_MENU[0].name && styles["allone_btn"]
                )}
              />

              <Button
                onClick={props.handleOpenCreateLessonPopup}
                className={classNames(
                  styles["main-wrapper-header-actions-button"],
                  styles["main-wrapper-header-actions-add-button"],
                  openMenuTab !== UPDATE_MODULE_PAGE_MENU[0].name && styles["hide"]
                )}
              >
                <i className="pi pi-plus" />
                Добавить урок
              </Button>
            </div>
          )}
        </div>
      </div>

      <div className={styles["main-wrapper-menu"]}>
        <TabView
          activeIndex={activeMenuIndex}
          onTabChange={(e) => setOpenMenuTab(UPDATE_MODULE_PAGE_MENU[e.index].name)}
        >
          {UPDATE_MODULE_PAGE_MENU.map((el) => (
            <TabPanel
              key={el.name}
              headerTemplate={(options) => (
                <span
                  className={classNames(
                    options.className,
                    styles["main-wrapper-menu-item"],
                    openMenuTab === el.name && styles["main-wrapper-menu-active-item"]
                  )}
                  onClick={options.onClick}
                >
                  <p>{el.label}</p>
                </span>
              )}
            />
          ))}
        </TabView>
      </div>

      <div className={styles["main-wrapper-content"]}>{renderActiveTab()}</div>
    </div>
  );
};

export default memo(UpdateModuleLayout);
