export const EDITORS_COLORS = [
  "#000000",
  "#ff0000",
  "#ff00bf",
  "#5e00ff",
  "#00bfff",
  "#00ff40",
  "#c8ff00",
  "#ff6600",
  "#ffffff",
  "#F2F4F6",
];
