import { TFile } from "typings/files";
import { Loader } from "components/Loader/Loader";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ERRORS } from "constants/errors";
import { phrases } from "constants/phrases";
import { FileUploader } from "react-drag-drop-files";
import { AxiosResponse } from "axios";
import { ProgressLoader } from "components/ProgressLoader/ProgressLoader";
import { formatCounterLabel } from "utils/formatCounterLabel";
import { LESSON_TYPE_COMPONENTS } from "constants/lesson";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { changeComponent, updateComponent } from "slices/lessonConstructor";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { FC, useCallback, useEffect, useState } from "react";
import { changeSelectedUrl, closeFileStoragePopup } from "slices/fileStoragePopupSlice";
import {
  completeMultipartUpload,
  getUploadedFiles,
  initMultipartUpload,
  removeFile,
  uploadChunk,
  uploadFile,
} from "api/file-storage";

import { FilesPopapFile } from "./content/file/file";
import { FilesPopapImage } from "./content/image/image";
import { FilesPopapVideo } from "./content/video/video";
import { FilesPopapAudio } from "./content/audio/audio";

import styles from "./file-torage.module.scss";

type FilesStoragePopupProps = {};

const chunkSize = 6000000;

const FilesStoragePopup: FC<FilesStoragePopupProps> = (props) => {
  const [key, setKey] = useState("");
  const [uploadId, setUploadId] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [filesList, setFilesList] = useState<TFile[]>([]);
  const [largeFile, setLargeFile] = useState<Blob | null>(null);
  const [loadingLarge, setLoadingLarge] = useState<boolean>(false);
  const [progressLargeLoading, setProgressLargeLoading] = useState(0);

  const { isOpen, type, indexLessonBlocks, fileTypes, counterLabels, isSingleType, isLessonEdit } =
    useAppSelector((state) => state.fileStoragePopup);

  const dispatch = useAppDispatch();

  const handleHide = () => dispatch(closeFileStoragePopup());

  const getFilesList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getUploadedFiles(
        type === LESSON_TYPE_COMPONENTS.FILE ? LESSON_TYPE_COMPONENTS.OTHER : type
      );
      if (response.status === 200 || response.status === 201) {
        setFilesList(response.data);
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
    } finally {
      setLoading(false);
    }
  }, [dispatch, type]);

  useEffect(() => {
    isOpen && getFilesList();
  }, [getFilesList, isOpen]);

  const handleUploadFile = useCallback(
    async (file: Blob | null) => {
      try {
        if (file) {
          setLoading(true);
          const formData = new FormData();
          formData.append("file", file[0]);
          const response = await uploadFile(formData);
          if (response.status === 200 || response.status === 201) {
            getFilesList();
            dispatch(addSuccessMessage(phrases.file_success_uploaded));
          } else throw new Error();
        } else return;
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      } finally {
        setLoading(false);
      }
    },
    [dispatch, getFilesList]
  );

  const initUploadWithChunks = async () => {
    const responseInitUpload: AxiosResponse<{ key: string; uploadId: string }> =
      await initMultipartUpload();
    if (responseInitUpload.data.key && responseInitUpload.data.uploadId) {
      setKey(responseInitUpload.data.key);
      setUploadId(responseInitUpload.data.uploadId);
    }
  };

  const handleLargeFile = (file: Blob | null) => {
    if (!file) return;
    setLargeFile(file[0]);
    initUploadWithChunks();
  };

  const uploadWithChunks = useCallback(async () => {
    try {
      setLoadingLarge(true);
      if (key && uploadId && largeFile) {
        let counter = 0;
        const filesize = largeFile.size;
        //@ts-ignore
        const fileName = largeFile.name;

        for (let start = 0; start < filesize; start += chunkSize) {
          const chunk = largeFile.slice(start, start + chunkSize);

          const fd = new FormData();
          fd.append("file", chunk);

          await uploadChunk({
            key,
            uploadId,
            index: counter + 1,
            file: fd,
          }).then(async () => {
            const per = Math.floor((start * 100) / filesize);
            setProgressLargeLoading(per < 100 ? per : 100);
            counter++;
            const isLastChunk = start + chunkSize > filesize;
            if (isLastChunk) {
              await completeMultipartUpload({
                key,
                uploadId,
                index: start,
                name: String(fileName),
              });
              setKey("");
              setUploadId("");
              getFilesList();
              dispatch(addSuccessMessage(phrases.file_success_uploaded));
              return;
            } else return;
          });
        }
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(ERRORS.failed_upload.alert));
    } finally {
      setKey("");
      setUploadId("");
      setLoadingLarge(false);
      setProgressLargeLoading(0);
    }
  }, [dispatch, key, uploadId, largeFile]);

  useEffect(() => {
    if (key && uploadId && largeFile) uploadWithChunks();
  }, [key, uploadId, largeFile]);

  const handleDeleteFile = useCallback(
    async (key: string) => {
      try {
        setLoading(true);
        const response = await removeFile(
          type === LESSON_TYPE_COMPONENTS.FILE ? LESSON_TYPE_COMPONENTS.OTHER : type,
          key
        );
        if (response.status === 200 || response.status === 201) {
          getFilesList();
          dispatch(addSuccessMessage(phrases.file_success_deleted));
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      } finally {
        setLoading(false);
      }
    },
    [dispatch, type]
  );

  const renderContentDragDrop = () => {
    return (
      <Button className={styles["main-wrapper-left_menu-upload_btn"]}>
        Загрузить {counterLabels[0]}
      </Button>
    );
  };

  const renderFile = useCallback(
    (file) => {
      switch (type) {
        case LESSON_TYPE_COMPONENTS.IMAGE:
          return <FilesPopapImage file={file} />;

        case LESSON_TYPE_COMPONENTS.VIDEO:
          return <FilesPopapVideo file={file} />;

        case LESSON_TYPE_COMPONENTS.AUDIO:
          return <FilesPopapAudio />;

        case LESSON_TYPE_COMPONENTS.FILE:
          return <FilesPopapFile />;
      }
    },
    [type]
  );

  return (
    <Dialog
      visible={isOpen}
      style={{ width: "900px" }}
      modal
      onHide={handleHide}
      header={`Загрузить файл`}
      className={styles["popup"]}
    >
      <div className={styles["main-wrapper"]}>
        <div className={styles["main-wrapper-left_menu"]}>
          <FileUploader
            multiple={true}
            handleChange={type === "video" ? handleLargeFile : handleUploadFile}
            name="file"
            types={fileTypes}
            children={renderContentDragDrop()}
            className={styles["main-wrapper-left_menu-uploader"]}
          />
        </div>
        <div className={styles["main-wrapper-right_content"]}>
          <span className={styles["main-wrapper-right_content-header"]}>Недавние</span>
          <span className={styles["main-wrapper-right_content-sub_header"]}>
            {filesList.length} {formatCounterLabel(filesList.length, counterLabels)}
          </span>
          <div className={styles["main-wrapper-right_content-list"]}>
            {filesList.map((file) => (
              <div
                className={styles["main-wrapper-right_content-list-item"]}
                key={file.key}
                onClick={() => {
                  if (isLessonEdit)
                    dispatch(
                      updateComponent({
                        property: type === "image" ? "imageUrl" : "videoUrl",
                        value: file.url,
                      })
                    );
                  else if (isSingleType) dispatch(changeSelectedUrl({ selectedURL: file.url }));
                  else dispatch(changeComponent({ el: indexLessonBlocks, value: file.url }));
                  handleHide();
                }}
              >
                {renderFile(file)}
                <p>{file.name}</p>
                <span
                  className={styles["main-wrapper-right_content-list-item-remove_btn"]}
                  onClick={(e) => {
                    handleDeleteFile(file.key);
                    e.stopPropagation();
                  }}
                >
                  <i className="pi pi-trash" />
                </span>
              </div>
            ))}
          </div>
          <Loader loading={loading} />
          <ProgressLoader loading={loadingLarge} progress={progressLargeLoading} />
        </div>
      </div>
    </Dialog>
  );
};

export default FilesStoragePopup;
