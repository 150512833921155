import { Editor } from "primereact/editor";
import { Tooltip } from "primereact/tooltip";
import { Dropdown } from "primereact/dropdown";
import { getRoles } from "utils/getRoles";
import { Checkbox } from "primereact/checkbox";
import { classNames } from "primereact/utils";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { EDITORS_COLORS } from "constants/colors";
import { HomeworkSettings } from "typings/lesson";
import { HOMEWORK_LESSON_VISIBLE } from "constants/lesson";
import { FC, memo, useCallback, useState } from "react";

import styles from "./HomeWorkLayout.module.scss";
// import AdditionalTasks from "./AdditionalTasks";
import ConditionDepositMoney from "./ConditionDepositMoney";
import { InputTextarea } from "primereact/inputtextarea";

type HomeworkLayoutProps = {
  settings: HomeworkSettings;
  updateSettings: (name: string, value: any) => void;
};

const HomeworkLayout: FC<HomeworkLayoutProps> = (props) => {
  // const [isAdditionalFields, setIsAdditionalFields] = useState<boolean>(false);

  const isAdmin = getRoles();

  // const renderAdditionalTask = useCallback(() => {
  //   if (
  //     (props.settings.additionalFieldsTask && props.settings.additionalFieldsTask.length > 0) ||
  //     isAdditionalFields
  //   )
  //     return <AdditionalTasks settings={props.settings} updateSettings={props.updateSettings} />;
  //   else return undefined;
  // }, [isAdditionalFields, props.settings]);

  const renderEditor = useCallback(() => {
    return (
      <Editor
        value={props.settings.task}
        onTextChange={(e) => props.updateSettings("task", e.htmlValue)}
        style={{ height: "120px" }}
        modules={{
          toolbar: [
            ["italic", "bold", "underline", "strike"],
            ["blockquote"],
            [
              {
                color: EDITORS_COLORS,
              },
              { background: EDITORS_COLORS },
            ],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            ["link"],
            ["clean"],
          ],
        }}
        showHeader={false}
        readOnly={!isAdmin}
      />
    );
  }, []);

  return (
    <div className={styles["homework-layout"]}>
      <span className={styles["textarea-wrapper"]}>
        <p>Заголовок</p>
        {renderEditor()}
      </span>
      {/* <span
        className={styles["additional-task"]}
        onClick={() => setIsAdditionalFields(!isAdditionalFields)}
      >
        Дополнительные поля к заданию
      </span> */}

      {/* {renderAdditionalTask()} */}

      <span className={styles["textarea-wrapper"]}>
        <p>Описание</p>
        <InputTextarea
          value={props.settings.taskDescription}
          onChange={(e) => {
            props.updateSettings("taskDescription", e.target.value);
          }}
          rows={3}
          cols={30}
          autoResize
          placeholder="Введите описание"
          className={styles["form-item"]}
        />
      </span>

      <div className={styles["money-condition"]}>
        <span className={styles["money-condition-text"]}>Текстовое задание</span>
        <InputSwitch
          checked={props.settings.isTextTask}
          onChange={(e) => {
            props.updateSettings("isTextTask", !props.settings.isTextTask);
          }}
          disabled={!isAdmin}
        />
        <span className={styles["money-condition-text"]}>Задание с видео</span>
      </div>

      <span className={styles["input-wrapper"]}>
        <p>По умолчанию</p>
        <Dropdown
          options={HOMEWORK_LESSON_VISIBLE}
          onChange={(e) =>
            props.updateSettings(
              "isAnswerVisibleEveryone",
              e.value === HOMEWORK_LESSON_VISIBLE[0].value ? true : false
            )
          }
          value={
            props.settings.isAnswerVisibleEveryone
              ? HOMEWORK_LESSON_VISIBLE[0].value
              : HOMEWORK_LESSON_VISIBLE[1].value
          }
          placeholder="По умолчанию"
          className={styles["available-dropdown"]}
          optionLabel="name"
          optionValue="value"
          disabled={!isAdmin}
        />
      </span>

      {props.settings.isAnswerVisibleEveryone ? (
        <div className={styles["chek-wrap"]}>
          <Checkbox
            onChange={() =>
              props.updateSettings("isStudentCanHideAnswer", !props.settings.isStudentCanHideAnswer)
            }
            checked={props.settings.isStudentCanHideAnswer}
            disabled={!isAdmin}
          ></Checkbox>
          <label className="p-checkbox-label">
            Ученик может скрыть свой ответ от других учеников
          </label>
        </div>
      ) : (
        <div className={styles["chek-wrap"]}>
          <Checkbox
            onChange={() =>
              props.updateSettings(
                "isProhibitStudentsFromShowingTheirAnswer",
                !props.settings.isProhibitStudentsFromShowingTheirAnswer
              )
            }
            checked={props.settings.isProhibitStudentsFromShowingTheirAnswer}
            disabled={!isAdmin}
          ></Checkbox>
          <label className="p-checkbox-label">
            Запретить ученикам показывать свой ответ другим ученикам
          </label>
        </div>
      )}
      <div className={styles["chek-wrap"]}>
        <Checkbox
          onChange={() =>
            props.updateSettings("isAcceptAnswerAuto", !props.settings.isAcceptAnswerAuto)
          }
          checked={props.settings.isAcceptAnswerAuto}
          disabled={!isAdmin}
        ></Checkbox>
        <label className="p-checkbox-label">Принимать ответы автоматически</label>
      </div>
      <div className={styles["input-wrapper"]}>
        <p>Начисление Женег</p>
        <i>Базовые баллы</i>
        <div className={styles["money-row"]}>
          <InputNumber
            value={props.settings.settingsAddingPoints?.defaultCountPoints}
            onChange={(e) => {
              props.updateSettings("settingsAddingPoints", {
                ...props.settings.settingsAddingPoints,
                defaultCountPoints: e.value,
              });
            }}
            useGrouping={false}
            min={0}
            maxFractionDigits={0}
            placeholder="Введите количество"
            className={styles["form-item"]}
            disabled={!isAdmin}
          />
          <i
            className={classNames("pi pi-info-circle tooltip tooltip-target", styles["tooltip"])}
          />
          <Tooltip target=".tooltip-target" mouseTrack mouseTrackLeft={10}>
            Количество Женег, которое будет начислено, если пользователь сдаст домашнее задание
            после наступления всех описанных ниже сроков
          </Tooltip>
        </div>
        <i>Условия</i>
        <div className={styles["money-condition"]}>
          <span className={styles["money-condition-text"]}>В течение * часов</span>
          <InputSwitch
            checked={props.settings.settingsAddingPoints?.conditionByDate}
            onChange={(e) =>
              props.updateSettings("settingsAddingPoints", {
                ...props.settings.settingsAddingPoints,
                conditionByDate: e.value,
              })
            }
            disabled={
              !isAdmin || props.settings.settingsAddingPoints?.conditionAddingPoints?.length > 0
            }
          />
          <span className={styles["money-condition-text"]}>До наступления даты</span>
        </div>
        <ConditionDepositMoney
          conditionList={props.settings.settingsAddingPoints?.conditionAddingPoints}
          updateSettings={props.updateSettings}
          dateValue={props.settings.settingsAddingPoints?.conditionByDate}
          settings={props.settings}
        />
      </div>
    </div>
  );
};

export default memo(HomeworkLayout);
