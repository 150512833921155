import { Button } from "primereact/button";
import { phrases } from "constants/phrases";
import { getRoles } from "utils/getRoles";
import { TCourseInfo } from "typings/courses";
import { useAppDispatch } from "utils/hooks";
import { TShortListTarifs } from "typings/tarifs";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { FC, useCallback, useEffect, useState } from "react";
import { createNewTarif, getAllTarifsList, removeTarif } from "api/tarifs";

import styles from "./Tarifs.module.scss";
import CreateTarifPopup from "popups/create-tarif";
import RemoveTarifPopup from "popups/remove-tarif";
import CourseTarifsTable from "components/comon/course-tarifs-table/CourseTarifsTable";

type CourseTarifsTabProps = {
  courseInfo: TCourseInfo | null;
  redirectOnEditTarifPage: (tarifId: number) => void;
};

const CourseTarifsTab: FC<CourseTarifsTabProps> = (props) => {
  const [tarifsList, setTarifsList] = useState<TShortListTarifs[]>([]);
  const [selectedTarif, setSelectedTarif] = useState<TShortListTarifs | null>(null);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const [isOpenAddTarifPopup, setIsOpenAddTarifPopup] = useState<boolean>(false);
  const [isOpenDeleteTarifPopup, setIsOpenDeleteTarifPopup] = useState<boolean>(false);

  const isAdmin = getRoles();

  const dispatch = useAppDispatch();

  const handleSelectTarif = (tarif: any) => setSelectedTarif(tarif);

  const handleOpenAddTarifPopup = () => setIsOpenAddTarifPopup(true);
  const handleCloseAddTarifPopup = () => setIsOpenAddTarifPopup(false);

  const handleOpenDeleteTarifPopup = () => setIsOpenDeleteTarifPopup(true);
  const handleCloseDeleteTarifPopup = () => setIsOpenDeleteTarifPopup(false);

  const getTarifsList = useCallback(async () => {
    setIsLoadingTable(true);
    try {
      if (props.courseInfo) {
        const response = await getAllTarifsList(props.courseInfo.id);
        if (response.status === 200 || response.status === 201) {
          setTarifsList(response.data);
          setIsLoadingTable(false);
        } else throw new Error();
      } else return;
    } catch (err: any) {
      dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
    }
  }, [dispatch, props.courseInfo]);

  useEffect(() => {
    getTarifsList();
  }, [getTarifsList]);

  const createTarif = useCallback(
    async (name: string) => {
      setIsLoadingTable(true);
      try {
        if (props.courseInfo) {
          const response = await createNewTarif({ courseId: props.courseInfo?.id, name });
          if (response.status === 200 || response.status === 201) {
            dispatch(addSuccessMessage(phrases.tarif_success_added));
            getTarifsList();
          } else throw new Error();
        } else return;
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrong));
      } finally {
        handleCloseAddTarifPopup();
        setIsLoadingTable(false);
      }
    },
    [dispatch, props.courseInfo]
  );

  const deleteTarif = useCallback(async () => {
    setIsLoadingTable(true);
    try {
      if (selectedTarif) {
        const response = await removeTarif(selectedTarif.id);
        if (response.status === 200 || response.status === 201) {
          dispatch(addSuccessMessage(phrases.tarif_success_deleted));
          getTarifsList();
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {
      dispatch(addErrorMessage(err.response?.data.message || phrases.smthWentWrongText));
    } finally {
      handleCloseDeleteTarifPopup();
      setIsLoadingTable(false);
    }
  }, [dispatch, selectedTarif]);

  return (
    <div className={styles["main-wrapper"]}>
      {isAdmin && (
        <span className={styles["main-wrapper-header"]}>
          <Button onClick={handleOpenAddTarifPopup}>
            <i className="pi pi-plus" />
            Добавить тариф
          </Button>
        </span>
      )}
      <div className={styles["main-wrapper-table"]}>
        <CourseTarifsTable
          tarifsList={tarifsList}
          handleSelectTarif={handleSelectTarif}
          handleOpenDeleteTarifPopup={handleOpenDeleteTarifPopup}
          isLoadingTable={isLoadingTable}
          isAdmin={isAdmin}
          redirectOnEditTarifPage={props.redirectOnEditTarifPage}
        />
      </div>

      <CreateTarifPopup
        isOpen={isOpenAddTarifPopup}
        handleHide={handleCloseAddTarifPopup}
        createTarif={createTarif}
      />
      <RemoveTarifPopup
        isOpen={isOpenDeleteTarifPopup}
        handleHide={handleCloseDeleteTarifPopup}
        selectedTarif={selectedTarif}
        deleteTarif={deleteTarif}
      />
    </div>
  );
};

export default CourseTarifsTab;
