import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { TLesson } from "typings/lesson";
import { getRoles } from "utils/getRoles";
import { classNames } from "primereact/utils";
import { useNavigate } from "react-router";
import { TabPanel, TabView } from "primereact/tabview";
import { UPDATE_LESSON_PAGE_MENU } from "constants/lesson";
import { FC, memo, useCallback, useMemo, useRef, useState } from "react";

import styles from "./UpdateLesson.module.scss";
import HomeworkAnswers from "./tabs/homework-answers/HomeworkAnswers";
import LessonContentTab from "./tabs/content/Content";
import LessonSettingsTab from "./tabs/settings/Settings";
import HomeworkAndComments from "./tabs/homework-and-comments/HomeworkAndComments";

type UpdateLessonLayoutProps = {
  lessonInfo: TLesson;
  courseId: string | null | undefined;
  moduleId: string | null | undefined;
  headCourseId: string | null | undefined;
  updateLesson: (lessonData: TLesson) => void;
  handleOpenRemoveLessonPopup: () => void;
  handleOpenCopyLessonPopup: () => void;
};

const UpdateLessonLayout: FC<UpdateLessonLayoutProps> = (props) => {
  const [openMenuTab, setOpenMenuTab] = useState<string>(UPDATE_LESSON_PAGE_MENU[0].name);

  const isAdmin = getRoles();

  const menuLeft = useRef(null);

  const navigate = useNavigate();

  const activeMenuIndex = useMemo(
    () => UPDATE_LESSON_PAGE_MENU.findIndex((el) => el.name === openMenuTab),
    [openMenuTab]
  );

  const ACTION_PAGE_BTN = [
    {
      label: "Редактировать урок",
      icon: "pi pi-pencil",
      command: () => setOpenMenuTab(UPDATE_LESSON_PAGE_MENU[1].name),
    },
    {
      label: "Копировать урок",
      icon: "pi pi-copy",
      command: props.handleOpenCopyLessonPopup,
    },
    {
      label: "Удалить урок",
      icon: "pi pi-trash",
      command: props.handleOpenRemoveLessonPopup,
    },
  ];

  const renderActiveTab = useCallback(() => {
    switch (openMenuTab) {
      case UPDATE_LESSON_PAGE_MENU[0].name:
        return <LessonContentTab lessonInfo={props.lessonInfo} updateLesson={props.updateLesson} />;
      // case UPDATE_LESSON_PAGE_MENU[1].name:
      //   return (
      //     <LessonSettingsTab lessonInfo={props.lessonInfo} updateLesson={props.updateLesson} />
      //   );
      case UPDATE_LESSON_PAGE_MENU[1].name:
        return <HomeworkAndComments updateLesson={props.updateLesson} />;
      case UPDATE_LESSON_PAGE_MENU[2].name:
        return <HomeworkAnswers lessonInfo={props.lessonInfo} />;
      default:
        return <LessonContentTab lessonInfo={props.lessonInfo} updateLesson={props.updateLesson} />;
    }
  }, [openMenuTab, props.lessonInfo]);

  return (
    <div className={styles["main-wrapper"]}>
      <div className={styles["main-wrapper-header"]}>
        <span
          className={styles["back-content"]}
          onClick={() => {
            props.moduleId
              ? navigate(
                  `/courses/update_module?module_id=${props.moduleId}&step_id=${props.courseId}&course_id=${props.headCourseId}`
                )
              : navigate(
                  `/courses/update_step?step_id=${props.courseId}&course_id=${props.headCourseId}`
                );
          }}
        >
          <i className="pi pi-arrow-left" />
          <p>{props.moduleId ? "Вернуться к модулю" : "Вернуться к степу"}</p>
        </span>
        {isAdmin && (
          <div className={styles["main-wrapper-header-actions"]}>
            <Menu model={ACTION_PAGE_BTN} popup ref={menuLeft} id="popup_menu_left" />
            <Button
              label="Действия"
              icon="pi pi-bolt"
              //@ts-ignore
              onClick={(event) => menuLeft.current.toggle(event)}
              aria-controls="popup_menu_left"
              aria-haspopup
              className={classNames(
                styles["main-wrapper-header-actions-button"],
                styles["main-wrapper-header-actions-action-button"],
                styles["green-btn"]
              )}
            />
          </div>
        )}
      </div>

      <div className={styles["main-wrapper-menu"]}>
        <TabView
          activeIndex={activeMenuIndex}
          onTabChange={(e) => setOpenMenuTab(UPDATE_LESSON_PAGE_MENU[e.index].name)}
        >
          {UPDATE_LESSON_PAGE_MENU.map((el) => (
            <TabPanel
              key={el.name}
              headerTemplate={(options) => (
                <span
                  className={classNames(
                    options.className,
                    styles["main-wrapper-menu-item"],
                    openMenuTab === el.name && styles["main-wrapper-menu-active-item"]
                  )}
                  onClick={options.onClick}
                >
                  <p>{el.label}</p>
                </span>
              )}
            />
          ))}
        </TabView>
      </div>

      <div className={styles["main-wrapper-content"]}>{renderActiveTab()}</div>
    </div>
  );
};

export default memo(UpdateLessonLayout);
