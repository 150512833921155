import { TNewAdmin } from "typings/admins";
import http from "./https";

type getAllAdminsParamsType = {
  page?: number;
  limit?: number;
  search?: string;
};

export const getAllAdmins = (params: getAllAdminsParamsType) =>
  http.get(
    `/api/admins`,
    {},
    {
      params,
    }
  );

export const createNewAdmin = (adminData: TNewAdmin) => http.post(`/api/admins`, adminData);

export const removeAdmin = (adminId: number) => http.remove(`/api/admins?id=${adminId}`, {});

export const updateAdminAsync = (adminId: number, adminData: TNewAdmin) =>
  http.patch(`/api/admins/${adminId}`, adminData);

export const getCuratorsWithoutAccess = (courseId: number) =>
  http.get(`/api/admins/list-teachers/${courseId}?isCurator=true`);
