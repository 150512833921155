import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { FC, useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { TNewLesson } from "typings/lesson";
import styles from "../components/Admins/Admins.module.scss";

type CreateLessonPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  createLesson: (lessonData: TNewLesson) => void;
};

const CreateLessonPopup: FC<CreateLessonPopupProps> = (props) => {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const clearStates = () => {
    setName("");
    setDescription("");
  };

  return (
    <Dialog
      visible={props.isOpen}
      style={{ width: "500px" }}
      modal
      onHide={() => {
        props.handleHide();
        clearStates();
      }}
      header={`Добавление нового урока`}
    >
      <div className={styles["create-account-popup-wrapper"]}>
        <span className={styles["create-account-input-wrapper"]}>
          <p>Название</p>
          <InputText
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Введите название урока"
          />
        </span>
        <span className={styles["create-account-input-wrapper"]}>
          <p>Описание</p>
          <InputTextarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={5}
            cols={30}
            autoResize
            placeholder="Введите описание урока"
          />
        </span>
        <div className={styles["create-account-btn-wrap"]}>
          <button
            className={styles["cancel-btn"]}
            onClick={() => {
              props.handleHide();
              clearStates();
            }}
          >
            Отмена
          </button>
          <button
            className={styles["create-acc-btn"]}
            onClick={() => {
              props.createLesson({ name, description });
              clearStates();
            }}
            disabled={!name}
          >
            Добавить
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default CreateLessonPopup;
