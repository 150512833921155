import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  type: "",
  indexLessonBlocks: 0,
  fileTypes: [],
  counterLabels: [],
  isSingleType: false,
  isLessonEdit: false,
  selectedUrl: "",
  changedField: "",
};

const fileStoragePopupSlice = createSlice({
  name: "fileStorage",
  initialState,
  reducers: {
    openFileStoragePopup(state, action) {
      state.isOpen = true;
      state.indexLessonBlocks = action.payload.indexLessonBlocks;
      state.type = action.payload.type;
      state.fileTypes = action.payload.fileTypes;
      state.counterLabels = action.payload.counterLabels;
      state.isSingleType = action.payload.isSingleType;
      state.isLessonEdit = action.payload.isLessonEdit;
      state.changedField = action.payload.changedField;
    },
    closeFileStoragePopup(state) {
      state.isOpen = false;
    },
    changeSelectedUrl(state, action) {
      state.selectedUrl = action.payload.selectedURL;
    },
  },
});

export const { openFileStoragePopup, closeFileStoragePopup, changeSelectedUrl } =
  fileStoragePopupSlice.actions;

export default fileStoragePopupSlice;
