import { FC } from "react";
import { InputText } from "primereact/inputtext";

import styles from "./UpdateTarif.module.scss";

type TarifCompositionProps = {
  isAdmin: boolean;
  composition: string[] | undefined;
  changeComposition: (newComposition: string[]) => void;
};

const TarifComposition: FC<TarifCompositionProps> = (props) => {
  const changeCompos = (value: string, changeIndex: number) => {
    const newCompos =
      props.composition &&
      props.composition.map((el, index) => {
        if (changeIndex === index) return value;
        else return el;
      });
    props.changeComposition(newCompos || []);
  };

  const deleteCompos = (changeIndex: number) => {
    const newCompos =
      props.composition && props.composition.filter((_, index) => index !== changeIndex);
    props.changeComposition(newCompos || []);
  };

  const addCompos = () => {
    if (props.composition) {
      const newCompos = [...props.composition, ""];
      props.changeComposition(newCompos || []);
    } else return;
  };

  return (
    <div className={styles["tarif-compos"]}>
      {props.composition &&
        props.composition.map((el, index) => (
          <div className={styles["tarif-compos-row"]} key={index}>
            <InputText
              id="name"
              name="name"
              placeholder="Название тарифа"
              value={el}
              onChange={(e) => {
                changeCompos(e.target.value, index);
              }}
              disabled={!props.isAdmin}
            />
            <button onClick={() => deleteCompos(index)}>
              <i className="pi pi-trash" />
            </button>
          </div>
        ))}
      <button className={styles["tarif-compos-add-btn"]} onClick={addCompos}>
        Добавить
      </button>
    </div>
  );
};

export default TarifComposition;
