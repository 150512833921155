import { FC, useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ADD_BALANCE } from "constants/balance";
import { TNewStudent, TStudent } from "typings/students";

import styles from "../components/Students/Students.module.scss";

type EditStudentPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  selectedStudent: TStudent;
  changeSelectedStudent: (name: string, value: string | boolean | number | null) => void;
  updateStudent: (studentData: TNewStudent) => void;
};

const EditStudentPopup: FC<EditStudentPopupProps> = ({
  isOpen,
  handleHide,
  selectedStudent,
  changeSelectedStudent,
  updateStudent,
}) => {
  const [stateBalanceAction, setStateBalanceAction] = useState<boolean>(true);
  const [diffBalance, setDiffBalance] = useState<number>(0);

  useEffect(() => {
    if (selectedStudent) setDiffBalance(selectedStudent.balance);
  }, [selectedStudent]);

  const updateStudentLocalFunc = () =>
    updateStudent({
      firstName: selectedStudent.firstName,
      lastName: selectedStudent.lastName,
      points:
        selectedStudent.balance > diffBalance
          ? -(selectedStudent.balance - diffBalance)
          : diffBalance - selectedStudent.balance,
    });

  return (
    <Dialog
      visible={isOpen}
      style={{ width: "500px" }}
      modal
      onHide={handleHide}
      header={`Редактирование студента`}
    >
      <div className={styles["create-account-popup-wrapper"]}>
        <span className={styles["create-account-input-wrapper"]}>
          <p>Имя</p>
          <InputText
            name="firstName"
            value={selectedStudent?.firstName}
            onChange={(e) => changeSelectedStudent(e.target.name, e.target.value)}
            maxLength={50}
            placeholder="Введите имя"
          />
        </span>
        <span className={styles["create-account-input-wrapper"]}>
          <p>Фамилия</p>
          <InputText
            name="lastName"
            value={selectedStudent?.lastName}
            onChange={(e) => changeSelectedStudent(e.target.name, e.target.value)}
            maxLength={50}
            placeholder="Введите фамилию"
          />
        </span>

        <div className={styles["create-account-input-wrapper"]}>
          <p>Баланс</p>
          <div className={styles["balance-display"]}>
            <span className={styles["balance-display-text"]}> {diffBalance || 0}</span>
            <Dropdown
              options={[
                {
                  name: "Пополнить",
                  value: true,
                },
                {
                  name: "Списать",
                  value: false,
                },
              ]}
              onChange={(e) => setStateBalanceAction(e.value)}
              value={stateBalanceAction}
              placeholder="По умолчанию"
              className={styles["available-dropdown"]}
              optionLabel="name"
              optionValue="value"
            />
          </div>
          <div className={styles["balance-btn-wrapper"]}>
            {ADD_BALANCE.map((button) => (
              <button
                className={styles["balance-btn"]}
                key={button}
                onClick={() =>
                  setDiffBalance(
                    stateBalanceAction
                      ? diffBalance + button
                      : !stateBalanceAction && diffBalance - button >= 0
                      ? diffBalance - button
                      : 0
                  )
                }
              >
                {button}
              </button>
            ))}
          </div>
        </div>

        <div className={styles["create-account-btn-wrap"]}>
          <button className={styles["cancel-btn"]} onClick={handleHide}>
            Отмена
          </button>
          <button
            className={styles["create-acc-btn"]}
            onClick={updateStudentLocalFunc}
            disabled={!selectedStudent.firstName}
          >
            Сохранить изменения
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default EditStudentPopup;
