import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { classNames } from "primereact/utils";
import AppTopbar from "./AppTopbar";
import { Tooltip } from "primereact/tooltip";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { menu } from "constants/menu";

const AppWrapper = ({ children }: any) => {
  let navigate = useNavigate();

  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [sidebarStatic, setSidebarStatic] = useState<boolean>(true);
  const [menuActive, setMenuActive] = useState<boolean>(false);
  const [menuMode, setMenuMode] = useState<string>("sidebar");
  const [configActive, setConfigActive] = useState(false);
  const [inputStyle, setInputStyle] = useState<string | null>(null);
  const [ripple, setRipple] = useState<boolean | null>(null);
  const [rightPanelActive, setRightPanelActive] = useState(false);
  const [topbarScheme, setTopbarScheme] = useState<string | null>(null);
  const [menuScheme, setMenuScheme] = useState<string | null>(null);
  const [searchActive, setSearchActive] = useState<boolean>(false);
  const [topbarUserMenuActive, setTopbarUserMenuActive] = useState<boolean>(false);
  const [resetActiveIndex, setResetActiveIndex] = useState<boolean | null>(null);

  const copyTooltipRef = useRef(null);

  let menuClick;
  let rightPanelClick;
  let configClick;
  let searchClick;
  let topbarUserMenuClick;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  useEffect(() => {
    setMenuScheme("light");
    setTopbarScheme("light");
    setInputStyle("filled");
    setMenuMode("sidebar");
    setRipple(false);
  }, []);

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current && //@ts-ignore
      copyTooltipRef.current.updateTargetEvents();
  }, [navigate]);

  useEffect(() => {
    if (staticMenuMobileActive) {
      blockBodyScroll();
    } else {
      unblockBodyScroll();
    }
  }, [staticMenuMobileActive]);

  useEffect(() => {
    setResetActiveIndex(true);
    setMenuActive(false);
  }, [menuMode]);

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setResetActiveIndex(true);
      hideOverlayMenu();
    }
    if (!event.item.items && (isSlim() || isHorizontal())) {
      setMenuActive(false);
    }
  };

  const onMenuClick = (event) => {
    if (menuActive && event.target.className === "layout-menu-container") {
      setResetActiveIndex(true);
      setMenuActive(false);
    }
    menuClick = true;
  };

  const blockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.add("blocked-scroll");
    } else {
      document.body.className += " blocked-scroll";
    }
  };

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove("blocked-scroll");
    } else {
      document.body.className = document.body.className.replace(
        new RegExp("(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)", "gi"),
        " "
      );
    }
  };

  const onMenuButtonClick = (event) => {
    menuClick = true;
    setTopbarUserMenuActive(false);
    setRightPanelActive(false);

    if (isMobile()) {
      setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive);
      if (staticMenuMobileActive) {
        blockBodyScroll();
      } else {
        unblockBodyScroll();
      }
    }
    event.preventDefault();
  };

  const isMobile = () => {
    return window.innerWidth <= 991;
  };

  const isHorizontal = () => {
    return menuMode === "horizontal";
  };

  const isSlim = () => {
    return menuMode === "slim";
  };

  const hideOverlayMenu = () => {
    setStaticMenuMobileActive(false);
    unblockBodyScroll();
  };

  const onRightPanelButtonClick = () => {
    setRightPanelActive((prevState) => !prevState);
    rightPanelClick = true;
  };

  const onTopbarSearchToggle = () => {
    setSearchActive((prevState) => !prevState);
    searchClick = true;
  };

  const onTopbarSearchClick = () => {
    searchClick = true;
  };

  const onTopbarUserMenuClick = () => {
    setTopbarUserMenuActive((prevState) => !prevState);
    topbarUserMenuClick = true;
  };

  const onDocumentClick = () => {
    if (!searchClick && searchActive) {
      setSearchActive(false);
      searchClick = false;
    }

    if (!topbarUserMenuClick && topbarUserMenuActive) {
      setTopbarUserMenuActive(false);
      topbarUserMenuClick = false;
    }

    if (!rightPanelClick && rightPanelActive) {
      setRightPanelActive(false);
    }

    if (!configClick && configActive) {
      setConfigActive(false);
    }

    if (!menuClick) {
      if (isSlim() || isHorizontal()) {
        setResetActiveIndex(true);
        setMenuActive(false);
      }

      if (staticMenuMobileActive) {
        hideOverlayMenu();
      }

      unblockBodyScroll();
    }

    searchClick = false;
    topbarUserMenuClick = false;
    rightPanelClick = false;
    configClick = false;
    menuClick = false;
  };

  const onToggleMenu = (event) => {
    menuClick = true;
    setSidebarStatic((prevState) => !prevState);

    event.preventDefault();
  };

  const onRootMenuItemClick = () => {
    setMenuActive((prevMenuActive) => !prevMenuActive);
  };

  const layoutClassName = classNames(
    "layout-wrapper",
    {
      "layout-sidebar": menuMode === "sidebar",
      "layout-static": menuMode === "sidebar" && sidebarStatic,
      "layout-horizontal": menuMode === "horizontal",
      "layout-rightpanel-active": rightPanelActive,
      "layout-slim": menuMode === "slim",
      "layout-mobile-active": staticMenuMobileActive,
      "p-input-filled": inputStyle === "filled",
      "p-ripple-disabled": !ripple,
    },
    "layout-menu-" + menuScheme + " layout-topbar-" + topbarScheme
  );

  return (
    <div className={layoutClassName} onClick={onDocumentClick}>
      <Tooltip
        ref={copyTooltipRef}
        target=".block-action-copy"
        position="bottom"
        content="Copied to clipboard"
        event="focus"
      />

      <AppTopbar
        topbarScheme={topbarScheme}
        onRightPanelButtonClick={onRightPanelButtonClick}
        searchActive={searchActive}
        onTopbarSearchToggle={onTopbarSearchToggle}
        onTopbarSearchClick={onTopbarSearchClick}
        topbarUserMenuActive={topbarUserMenuActive}
        onTopbarUserMenuClick={onTopbarUserMenuClick}
        menu={menu()}
        menuActive={menuActive}
        onRootMenuItemClick={onRootMenuItemClick}
        mobileMenuActive={staticMenuMobileActive}
        onMenuItemClick={onMenuItemClick}
        menuMode={menuMode}
        sidebarStatic={sidebarStatic}
        onToggleMenu={onToggleMenu}
        onMenuButtonClick={onMenuButtonClick}
        resetActiveIndex={resetActiveIndex}
        onMenuClick={onMenuClick}
      />

      <div className="layout-main">
        <div className="top-bar"></div>
        <div className="layout-content">{children}</div>
      </div>

      <div className="layout-mask modal-in"></div>
    </div>
  );
};

export default AppWrapper;
