import { DataTable } from "primereact/datatable";
import { FC, memo } from "react";
import { Column } from "primereact/column";
import { TShedule } from "typings/courses";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { LESSON_AVAILABLE_OPTIONS } from "constants/lesson";

import styles from "./Shedule.module.scss";

type SheduleProps = {
  shedule: TShedule[];
  changeSheduleDate: (lessonId: number, newDate: string) => void;
  changeSheduleVisible: (lessonId: number, visibility: string) => void;
  isAdmin: boolean;
};

const Shedule: FC<SheduleProps> = (props) => {
  const renderLesson = (rowData: TShedule) => {
    return (
      <div className="col-wrapper" onClick={() => {}}>
        <p className="table-text">{rowData.index + 1 + ". " + rowData.name}</p>
      </div>
    );
  };

  const renderVisibility = (rowData: TShedule) => {
    return (
      <div className="col-wrapper" onClick={() => {}}>
        <Dropdown
          options={LESSON_AVAILABLE_OPTIONS}
          onChange={(e) => props.changeSheduleVisible(rowData.id, e.value)}
          value={rowData.visibility}
          placeholder="Задать доступность урока"
          className={styles["available-dropdown"]}
          disabled={!props.isAdmin}
        />
      </div>
    );
  };

  const renderCalendar = (rowData: TShedule) => {
    return (
      <div className="col-wrapper">
        {rowData.visibility !== "available" ? (
          <Calendar
            value={rowData.startAt ? new Date(rowData.startAt) : undefined}
            onChange={(e) =>
              props.changeSheduleDate(
                rowData.id,
                e.value ? new Date(String(e.value)).toISOString() : ""
              )
            }
            showTime
            hourFormat="24"
            className={styles["calendar"]}
            dateFormat="dd/mm/yy"
            readOnlyInput
            minDate={new Date()}
          />
        ) : (
          <span className={styles["available-data-text"]}>Доступен</span>
        )}
      </div>
    );
  };

  return (
    <DataTable
      value={props.shedule}
      className={styles["shedule-table"]}
      responsiveLayout="scroll"
      showGridlines
    >
      <Column field="index" header="№ Урока" style={{ width: "30%" }} body={renderLesson}></Column>
      <Column
        field="visibility"
        header="Доступность и видимость урока до даты начала"
        style={{ width: "30%" }}
        body={renderVisibility}
      ></Column>
      <Column
        field="date"
        header="Дата начала"
        style={{ width: "40%" }}
        body={renderCalendar}
      ></Column>
    </DataTable>
  );
};

export default memo(Shedule);
