// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomeworkAndComments_main-wrapper__HknuY {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.HomeworkAndComments_main-wrapper__HknuY .HomeworkAndComments_chek-wrap__kKyCX {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0px;
}
.HomeworkAndComments_main-wrapper__HknuY .HomeworkAndComments_chek-wrap__kKyCX label {
  margin-left: 12px;
  font-size: 14px;
  font-weight: 600;
}
.HomeworkAndComments_main-wrapper-content__p4ItX {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.HomeworkAndComments_save-button__-wsBc {
  border-radius: 6px;
  padding: 12px;
  width: 100%;
  max-width: 300px;
  cursor: pointer;
  color: #fff;
  display: none;
  align-items: center;
  justify-content: center;
  transition: all 0.1s;
  animation: HomeworkAndComments_shows__wrtgb 1s;
  margin-top: 20px;
  background-color: rgb(2, 173, 2);
  border: 1px solid rgb(2, 173, 2);
}
.HomeworkAndComments_save-button__-wsBc:hover {
  background-color: rgb(4, 112, 4) !important;
  border: 1px solid rgb(4, 112, 4) !important;
}

.HomeworkAndComments_save-button-visible__xVi79 {
  display: flex;
}

@keyframes HomeworkAndComments_shows__wrtgb {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Lesson/LessonUpdate/tabs/homework-and-comments/HomeworkAndComments.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;AACF;AACE;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,gBAAA;AACJ;AACI;EACE,iBAAA;EACA,eAAA;EACA,gBAAA;AACN;AAGE;EACE,WAAA;EACA,aAAA;EACA,sBAAA;AADJ;;AAKA;EACE,kBAAA;EACA,aAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,oBAAA;EACA,8CAAA;EACA,gBAAA;EAEA,gCAAA;EACA,gCAAA;AAHF;AAKE;EACE,2CAAA;EACA,2CAAA;AAHJ;;AAMA;EACE,aAAA;AAHF;;AAMA;EACE;IACE,UAAA;EAHF;EAMA;IACE,UAAA;EAJF;AACF","sourcesContent":[".main-wrapper {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n\n  .chek-wrap {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    margin: 10px 0px;\n\n    label {\n      margin-left: 12px;\n      font-size: 14px;\n      font-weight: 600;\n    }\n  }\n\n  &-content {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n  }\n}\n\n.save-button {\n  border-radius: 6px;\n  padding: 12px;\n  width: 100%;\n  max-width: 300px;\n  cursor: pointer;\n  color: #fff;\n  display: none;\n  align-items: center;\n  justify-content: center;\n  transition: all 0.1s;\n  animation: shows 1s;\n  margin-top: 20px;\n\n  background-color: rgb(2, 173, 2);\n  border: 1px solid rgb(2, 173, 2);\n\n  &:hover {\n    background-color: rgb(4, 112, 4) !important;\n    border: 1px solid rgb(4, 112, 4) !important;\n  }\n}\n.save-button-visible {\n  display: flex;\n}\n\n@keyframes shows {\n  0% {\n    opacity: 0;\n  }\n\n  100% {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-wrapper": `HomeworkAndComments_main-wrapper__HknuY`,
	"chek-wrap": `HomeworkAndComments_chek-wrap__kKyCX`,
	"main-wrapper-content": `HomeworkAndComments_main-wrapper-content__p4ItX`,
	"save-button": `HomeworkAndComments_save-button__-wsBc`,
	"shows": `HomeworkAndComments_shows__wrtgb`,
	"save-button-visible": `HomeworkAndComments_save-button-visible__xVi79`
};
export default ___CSS_LOADER_EXPORT___;
