import { TShedule } from "typings/courses";
import http from "./https";
import { TUpdateModule } from "typings/module";

export const getModuleById = (moduleId: string) => http.get(`/api/courses/${moduleId}`);

export const updateModuleAsync = (moduleId: number, moduleData: TUpdateModule) =>
  http.patch(`/api/courses/${moduleId}`, moduleData);

export const removeModule = (moduleId: number) => http.remove(`/api/courses/${moduleId}`, {});

export const getModuleSheduleAsync = (moduleId: number) =>
  http.get(`/api/courses/${moduleId}/lessons-schedule`);

export const updateModuleShedule = (moduleId: number, newModuleShedule: TShedule[]) =>
  http.patch(`/api/courses/${moduleId}/lessons-schedule`, { schedule: newModuleShedule });

export const copyModuleAsync = (moduleId: string, data: { name: string; courseId: number }) =>
  http.post(`api/courses/${moduleId}/copy`, data);
