import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { getRoles } from "utils/getRoles";
import { classNames } from "primereact/utils";
import { useNavigate } from "react-router";
import { CustomBreadCrumb } from "components/BreadCrumb/BreadCrumb";
import { TabPanel, TabView } from "primereact/tabview";
import { UPDATE_COURSE_PAGE_MENU } from "constants/courses";
import { TCourseInfo, TUpdateCourse } from "typings/courses";
import { FC, memo, useCallback, useMemo, useRef, useState } from "react";

import CourseStudentsTab from "./tabs/students/Students";
import CourseContentTab from "./tabs/content/Content";
import CourseSettingsTab from "./tabs/settings/Settings";
import CourseSheduleTab from "./tabs/shedule/Shedule";
import CourseTarifsTab from "./tabs/tarifs/Tarifs";

import styles from "./UpdateCourse.module.scss";

type UpdateCourseLayoutProps = {
  courseInfo: TCourseInfo | null;
  headCourseId: string | null | undefined;
  handleOpenCreateModulePopup: () => void;
  handleOpenCreateLessonPopup: () => void;
  updateCourse: (courseData: TUpdateCourse) => void;
  handleOpenRemoveCoursePopup: () => void;
  handleOpenChangePlacesPopup: (type: string) => void;
  handleOpenCopyCoursePopup: () => void;
};

const UpdateCourseLayout: FC<UpdateCourseLayoutProps> = (props) => {
  const [openMenuTab, setOpenMenuTab] = useState<any>(UPDATE_COURSE_PAGE_MENU[0].name);

  const addCourseMenuRef = useRef(null);
  const actionPageMenuRef = useRef(null);

  const isAdmin = getRoles();

  const activeMenuIndex = useMemo(
    () => UPDATE_COURSE_PAGE_MENU.findIndex((el) => el.name === openMenuTab),
    [openMenuTab]
  );

  const navigate = useNavigate();

  const redirectOnEditTarifPage = (tarifId: number) =>
    navigate(
      `/courses/update_step/update-tarif?step_id=${props.courseInfo?.id}&course_id=${props.headCourseId}&tarif_id=${tarifId}`
    );

  const ADD_COURSES_BTN = [
    {
      label: "Модуль",
      icon: "pi pi-folder-open",
      command: props.handleOpenCreateModulePopup,
    },
    {
      label: "Урок",
      icon: "pi pi-book",
      command: props.handleOpenCreateLessonPopup,
    },
  ];

  const ACTION_PAGE_BTN = [
    {
      label: "Редактировать степ",
      icon: "pi pi-pencil",
      command: () => {
        setOpenMenuTab(UPDATE_COURSE_PAGE_MENU[1].name);
      },
    },
    {
      label: "Настроить расписание",
      icon: "pi pi-calendar",
      command: () => {
        setOpenMenuTab(UPDATE_COURSE_PAGE_MENU[2].name);
      },
    },
    {
      label: "Копировать степ",
      icon: "pi pi-copy",
      command: props.handleOpenCopyCoursePopup,
    },
    {
      label: "Изменить порядок уроков",
      icon: "pi pi-sort-alt",
      command: () => {
        setOpenMenuTab(UPDATE_COURSE_PAGE_MENU[0].name);
        props.handleOpenChangePlacesPopup("lessons");
      },
    },
    {
      label: "Изменить порядок модулей",
      icon: "pi pi-sort-alt",
      command: () => {
        setOpenMenuTab(UPDATE_COURSE_PAGE_MENU[0].name);
        props.handleOpenChangePlacesPopup("modules");
      },
    },
    {
      label: "Удалить степ",
      icon: "pi pi-trash",
      command: props.handleOpenRemoveCoursePopup,
    },
  ];

  const renderActiveTab = useCallback(() => {
    switch (openMenuTab) {
      case UPDATE_COURSE_PAGE_MENU[0].name:
        return props.courseInfo &&
          props.courseInfo?.lessons.length < 1 &&
          props.courseInfo?.submodules.length < 1 ? (
          <div className={styles["empty-content"]}>
            <i className="pi pi-info-circle" />
            <p>Степ пуст. Вы еще не добавили ни одного урока!</p>
          </div>
        ) : (
          <CourseContentTab courseInfo={props.courseInfo} headCourseId={props.headCourseId} />
        );
      case UPDATE_COURSE_PAGE_MENU[1].name:
        return (
          <CourseSettingsTab courseInfo={props.courseInfo} updateCourse={props.updateCourse} />
        );
      case UPDATE_COURSE_PAGE_MENU[2].name:
        return <CourseSheduleTab courseInfo={props.courseInfo} />;
      case UPDATE_COURSE_PAGE_MENU[3].name:
        return <CourseStudentsTab courseInfo={props.courseInfo} />;
      case UPDATE_COURSE_PAGE_MENU[4].name:
        return (
          <CourseTarifsTab
            courseInfo={props.courseInfo}
            redirectOnEditTarifPage={redirectOnEditTarifPage}
          />
        );
      default:
        return <CourseContentTab courseInfo={props.courseInfo} headCourseId={props.headCourseId} />;
    }
  }, [openMenuTab, props.courseInfo]);

  return (
    <div className={styles["main-wrapper"]}>
      <div className={styles["main-wrapper-header"]}>
        <div className={styles["main-wrapper-header-title"]}>
          <span>{props.courseInfo?.name}</span>
          <span className={styles["main-wrapper-breadcrumb-wrapper"]}>
            <CustomBreadCrumb
              home={{ label: "Все курсы", url: "/courses", icon: "pi pi-home" }}
              items={[
                {
                  label: props.courseInfo?.headCourseName || "Родительский курс",
                  url: `/courses/update_course?course_id=${props.headCourseId}`,
                },
                {
                  label: props.courseInfo?.name || "Без названия",
                  url: `/courses/update_course?course_id=${props.courseInfo?.id}`,
                  disabled: true,
                },
              ]}
            />
          </span>
        </div>
        <div className={styles["actions-container"]}>
          {isAdmin && (
            <div className={styles["main-wrapper-header-actions"]}>
              <Menu model={ACTION_PAGE_BTN} popup ref={actionPageMenuRef} id="actionPageMenuRef" />
              <Button
                label="Действия"
                icon="pi pi-bolt"
                //@ts-ignore
                onClick={(event) => actionPageMenuRef.current.toggle(event)}
                aria-controls="actionPageMenuRef"
                aria-haspopup
                className={classNames(
                  styles["main-wrapper-header-actions-button"],
                  styles["main-wrapper-header-actions-action-button"],
                  openMenuTab !== UPDATE_COURSE_PAGE_MENU[0].name && styles["allone_btn"]
                )}
              />
              <Menu model={ADD_COURSES_BTN} popup ref={addCourseMenuRef} id="addCourseMenuRef" />
              <Button
                label="Добавить"
                icon="pi pi-plus"
                //@ts-ignore
                onClick={(event) => addCourseMenuRef.current.toggle(event)}
                aria-controls="addCourseMenuRef"
                aria-haspopup
                className={classNames(
                  styles["main-wrapper-header-actions-button"],
                  styles["main-wrapper-header-actions-add-button"],
                  openMenuTab !== UPDATE_COURSE_PAGE_MENU[0].name && styles["hide"]
                )}
              />
            </div>
          )}
        </div>
      </div>

      <div className={styles["main-wrapper-menu"]}>
        <TabView
          activeIndex={activeMenuIndex}
          onTabChange={(e) => setOpenMenuTab(UPDATE_COURSE_PAGE_MENU[e.index].name)}
        >
          {UPDATE_COURSE_PAGE_MENU.map((el) => (
            <TabPanel
              key={el.name}
              headerTemplate={(options) => (
                <span
                  className={classNames(
                    options.className,
                    styles["main-wrapper-menu-item"],
                    openMenuTab === el.name && styles["main-wrapper-menu-active-item"]
                  )}
                  onClick={options.onClick}
                >
                  <p>{el.label}</p>
                </span>
              )}
            />
          ))}
        </TabView>
      </div>
      <div className={styles["main-wrapper-content"]}>{renderActiveTab()}</div>
    </div>
  );
};

export default memo(UpdateCourseLayout);
