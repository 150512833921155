import { Dialog } from "primereact/dialog";
import { TTeacher } from "typings/courses";
import { MultiSelect } from "primereact/multiselect";
import { getCuratorsWithoutAccess } from "api/admins";
import { FC, useCallback, useEffect, useState } from "react";

import styles from "../components/comon/students/Students.module.scss";

type AddTeacherOnCoursePopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  addTeachers: (teachers: TTeacher[]) => void;
  courseId: number | undefined;
};

const AddTeacherOnCoursePopup: FC<AddTeacherOnCoursePopupProps> = (props) => {
  const [selectAll, setSelectAll] = useState(false);
  const [allTeachersList, setAllTeachersList] = useState<TTeacher[]>([]);
  const [selectedTeachers, setSelectedTeachers] = useState<TTeacher[]>([]);

  const closePopup = () => {
    setSelectedTeachers([]);
    props.handleHide();
  };

  const getTeachersList = useCallback(async () => {
    try {
      if (props.courseId) {
        const response = await getCuratorsWithoutAccess(props.courseId);
        if (response.status === 200 || response.status === 201) {
          setAllTeachersList(
            response.data.map((el: TTeacher) => ({
              ...el,
              fullName: el.firstName + " " + el.lastName,
            }))
          );
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {}
  }, [props.courseId]);

  useEffect(() => {
    props.isOpen && getTeachersList();
  }, [getTeachersList, props.isOpen]);

  return (
    <Dialog
      visible={props.isOpen}
      style={{ width: "500px" }}
      modal
      onHide={closePopup}
      header={`Добавление преподавателей`}
    >
      <div className={styles["create-account-popup-wrapper"]}>
        <span className={styles["create-account-input-wrapper"]}>
          <p>Выберите преподавателей</p>
          {allTeachersList.length > 0 ? (
            <MultiSelect
              value={selectedTeachers}
              options={allTeachersList}
              onChange={(e) => {
                setSelectedTeachers(e.value);
                setSelectAll(e.value.length === allTeachersList.length);
              }}
              selectAll={selectAll}
              onSelectAll={(e) => {
                setSelectedTeachers(e.checked ? [] : allTeachersList.map((item) => item));
                setSelectAll(!e.checked);
              }}
              maxSelectedLabels={2}
              placeholder="Выберите преподавателей"
              selectedItemsLabel={`${selectedTeachers.length} преподавателей выбрано`}
              optionLabel="fullName"
            />
          ) : (
            <i>Нет доступных преподавателей</i>
          )}
        </span>

        <div className={styles["create-account-btn-wrap"]}>
          <button className={styles["cancel-btn"]} onClick={closePopup}>
            Отмена
          </button>
          <button
            className={styles["create-acc-btn"]}
            onClick={() => {
              props.addTeachers(selectedTeachers);
              closePopup();
            }}
            disabled={selectedTeachers.length < 1}
          >
            Добавить
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default AddTeacherOnCoursePopup;
