import { FC } from "react";
import { TAdmin } from "typings/admins";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import styles from "../components/Admins/Admins.module.scss";

type AdminInfoPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  selectedAdmin: TAdmin | null;
};

const AdminInfoPopup: FC<AdminInfoPopupProps> = ({ isOpen, handleHide, selectedAdmin }) => {
  const renderColumn = (value: string) => (rowData: TAdmin) => {
    return (
      <div className="col-wrapper">
        <p className={styles["col-text"]}>{rowData[value]}</p>
      </div>
    );
  };

  return (
    <Dialog
      visible={isOpen}
      style={{ width: "500px" }}
      modal
      onHide={handleHide}
      header={`Информация пользователя`}
    >
      <div className={styles["create-account-popup-wrapper"]}>
        <div className={styles["create-account-input-wrapper"]}>
          <p>Список курсов:</p>
          {selectedAdmin?.courses && selectedAdmin?.courses.length > 0 ? (
            <DataTable
              value={selectedAdmin?.courses}
              className={styles["shedule-table"]}
              responsiveLayout="scroll"
              showGridlines
            >
              <Column
                field="id"
                header="ID"
                style={{ width: "20%" }}
                body={renderColumn("id")}
              ></Column>
              <Column
                field="name"
                header="Курс"
                style={{ width: "80%" }}
                body={renderColumn("name")}
              ></Column>
            </DataTable>
          ) : (
            <i>Нет назначенных курсов</i>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default AdminInfoPopup;
