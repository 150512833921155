export const ROLES = [
  {
    label: "Администратор",
    value: "admin",
  },
  {
    label: "Куратор",
    value: "curator",
  },
];

export const ROLES_LIST = {
  curator: "curator",
  admin: "admin",
};
