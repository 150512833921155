import { Dialog } from "primereact/dialog";
import { FC } from "react";
import styles from "../components/Admins/Admins.module.scss";
import { TAdmin } from "typings/admins";

type RemoveAdminPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  selectedAdmin: TAdmin | null;
  deleteAdmin: () => void;
};

const RemoveAdminPopup: FC<RemoveAdminPopupProps> = (props) => {
  return (
    <Dialog
      visible={props.isOpen}
      style={{ width: "345px" }}
      modal
      onHide={props.handleHide}
      header={`Удаление пользователя`}
    >
      <div className={styles["remove-admin-popup-wrapper"]}>
        <i className="pi pi-info-circle"></i>

        <h1>
          Вы уверены, что хотите удалить следующего{" "}
          {props.selectedAdmin?.isCurator ? "куратора" : "администратора"}?
        </h1>

        <span>{props.selectedAdmin?.firstName + " " + props.selectedAdmin?.lastName}</span>

        <p>Это ограничит доступ данного пользователя к Административной панели сервиса</p>

        <div className={styles["remove-admin-btn-wrap"]}>
          <button className={styles["cancel-btn"]} onClick={props.handleHide}>
            Отмена
          </button>
          <button className={styles["remove-btn"]} onClick={props.deleteAdmin}>
            Удалить
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default RemoveAdminPopup;
