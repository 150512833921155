import { useAppDispatch } from "utils/hooks";
import { FC, memo, useState } from "react";
import { addItem, changeComponent, changePosition, removeItem } from "slices/lessonConstructor";

import AddLessonComponentPopup from "popups/add-lesson-component";
import LessonConstructorLayout from "./Constructor.layout";

type LessonConstructorProps = {};

const LessonConstructor: FC<LessonConstructorProps> = () => {
  const [isOpenAddLessonItemPopup, setIsOpenAddLessonItemPopup] = useState<boolean>(false);

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectedType, setSelectedType] = useState<string>("");

  const handleOpenAddLessonItemPopup = (index: number, type: string) => {
    setIsOpenAddLessonItemPopup(true);
    setSelectedType(type);
    setSelectedIndex(index);
  };

  const handleCloseAddLessonItemPopup = () => setIsOpenAddLessonItemPopup(false);

  const dispatch = useAppDispatch();

  const changeItemPosition = (indexItem: number, type: string) => {
    dispatch(changePosition({ indexItem, type }));
  };

  const removeItemFunc = (indexItem: number) => {
    dispatch(removeItem({ indexItem }));
  };

  const addItemFunc = (indexItem: number, type: string, typeItem: string) => {
    dispatch(addItem({ indexItem, type, typeItem }));
  };

  const changeComponentFunc = (el: number, value: string) => {
    dispatch(changeComponent({ el, value }));
  };

  return (
    <>
      <LessonConstructorLayout
        changeItemPosition={changeItemPosition}
        removeItemFunc={removeItemFunc}
        addItemFunc={addItemFunc}
        changeComponentFunc={changeComponentFunc}
        handleOpenAddLessonItemPopup={handleOpenAddLessonItemPopup}
      />
      <AddLessonComponentPopup
        isOpen={isOpenAddLessonItemPopup}
        handleHide={handleCloseAddLessonItemPopup}
        addItemFunc={addItemFunc}
        index={selectedIndex}
        type={selectedType}
      />
    </>
  );
};

export default memo(LessonConstructor);
