import { Button } from "primereact/button";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "../components/Courses/UpdateCourse/UpdateCourse.module.scss";
import UpdateHeadCourse from "components/HeadCourse/UpdateHeadCourse/UpdateHeadCourse";

const UpdateHeadCoursesPage = () => {
  const [headCourseId, setHeadCourseId] = useState<string | null>();

  let { search } = useLocation();

  const navigate = useNavigate();

  const changeCourseId = (headCourseId: string | null) => setHeadCourseId(headCourseId);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const courseId: string | null = params.get("course_id");
    setHeadCourseId(courseId);
  }, []);

  const renderPageLayout = useCallback(() => {
    if (headCourseId)
      return <UpdateHeadCourse headCourseId={headCourseId} changeCourseId={changeCourseId} />;
    else
      return (
        <div className={styles["empty-page"]}>
          <i className="pi pi-minus-circle" />
          <p>К сожалению, курс не найден!</p>
          <Button
            className="p-button p-button-inverted"
            onClick={() => {
              navigate("/courses");
            }}
          >
            Вернуть к списку курсов
          </Button>
        </div>
      );
  }, [headCourseId]);

  return renderPageLayout();
};

export default UpdateHeadCoursesPage;
