import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { useAppDispatch } from "utils/hooks";
import { loginThunk } from "slices/authSlice";
import styles from "./Login.module.scss";
import Logo from "../../assets/login-logo.svg";
import { phrases } from "constants/phrases";
import { ERRORS } from "constants/errors";
import PasswordRecoveryPopup from "popups/password-recovery";
import { forgotPassword } from "api/auth";

export const Login = () => {
  const [isOpenPwdRecoveryPopup, setIsOpenPwdRecoveryPopup] = useState<boolean>(false);

  const [loginData, setLoginData] = useState<{
    email: string;
    password: string;
  }>({
    email: "",
    password: "",
  });

  const handleOpenPwdRecoveryPopup = () => setIsOpenPwdRecoveryPopup(true);
  const handleClosePwdRecoveryPopup = () => setIsOpenPwdRecoveryPopup(false);

  const accessToken = localStorage.getItem("accessToken");

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (accessToken) navigate("/");
  }, []);

  const handleUserData = (e) => {
    setLoginData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const onLogin = async () => {
    try {
      const response = await dispatch(loginThunk(loginData));
      if (response.payload.token) {
        dispatch(addSuccessMessage(phrases.login_sucess));
        navigate("/");
      } else throw new Error(response.payload.message || phrases.smthWentWrongText);
    } catch (err: any) {
      if (err.message.includes(ERRORS.invalid_email.backend_value))
        return dispatch(addErrorMessage(ERRORS.invalid_email.alert));
      else if (err.message.includes(ERRORS.invalid_pwd.backend_value))
        return dispatch(addErrorMessage(ERRORS.invalid_pwd.alert));
      else return dispatch(addErrorMessage(err.message || phrases.smthWentWrong));
    }
  };

  const forgotPswd = async (email: string) => {
    try {
      const response = await forgotPassword(email);
      if (response.status === 200 || response.status === 201) {
        dispatch(addSuccessMessage(phrases.send_pwd));
        handleClosePwdRecoveryPopup();
      } else throw new Error(response.data);
    } catch (err: any) {
      dispatch(addErrorMessage(err.message || phrases.smthWentWrong));
    }
  };

  return (
    <>
      <div className="login-body">
        <div className="login-wrapper">
          <div className="login-panel">
            <div className={styles["logo-wrap"]}>
              <img src={Logo} alt="logo" />
            </div>
            <InputText
              id="email"
              name="email"
              placeholder="Email"
              value={loginData.email}
              onChange={handleUserData}
            />
            <Password
              id="password"
              name="password"
              placeholder="Пароль"
              feedback={false}
              toggleMask
              value={loginData.password}
              onChange={handleUserData}
            />
            <Button label="Войти" type="button" className="login-button" onClick={onLogin}></Button>
            <span className={styles["pwd-recovery"]} onClick={handleOpenPwdRecoveryPopup}>
              Восстановить пароль
            </span>
          </div>
        </div>
      </div>
      <PasswordRecoveryPopup
        isOpen={isOpenPwdRecoveryPopup}
        handleHide={handleClosePwdRecoveryPopup}
        forgotPswd={forgotPswd}
      />
    </>
  );
};
