import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const isCurator = localStorage.getItem("isCurator");

  if (isCurator === "true") return <Navigate to={"/courses"} />;

  return <Outlet />;
};

export default PrivateRoute;
