import { Avatar } from "primereact/avatar";
import { phrases } from "constants/phrases";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";
import { useAppDispatch } from "utils/hooks";
import { addErrorMessage } from "slices/toastSlice";
import { tableControlsType } from "typings/table-controls";
import { Accordion, AccordionTab } from "primereact/accordion";
import { FC, memo, useCallback, useEffect, useState } from "react";
import { TAnswerAdditionalTask, THomeworkAnswer, TLesson } from "typings/lesson";
import { changeHomeWorkAnswerStatus, getAllHomeWorksAsync, sendCommentAsync } from "api/lesson";
import {
  ANSWER_ADDITIONAL_TYPE_TASKS,
  ANSWER_HOMEWORK_STATUSES,
  HOMEWORK_STATUSES,
  STATUSES_HOMEWORK_AWNSWER,
} from "constants/lesson";

import useDebounce from "utils/use-debounce";

import styles from "./HomeworkAnswers.module.scss";

type HomeworkAnswersProps = {
  lessonInfo: TLesson;
};

const defaultParams: tableControlsType = {
  first: 0,
  rows: 15,
  page: 0,
  search: "",
};

const HomeworkAnswers: FC<HomeworkAnswersProps> = (props) => {
  const [answersList, setAnswersList] = useState<THomeworkAnswer[]>([]);
  const [params, setParams] = useState<tableControlsType>(defaultParams);
  const [searchLocalValue, setSearchLocalValue] = useState<string>("");
  const [taskStatus, setTaskStatus] = useState<string>();
  const [comment, setComment] = useState<string>("");
  const [paginationParam, setPaginationParam] = useState<{
    hasNext: boolean;
    pageNumber: number;
  }>({
    hasNext: false,
    pageNumber: 0,
  });

  const debouncedSearchValue = useDebounce(searchLocalValue, 500);

  useEffect(() => {
    if (debouncedSearchValue !== params?.search) {
      setParams((prev) => ({ ...prev, search: debouncedSearchValue }));
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (searchLocalValue !== params?.search && typeof params?.search === "string") {
      setSearchLocalValue(params?.search);
    }
  }, [params?.search]);

  const dispatch = useAppDispatch();

  const getAnswersList = useCallback(
    async (params) => {
      try {
        const response = await getAllHomeWorksAsync(props.lessonInfo.id, {
          ...params,
        });
        if (response.status === 200 || response.status === 201) {
          setAnswersList(response.data.data);
          setPaginationParam({
            hasNext: response.data.pagination.currentPage + 1 < response.data.pagination.totalPages,
            pageNumber: response.data.pagination.currentPage,
          });
        } else throw new Error();
      } catch (err: any) {
        console.error(err);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    getAnswersList({
      page: params.page,
      limit: params.rows,
      search: params.search || undefined,
      taskStatus,
    });
  }, [params, getAnswersList, taskStatus]);

  const getMoreRow = async () => {
    try {
      const response = await getAllHomeWorksAsync(props.lessonInfo.id, {
        page: paginationParam.pageNumber + 1,
        limit: 15,
        search: params.search,
        taskStatus,
      });
      if (response.status === 200 || response.status === 201) {
        setAnswersList((prev) => [...prev, ...response.data.data]);
        setPaginationParam({
          hasNext: response.data.pagination.currentPage + 1 < response.data.pagination.totalPages,
          pageNumber: response.data.pagination.currentPage,
        });
      } else throw new Error("error");
    } catch (err) {
      dispatch(addErrorMessage("Error"));
    }
  };

  const renderAdditionalContent = useCallback((item: TAnswerAdditionalTask) => {
    switch (item.type) {
      case "string":
        return <p className={styles["text"]}>{item.content || "Нет ответа"}</p>;
      case "text":
        return <p className={styles["text"]}>{item.content || "Нет ответа"}</p>;
      case "money":
        return <p className={styles["text"]}>{item.content || "Нет ответа"}</p>;
      case "number":
        return <p className={styles["text"]}>{item.content || "Нет ответа"}</p>;
      case "checkbox":
        return <p className={styles["text"]}>{item.content === "1" ? "Да" : "Нет"}</p>;
      case "file": {
        const file = item.content ? JSON.parse(item.content) : undefined;
        if (file)
          return (
            <a target="_blank" className={styles["file"]} href={file && file.url ? file.url : "#"}>
              {file && file.name ? file.name : "Без названия"}
            </a>
          );
        else return <p className={styles["text"]}>{"Нет ответа"}</p>;
      }
      case "files": {
        const files = item.content ? JSON.parse(item.content) : undefined;
        if (files)
          return (
            <span className={styles["files-list"]}>
              {files.map((el) => (
                <a
                  key={el.key}
                  target="_blank"
                  className={styles["file"]}
                  href={el && el.url ? el.url : "#"}
                >
                  {el && el.name ? el.name : "Без названия"}
                </a>
              ))}
            </span>
          );
        else return <p className={styles["text"]}>{"Нет ответа"}</p>;
      }
      default:
        return <p>Нет ответа</p>;
    }
  }, []);

  const sendComment = useCallback(
    async (lessonId: number, studentId: number) => {
      try {
        if (lessonId && studentId) {
          const response = await sendCommentAsync({
            lessonId,
            studentId,
            text: comment,
            filesData: [],
          });
          if (response.status === 200 || response.status === 201) {
            getAnswersList({
              page: params.page,
              limit: params.rows,
              search: params.search || undefined,
              taskStatus,
            });
            setComment("");
          } else {
            throw new Error(phrases.smthWentWrongText);
          }
        } else throw new Error(phrases.smthWentWrongText);
      } catch (err: any) {
        dispatch(addErrorMessage(phrases.smthWentWrongText));
      }
    },
    [dispatch, comment]
  );

  const changeAnswerStatus = useCallback(
    async (lessonId: number, studentId: number, localtaskStatus: string) => {
      try {
        const response = await changeHomeWorkAnswerStatus(String(lessonId), {
          taskStatus: localtaskStatus,
          studentId,
          isNotAddPoints: false,
        });
        if (response.status === 200 || response.status === 201) {
          getAnswersList({
            page: params.page,
            limit: params.rows,
            search: params.search || undefined,
            taskStatus,
          });
        } else {
          throw new Error(phrases.smthWentWrongText);
        }
      } catch (err) {
        dispatch(addErrorMessage(phrases.smthWentWrongText));
      }
    },
    [getAnswersList]
  );

  const customAnswerHeader = (el: THomeworkAnswer) => {
    return (
      <div className={styles["item-header"]}>
        <Avatar
          label={
            `${el.firstName?.charAt(0) ? el.firstName?.charAt(0).toUpperCase() : ""}` +
            `${el.lastName?.charAt(0) ? el.lastName?.charAt(0).toUpperCase() : ""}`
          }
          size="xlarge"
          className={styles["avatar"]}
        />
        <div className={styles["item-header-row"]}>
          <span className={styles["item-header-name"]}>
            <p>{el.firstName}</p>
            <p>{el.lastName}</p>
          </span>
          <span>
            <i className={styles["email"]}>{el.email}</i>
          </span>
          <span className={styles["item-header-date"]}>
            <p>Ответ создан:</p>
            <p>
              {el.answerTaskAt
                ? new Date(el.answerTaskAt).toLocaleDateString() +
                " " +
                new Date(el.answerTaskAt).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })
                : "Дата не задана"}
            </p>
          </span>
        </div>
        <div className={styles["status-answer"]}>
          <span className={styles[el.taskStatus]}>{ANSWER_HOMEWORK_STATUSES[el.taskStatus]}</span>
        </div>
      </div>
    );
  };

  return (
    <div className={styles["main"]}>
      <span className="p-input-icon-left p-main-input">
        <i className="pi pi-search" />
        <InputText
          value={searchLocalValue}
          type="text"
          placeholder="Поиск"
          onChange={(e) => setSearchLocalValue(e.target.value)}
        />
      </span>

      <span className={styles["filter"]}>
        <Dropdown
          options={STATUSES_HOMEWORK_AWNSWER}
          onChange={(e) => setTaskStatus(e.value)}
          value={taskStatus}
          placeholder="Все статусы"
          className={styles["available-dropdown"]}
          optionLabel="name"
          optionValue="value"
          name="available"
        />
      </span>

      <div className={styles["list"]}>
        {answersList && answersList.length > 0 ? (
          <Accordion activeIndex={0}>
            {answersList.map((el) => (
              <AccordionTab
                header={customAnswerHeader(el)}
                key={el.studentId}
                disabled={el.taskStatus === "denied"}
              >
                <div className={styles["additional-list"]}>
                  {(el.taskResponse.text || el.taskResponse.filesData.length > 0) && (
                    <div className={styles["additional-list-item"]}>
                      <span>Основное поле</span>
                      <p>{el.taskResponse.text || "Нет ответа"}</p>
                      {el.taskResponse.filesData && el.taskResponse.filesData.length > 0 && (
                        <span>Файлы:</span>
                      )}
                      {el.taskResponse.filesData && el.taskResponse.filesData.length > 0 && (
                        <span>
                          {el.taskResponse.filesData.map((file) => (
                            <a key={file.key} href={file.url} target="_blank">
                              {file.name}
                            </a>
                          ))}
                        </span>
                      )}
                    </div>
                  )}
                  {el.taskResponse.additionalFields.map((item) => (
                    <div key={item.id} className={styles["additional-list-item"]}>
                      <span>{ANSWER_ADDITIONAL_TYPE_TASKS[item.type]}</span>
                      {renderAdditionalContent(item)}
                    </div>
                  ))}
                  <div className={styles["comments-wrapper"]}>
                    <span className={styles["comments-header"]}>Комментарии</span>
                    <div className={styles["my-comment"]}>
                      <i className="pi pi-user-edit" />
                      <div className={styles["my-comment-body"]}>
                        <Dropdown
                          options={HOMEWORK_STATUSES}
                          onChange={(e) => {
                            changeAnswerStatus(props.lessonInfo.id, el.studentId, e.value);
                          }}
                          value={el.taskStatus}
                          placeholder="Выберите куда копировать степ"
                          className={styles["available-dropdown"]}
                        />
                        <InputTextarea
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                          rows={5}
                          cols={30}
                          className={styles["comment-field"]}
                          placeholder="Ваш комментарий ..."
                        />
                        {comment && comment.length > 0 && (
                          <button
                            onClick={() => {
                              sendComment(props.lessonInfo.id, el.studentId);
                            }}
                          >
                            Отправить
                          </button>
                        )}
                      </div>
                    </div>

                    <div className={styles["comments-list"]}>
                      {el.comments
                        .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
                        .map((comment, index) => (
                          <div
                            className={classNames(
                              styles["comment-wrapper"],
                              index !== el.comments.length - 1 &&
                              styles["comment-wrapper-with-border"]
                            )}
                            key={comment.id}
                          >
                            <div className={styles["item-header"]}>
                              <Avatar
                                label={
                                  `${comment.firstName?.charAt(0)
                                    ? comment.firstName?.charAt(0).toUpperCase()
                                    : ""
                                  }` +
                                  `${comment.lastName?.charAt(0)
                                    ? comment.lastName?.charAt(0).toUpperCase()
                                    : ""
                                  }`
                                }
                                size="xlarge"
                                className={styles["avatar"]}
                              />
                              <div>
                                <div className={styles["comment-header-row"]}>
                                  <span className={styles["comment-header-row-name"]}>
                                    <p>{comment.firstName}</p>
                                    <p>{comment.lastName}</p>
                                  </span>
                                  <span className={styles["comment-header-row-point"]}>.</span>
                                  <span className={styles["comment-header-row-date"]}>
                                    <p>
                                      {comment.createdAt
                                        ? new Date(comment.createdAt).toLocaleDateString() +
                                        " " +
                                        new Date(comment.createdAt).toLocaleTimeString([], {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        })
                                        : ""}
                                    </p>
                                  </span>
                                </div>
                                <div className={styles["comment-header-row"]}>
                                  <p className={styles["comment-text"]}>{comment.text}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </AccordionTab>
            ))}
          </Accordion>
        ) : (
          <p className={styles["empty-list"]}>Список ответов пуст</p>
        )}
      </div>

      {paginationParam.hasNext && (
        <div className={styles["upload-more-row"]}>
          <button onClick={getMoreRow}>Загрузить больше</button>
        </div>
      )}
    </div>
  );
};

export default memo(HomeworkAnswers);
