import { FC } from "react";

import LessonPreviewLayout from "./Preview.layout";

type LessonPreviewProps = {};

const LessonPreview: FC<LessonPreviewProps> = () => {
  return <LessonPreviewLayout />;
};

export default LessonPreview;
