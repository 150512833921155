import { FC } from "react";
import { Dialog } from "primereact/dialog";
import { TCourseStudent } from "typings/students";

import styles from "../components/Students/Students.module.scss";

type RemoveStudentFromCoursePopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  selectedStudent: TCourseStudent | null;
  deleteStudent: () => void;
};

const RemoveStudentFromCoursePopup: FC<RemoveStudentFromCoursePopupProps> = (props) => {
  return (
    <Dialog
      visible={props.isOpen}
      style={{ width: "345px" }}
      modal
      onHide={props.handleHide}
      header={`Удаление студента`}
    >
      <div className={styles["remove-student-popup-wrapper"]}>
        <i className="pi pi-info-circle"></i>

        <h1>Вы уверены, что хотите удалить следующего студента?</h1>

        <span>{props.selectedStudent?.fullname || "---"}</span>

        <p>Это ограничит доступ данного студента к курсу</p>

        <div className={styles["remove-student-btn-wrap"]}>
          <button className={styles["cancel-btn"]} onClick={props.handleHide}>
            Отмена
          </button>
          <button className={styles["remove-btn"]} onClick={props.deleteStudent}>
            Удалить
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default RemoveStudentFromCoursePopup;
