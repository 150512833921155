import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { FC, useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { TNewHeadCourse } from "typings/headCourse";

import styles from "../components/Admins/Admins.module.scss";

type CreateHeadCoursePopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  createHeadCourse: (headCourseData: TNewHeadCourse) => void;
};

const CreateHeadCoursePopup: FC<CreateHeadCoursePopupProps> = (props) => {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [startAt, setStartAt] = useState<Date | Date[] | undefined>(undefined);
  const [endAt, setEndAt] = useState<Date | Date[] | undefined>(undefined);
  const [isFree, setIsFree] = useState<boolean>(false);

  const clearStates = () => {
    setName("");
    setDescription("");
  };

  const createNewHeadCourse = () => {
    props.createHeadCourse({
      name,
      description,
      startAt: new Date(String(startAt)).toISOString(),
      endAt: new Date(String(endAt)).toISOString(),
      isFree,
    });
    clearStates();
  };

  return (
    <Dialog
      visible={props.isOpen}
      style={{ width: "500px" }}
      modal
      onHide={() => {
        props.handleHide();
        clearStates();
      }}
      header={`Создание нового курса`}
    >
      <div className={styles["create-account-popup-wrapper"]}>
        <span className={styles["create-account-input-wrapper"]}>
          <p>Название</p>
          <InputText
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Введите название"
          />
        </span>
        <span className={styles["create-account-input-wrapper"]}>
          <p>Описание</p>
          <InputTextarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={5}
            cols={30}
            autoResize
            placeholder="Введите описание"
          />
        </span>
        <span className={styles["chek-wrap"]}>
          <Checkbox onChange={() => setIsFree(!isFree)} checked={isFree}></Checkbox>
          <label className="p-checkbox-label">Бесплатные материалы</label>
        </span>
        <span className={styles["create-account-input-wrapper"]}>
          <p>Введите дату старта курса</p>
          <Calendar
            value={startAt}
            onChange={(e) => setStartAt(e.value)}
            showTime
            hourFormat="24"
            className={styles["calendar"]}
            dateFormat="dd/mm/yy"
            readOnlyInput
            minDate={new Date()}
            placeholder="Введите дату старта курса"
          />
        </span>
        <span className={styles["create-account-input-wrapper"]}>
          <p>Введите дату окончания курса</p>
          <Calendar
            value={endAt}
            onChange={(e) => setEndAt(e.value)}
            showTime
            hourFormat="24"
            className={styles["calendar"]}
            dateFormat="dd/mm/yy"
            readOnlyInput
            minDate={new Date()}
            placeholder="Введите дату окончания курса"
          />
        </span>
        <div className={styles["create-account-btn-wrap"]}>
          <button
            className={styles["cancel-btn"]}
            onClick={() => {
              props.handleHide();
              clearStates();
            }}
          >
            Отмена
          </button>
          <button
            className={styles["create-acc-btn"]}
            onClick={createNewHeadCourse}
            disabled={!name || !startAt || !endAt}
          >
            Создать
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default CreateHeadCoursePopup;
