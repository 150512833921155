import http from "./https";
import { TLesson, TNewComment } from "typings/lesson";

type getAllAnswersParamsType = {
  page?: number;
  limit?: number;
  search?: string;
  taskStatus: undefined | string;
};

export const getLessonById = (lessonId: string) => http.get(`/api/lessons/${lessonId}`);

export const updateLessonAsync = (lessonData: TLesson) =>
  http.patch(`/api/lessons/${lessonData.id}`, lessonData);

export const removeLesson = (lessonId: number) => http.remove(`/api/lessons/${lessonId}`, {});

export const copyLesonAsync = (lessonId: string, data: { name: string; courseId: number }) =>
  http.post(`api/lessons/${lessonId}/copy`, data);

export const getAllHomeWorksAsync = (lessonId: number, params: getAllAnswersParamsType) =>
  http.get(
    `/api/lessons/${lessonId}/answers`,
    {},
    {
      params,
    }
  );

export const sendCommentAsync = (commentData: TNewComment) =>
  http.post(`/api/comments`, commentData);

export const changeHomeWorkAnswerStatus = (
  lessonId: string,
  data: { taskStatus: string; studentId: number; isNotAddPoints?: boolean }
) => http.patch(`/api/accesses/${lessonId}/change-status-task`, data);
