// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Image_image-wrapper__NFzmU {
  width: 100%;
  display: flex;
  justify-content: center;
}
.Image_image-wrapper__NFzmU .Image_image__DWcms {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Image_image-wrapper__NFzmU .Image_image__DWcms img {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Lesson/Preview/Components/Image/Image.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,uBAAA;AACF;AACE;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AACI;EACE,WAAA;AACN","sourcesContent":[".image-wrapper {\r\n  width: 100%;\r\n  display: flex;\r\n  justify-content: center;\r\n\r\n  .image {\r\n    width: 100%;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n\r\n    img {\r\n      width: 100%;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image-wrapper": `Image_image-wrapper__NFzmU`,
	"image": `Image_image__DWcms`
};
export default ___CSS_LOADER_EXPORT___;
