import { phrases } from "constants/phrases";
import { TNewLesson } from "typings/lesson";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "utils/hooks";
import { addErrorMessage, addSuccessMessage } from "slices/toastSlice";
import { TCourseInfo, TNewCourse, TUpdateCourse } from "typings/courses";
import { FC, memo, useCallback, useEffect, useState } from "react";
import {
  copyCourseAsync,
  createNewLesson,
  createNewModule,
  getCourseById,
  removeCourse,
  saveChangePlacesAsync,
  updateCourseAsync,
} from "api/courses";

import CreateModulePopup from "popups/create-module";
import CreateLessonPopup from "popups/create-lesson";
import ScrollToTop from "react-scroll-to-top";
import RemoveCoursePopup from "popups/remove-course";
import UpdateCourseLayout from "./UpdateCourse.layout";

import styles from "./UpdateCourse.module.scss";
import ChangePlacesPopup from "popups/change-places";
import CopyCoursePopup from "popups/copy-course";

type UpdateCourseProps = {
  courseId: string | null | undefined;
  headCourseId: string | null | undefined;
  changeCourseId: (courseID: string | null) => void;
};

const UpdateCourse: FC<UpdateCourseProps> = (props) => {
  const [courseInfo, setCourseInfo] = useState<TCourseInfo | null>(null);
  const [isOpenCreateModulePopup, setIsOpenCreateModulePopup] = useState<boolean>(false);
  const [isOpenCreateLessonPopup, setIsOpenCreateLessonPopup] = useState<boolean>(false);
  const [isOpenRemoveCoursePopup, setIsOpenRemoveCoursePopup] = useState<boolean>(false);
  const [isOpenCopyCourse, setIsOpenCopyCourse] = useState<boolean>(false);
  const [isOpenChangePlacesPopup, setIsOpenChangePlacesPopup] = useState<{
    isOpen: boolean;
    type: string;
  }>({
    isOpen: false,
    type: "modules",
  });

  const handleOpenCreateModulePopup = () => setIsOpenCreateModulePopup(true);
  const handleCloseCreateModulePopup = () => setIsOpenCreateModulePopup(false);

  const handleOpenCreateLessonPopup = () => setIsOpenCreateLessonPopup(true);
  const handleCloseCreateLessonPopup = () => setIsOpenCreateLessonPopup(false);

  const handleOpenRemoveCoursePopup = () => setIsOpenRemoveCoursePopup(true);
  const handleCloseRemoveCoursePopup = () => setIsOpenRemoveCoursePopup(false);

  const handleOpenCopyCoursePopup = () => setIsOpenCopyCourse(true);
  const handleCloseCopyCoursePopup = () => setIsOpenCopyCourse(false);

  const handleOpenChangePlacesPopup = (type: string) =>
    setIsOpenChangePlacesPopup({ type: type, isOpen: true });
  const handleCloseChangePlacesPopup = () =>
    setIsOpenChangePlacesPopup((prev) => ({ ...prev, isOpen: false }));

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const getCourseInfo = useCallback(async () => {
    try {
      if (props.courseId) {
        const response = await getCourseById(props.courseId);
        if (response.status === 200 || response.status === 201) {
          setCourseInfo(response.data);
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {
      props.changeCourseId(null);
    }
  }, [dispatch, props.courseId]);

  useEffect(() => {
    getCourseInfo();
  }, [getCourseInfo]);

  const createModule = useCallback(
    async (moduleData: TNewCourse) => {
      try {
        const response = await createNewModule({
          ...moduleData,
          parentCourseId: Number(courseInfo?.id),
        });
        if (response.status === 200 || response.status === 201) {
          navigate({
            pathname: "/courses/update_module",
            search: `?module_id=${response.data.id}&step_id=${courseInfo?.id}&course_id=${props.headCourseId}`,
          });
          dispatch(addSuccessMessage(phrases.module_success_added));
        } else throw new Error();
      } catch (err: any) {
        return dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrong));
      } finally {
        handleCloseCreateModulePopup();
      }
    },
    [dispatch, courseInfo]
  );

  const createLesson = useCallback(
    async (lessonData: TNewLesson) => {
      try {
        const response = await createNewLesson({
          ...lessonData,
          courseId: Number(courseInfo?.id),
          headCourseId: Number(props.headCourseId),
        });
        if (response.status === 200 || response.status === 201) {
          navigate({
            pathname: "/courses/update_lesson",
            search: `?lesson_id=${response.data.id}&step_id=${courseInfo?.id}&course_id=${props.headCourseId}`,
          });
          dispatch(addSuccessMessage(phrases.lesson_success_added));
        } else throw new Error();
      } catch (err: any) {
        return dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrong));
      } finally {
        handleCloseCreateLessonPopup();
      }
    },
    [dispatch, courseInfo]
  );

  const deleteCourse = useCallback(
    async (courseId: number) => {
      try {
        const response = await removeCourse(courseId);
        if (response.status === 200 || response.status === 201) {
          dispatch(addSuccessMessage(phrases.step_success_deleted));
          navigate(`/courses/update_course?course_id=${props.headCourseId}`);
          getCourseInfo();
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      } finally {
        handleCloseRemoveCoursePopup();
      }
    },
    [dispatch]
  );

  const updateCourse = useCallback(
    async (courseData: TUpdateCourse) => {
      try {
        if (courseInfo) {
          const response = await updateCourseAsync(courseInfo?.id, courseData);
          if (response.status === 200 || response.status === 201) {
            getCourseInfo();
            dispatch(addSuccessMessage(phrases.step_success_updated));
          } else throw new Error();
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      }
    },
    [dispatch, courseInfo]
  );

  const saveChangePlaces = useCallback(
    async (indexesData: { id: number; index: number }[], isLessons: boolean) => {
      try {
        if (props.courseId) {
          const response = await saveChangePlacesAsync(indexesData, props.courseId, isLessons);
          if (response.status === 200 || response.status === 201) {
            getCourseInfo();
            dispatch(addSuccessMessage(phrases.step_success_updated));
          } else throw new Error();
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      }
    },
    [dispatch, props.courseId]
  );

  const copyCourse = useCallback(
    async (name: string, headCourseId: number) => {
      try {
        if (props.courseId) {
          const response = await copyCourseAsync(props.courseId, {
            name,
            headCourseId,
          });
          if (response.status === 200 || response.status === 201) {
            dispatch(addSuccessMessage(phrases.step_success_copy));
          } else throw new Error();
        } else throw new Error();
      } catch (err: any) {
        dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
      } finally {
        handleCloseCopyCoursePopup();
      }
    },
    [dispatch, props.courseId]
  );

  return (
    <>
      <UpdateCourseLayout
        courseInfo={courseInfo}
        headCourseId={props.headCourseId}
        handleOpenCreateModulePopup={handleOpenCreateModulePopup}
        handleOpenCreateLessonPopup={handleOpenCreateLessonPopup}
        updateCourse={updateCourse}
        handleOpenRemoveCoursePopup={handleOpenRemoveCoursePopup}
        handleOpenChangePlacesPopup={handleOpenChangePlacesPopup}
        handleOpenCopyCoursePopup={handleOpenCopyCoursePopup}
      />

      <CreateModulePopup
        isOpen={isOpenCreateModulePopup}
        handleHide={handleCloseCreateModulePopup}
        createModule={createModule}
      />

      <CreateLessonPopup
        isOpen={isOpenCreateLessonPopup}
        handleHide={handleCloseCreateLessonPopup}
        createLesson={createLesson}
      />

      <RemoveCoursePopup
        isOpen={isOpenRemoveCoursePopup}
        handleHide={handleCloseRemoveCoursePopup}
        deleteCourse={deleteCourse}
        selectedCourse={courseInfo}
      />

      <ChangePlacesPopup
        isOpen={isOpenChangePlacesPopup.isOpen}
        type={isOpenChangePlacesPopup.type}
        handleHide={handleCloseChangePlacesPopup}
        courseId={props.courseId}
        saveChangePlaces={saveChangePlaces}
      />

      <CopyCoursePopup
        isOpen={isOpenCopyCourse}
        handleHide={handleCloseCopyCoursePopup}
        courseInfo={courseInfo}
        copyCourse={copyCourse}
      />

      <ScrollToTop
        smooth
        component={<i className="pi pi-chevron-up"></i>}
        className={styles["up-btn"]}
      />
    </>
  );
};

export default memo(UpdateCourse);
