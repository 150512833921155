import { FC } from "react";
import { InputText } from "primereact/inputtext";
import { TCourseFAQ } from "typings/courses";
import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";

import styles from "./AdditionalSettings.module.scss";

type AdditionalSettingsFAQProps = {
  faqList: TCourseFAQ[];
  changeFaq: React.Dispatch<React.SetStateAction<TCourseFAQ[]>>;
};

const AdditionalSettingsFAQ: FC<AdditionalSettingsFAQProps> = (props) => {
  const addNewItem = () => {
    props.changeFaq((prev) => [...prev, { text: "", title: "" }]);
  };

  const removeItem = (removeIndex: number) => {
    const newArray = props.faqList.filter((el, index) => index !== removeIndex);
    props.changeFaq(newArray);
  };

  const changeItem = (updateIndex: number, fieldName: string, value: string | number | null) => {
    const newArray = props.faqList.map((el, index) => {
      if (index === updateIndex) {
        return { ...el, [fieldName]: value };
      } else return el;
    });
    props.changeFaq(newArray);
  };

  return (
    <div className={styles["list-container"]}>
      {props.faqList.map((el, index) => (
        <div
          className={classNames(
            styles["list-item"],
            index + 1 !== props.faqList.length && styles["separator"]
          )}
          key={index}
        >
          <div>
            <InputText
              value={el.title}
              onChange={(e) => {
                changeItem(index, "title", e.target.value);
              }}
              placeholder="Введите вопрос"
              className={styles["form-item"]}
            />
            <InputTextarea
              value={el.text}
              onChange={(e) => {
                changeItem(index, "text", e.target.value);
              }}
              rows={3}
              cols={30}
              autoResize
              placeholder="Введите ответ"
              className={styles["form-item"]}
            />
          </div>
          <button className={styles["remove-btn"]} onClick={() => removeItem(index)}>
            <i className="pi pi-trash" />
          </button>
        </div>
      ))}
      <button className={styles["add-btn"]} onClick={addNewItem}>
        Добавить вопрос
      </button>
    </div>
  );
};

export default AdditionalSettingsFAQ;
