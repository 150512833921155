import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { AutoComplete } from "primereact/autocomplete";
import { getAllTarifsList } from "api/tarifs";
import { getShortListStudents } from "api/students";
import { FC, useCallback, useEffect, useState } from "react";

import styles from "../components/comon/students/Students.module.scss";

type AddStudentOnCoursePopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  addStudents: (selectedTarif: number, studentIds: number[]) => void;
  courseId: number | undefined;
};

const AddStudentOnCoursePopup: FC<AddStudentOnCoursePopupProps> = (props) => {
  const [allStudentsList, setAllStudentsList] = useState<{ value: number; label: string }[]>([]);
  const [tarifsList, setTarifsList] = useState<{ name: string; id: number }[]>([]);
  const [selectedStudents, setSelectedStudents] = useState<{ value: number; label: string }[]>([]);
  const [selectedTarif, setSelectedTarif] = useState<number | null>(null);

  const clearStates = () => {
    setSelectedStudents([]);
  };

  const getTarifsList = useCallback(async () => {
    try {
      if (props.courseId) {
        const response = await getAllTarifsList(props.courseId);
        if (response.status === 200 || response.status === 201) {
          setTarifsList(response.data);
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {}
  }, [props.courseId]);

  useEffect(() => {
    if (props.isOpen) {
      getTarifsList();
    }
  }, [props.isOpen, props.courseId]);

  const searchItems = async (event) => {
    try {
      if (props.courseId) {
        const response = await getShortListStudents(props.courseId, {
          search: event.query,
        });
        if (response.status === 200 || response.status === 201) {
          const correctArrayStudents = response.data.data.map((el) => ({
            value: el.id,
            label: el.firstName + " " + el.lastName,
          }));
          setAllStudentsList(correctArrayStudents);
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {}
  };

  return (
    <Dialog
      visible={props.isOpen}
      style={{ width: "500px" }}
      modal
      onHide={() => {
        props.handleHide();
        clearStates();
      }}
      header={`Добавление учеников`}
    >
      <div className={styles["create-account-popup-wrapper"]}>
        <span className={styles["create-account-input-wrapper"]}>
          <p>Выберите учеников</p>
          <AutoComplete
            value={selectedStudents}
            suggestions={allStudentsList}
            completeMethod={searchItems}
            virtualScrollerOptions={{ itemSize: 20 }}
            onChange={(e) => setSelectedStudents(e.value)}
            dropdown
            field="label"
            placeholder="Выберите учеников"
            multiple
            className={styles["autocomplete"]}
          />
        </span>
        <span className={styles["create-account-input-wrapper"]}>
          <p>Выберите тариф</p>
          <Dropdown
            options={tarifsList}
            onChange={(e) => setSelectedTarif(e.target.value)}
            value={selectedTarif}
            placeholder="Выберите тариф"
            optionLabel="name"
            optionValue="id"
          />
        </span>

        <div className={styles["create-account-btn-wrap"]}>
          <button
            className={styles["cancel-btn"]}
            onClick={() => {
              props.handleHide();
              clearStates();
            }}
          >
            Отмена
          </button>
          <button
            className={styles["create-acc-btn"]}
            onClick={() => {
              selectedTarif &&
                props.addStudents(
                  selectedTarif,
                  selectedStudents.map((el) => el.value)
                );
              clearStates();
            }}
            disabled={selectedStudents.length < 1 || !selectedTarif}
          >
            Добавить
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default AddStudentOnCoursePopup;
