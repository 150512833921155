import styles from "./video.module.scss";

export const FilesPopapVideo = (file) => (
  <div className={styles["video-wrapper"]}>
    <video controls width="100%" preload="none" poster={file.file.url}>
      <source src={file.file.url} />
    </video>
    <div className={styles["fake"]} />
  </div>
);
