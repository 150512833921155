// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConditionDepositMoney_condition-wrapper__DbMEk {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.ConditionDepositMoney_condition-wrapper__DbMEk .ConditionDepositMoney_add_btn__IuAb6 {
  max-width: 200px;
  margin-top: 20px;
  height: 35px;
  cursor: pointer !important;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border: none;
}
.ConditionDepositMoney_condition-wrapper__DbMEk .ConditionDepositMoney_add_btn__IuAb6:hover {
  background: rgba(140, 87, 238, 0.8) !important;
}
.ConditionDepositMoney_condition-wrapper__DbMEk .ConditionDepositMoney_add_btn__IuAb6 i {
  margin-right: 8px;
}
.ConditionDepositMoney_condition-wrapper__DbMEk .ConditionDepositMoney_content__4PKno {
  width: 100%;
}
.ConditionDepositMoney_condition-wrapper__DbMEk .ConditionDepositMoney_content__4PKno .ConditionDepositMoney_empty-list__RaP0p {
  display: flex;
  width: 100%;
  justify-content: center;
}
.ConditionDepositMoney_condition-wrapper__DbMEk .ConditionDepositMoney_content__4PKno .ConditionDepositMoney_list-item__w4jgY .ConditionDepositMoney_iterable__VCqNw {
  margin-right: 10px;
}
.ConditionDepositMoney_condition-wrapper__DbMEk .ConditionDepositMoney_content__4PKno .ConditionDepositMoney_list-item__w4jgY .ConditionDepositMoney_form-item__rpKk9 {
  margin-right: 10px;
  width: fit-content;
}
.ConditionDepositMoney_condition-wrapper__DbMEk .ConditionDepositMoney_content__4PKno .ConditionDepositMoney_list-item__w4jgY .ConditionDepositMoney_delete-btn__lXB5\\+ {
  width: 30px;
  height: 30px;
  border: none;
  background-color: transparent;
  color: red;
  cursor: pointer;
  border-radius: 50%;
  margin-left: 10px;
  transition: all 0.3s;
}
.ConditionDepositMoney_condition-wrapper__DbMEk .ConditionDepositMoney_content__4PKno .ConditionDepositMoney_list-item__w4jgY .ConditionDepositMoney_delete-btn__lXB5\\+ i {
  font-size: 16px;
}
.ConditionDepositMoney_condition-wrapper__DbMEk .ConditionDepositMoney_content__4PKno .ConditionDepositMoney_list-item__w4jgY .ConditionDepositMoney_delete-btn__lXB5\\+:hover {
  color: rgb(187, 16, 16);
}`, "",{"version":3,"sources":["webpack://./src/components/Lesson/LessonUpdate/tabs/homework-and-comments/ConditionDepositMoney.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;AACF;AACE;EACE,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,0BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AACJ;AACI;EACE,8CAAA;AACN;AAEI;EACE,iBAAA;AAAN;AAIE;EACE,WAAA;AAFJ;AAII;EACE,aAAA;EACA,WAAA;EACA,uBAAA;AAFN;AAMM;EACE,kBAAA;AAJR;AAOM;EACE,kBAAA;EACA,kBAAA;AALR;AAQM;EACE,WAAA;EACA,YAAA;EACA,YAAA;EACA,6BAAA;EACA,UAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;EAEA,oBAAA;AAPR;AASQ;EACE,eAAA;AAPV;AAUQ;EACE,uBAAA;AARV","sourcesContent":[".condition-wrapper {\r\n  width: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n\r\n  .add_btn {\r\n    max-width: 200px;\r\n    margin-top: 20px;\r\n    height: 35px;\r\n    cursor: pointer !important;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    white-space: nowrap;\r\n    border: none;\r\n\r\n    &:hover {\r\n      background: rgba(140, 87, 238, 0.8) !important;\r\n    }\r\n\r\n    i {\r\n      margin-right: 8px;\r\n    }\r\n  }\r\n\r\n  .content {\r\n    width: 100%;\r\n\r\n    .empty-list {\r\n      display: flex;\r\n      width: 100%;\r\n      justify-content: center;\r\n    }\r\n\r\n    .list-item {\r\n      .iterable {\r\n        margin-right: 10px;\r\n      }\r\n\r\n      .form-item {\r\n        margin-right: 10px;\r\n        width: fit-content;\r\n      }\r\n\r\n      .delete-btn {\r\n        width: 30px;\r\n        height: 30px;\r\n        border: none;\r\n        background-color: transparent;\r\n        color: red;\r\n        cursor: pointer;\r\n        border-radius: 50%;\r\n        margin-left: 10px;\r\n\r\n        transition: all 0.3s;\r\n\r\n        i {\r\n          font-size: 16px;\r\n        }\r\n\r\n        &:hover {\r\n          color: rgb(187, 16, 16);\r\n        }\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"condition-wrapper": `ConditionDepositMoney_condition-wrapper__DbMEk`,
	"add_btn": `ConditionDepositMoney_add_btn__IuAb6`,
	"content": `ConditionDepositMoney_content__4PKno`,
	"empty-list": `ConditionDepositMoney_empty-list__RaP0p`,
	"list-item": `ConditionDepositMoney_list-item__w4jgY`,
	"iterable": `ConditionDepositMoney_iterable__VCqNw`,
	"form-item": `ConditionDepositMoney_form-item__rpKk9`,
	"delete-btn": `ConditionDepositMoney_delete-btn__lXB5+`
};
export default ___CSS_LOADER_EXPORT___;
