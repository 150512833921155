import { CORRECT_EMAIL } from "constants/email";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { FC, useState } from "react";
import { addErrorMessage } from "slices/toastSlice";
import { useAppDispatch } from "utils/hooks";
import styles from "../components/Login/Login.module.scss";
import { ERRORS } from "constants/errors";

type PasswordRecoveryPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  forgotPswd: (email: string) => void;
};

const PasswordRecoveryPopup: FC<PasswordRecoveryPopupProps> = ({
  isOpen,
  handleHide,
  forgotPswd,
}) => {
  const [email, setEmail] = useState<string>("");

  const dispatch = useAppDispatch();

  const forgotPasssword = () => {
    if (!CORRECT_EMAIL.test(email)) {
      return dispatch(addErrorMessage(ERRORS.invalid_email.alert));
    } else {
      forgotPswd(email);
    }
  };

  return (
    <Dialog
      visible={isOpen}
      style={{ width: "420px" }}
      modal
      onHide={() => {
        handleHide();
        setEmail("");
      }}
    >
      <div className={styles["fgt-pwd-wrapper"]}>
        <div className={styles["input-wrapper"]}>
          <p>Введите email. Новый пароль будет направлен вам на почту.</p>
          <InputText
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder="Введите email"
          />
        </div>
        <div className={styles["btn-wrap"]}>
          <Button
            className={styles["cancel-button"]}
            onClick={() => {
              handleHide();
              setEmail("");
            }}
          >
            Отмена
          </Button>
          <button
            className={styles["reset-btn"]}
            onClick={() => {
              forgotPasssword();
              setEmail("");
            }}
          >
            Получить пароль
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default PasswordRecoveryPopup;
