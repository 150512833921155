import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { FC, useState } from "react";

import styles from "../components/comon/course-tarifs-table/CourseTarifsTable.module.scss";

type CreateTarifPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  createTarif: (name: string) => void;
};

const CreateTarifPopup: FC<CreateTarifPopupProps> = (props) => {
  const [name, setName] = useState<string>("");

  const clearStates = () => {
    setName("");
  };

  return (
    <Dialog
      visible={props.isOpen}
      style={{ width: "500px" }}
      modal
      onHide={() => {
        props.handleHide();
        clearStates();
      }}
      header={`Добавить тариф`}
    >
      <div className={styles["create-tarif-popup-wrapper"]}>
        <span className={styles["create-tarif-input-wrapper"]}>
          <p>Название тарифа</p>
          <InputText
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Введите название тарифа"
          />
        </span>
        <div className={styles["create-tarif-btn-wrap"]}>
          <button
            className={styles["cancel-btn"]}
            onClick={() => {
              props.handleHide();
              clearStates();
            }}
          >
            Отмена
          </button>
          <button
            className={styles["create-tarif-btn"]}
            onClick={() => {
              props.createTarif(name);
              clearStates();
            }}
            disabled={!name}
          >
            Добавить
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default CreateTarifPopup;
