export const UPDATE_MODULE_PAGE_MENU: {
  label: string;
  name: string;
}[] = [
  {
    label: "Содержание",
    name: "content",
  },
  {
    label: "Настройки",
    name: "settings",
  },
  {
    label: "Доступ",
    name: "access",
  },
  {
    label: "Расписание",
    name: "schedule",
  },
  // {
  //   label: "Ученики",
  //   name: "students",
  // },
];
