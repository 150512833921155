import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { FC, useState } from "react";
import { TNewStudent } from "typings/students";
import styles from "../components/Students/Students.module.scss";

type CreateStudentPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  createStudent: (studentData: TNewStudent) => void;
};

const CreateStudentPopup: FC<CreateStudentPopupProps> = (props) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const clearStates = () => {
    setEmail("");
    setFirstName("");
    setLastName("");
  };

  const createNewStudent = () => {
    props.createStudent({
      firstName,
      lastName,
      email,
    });
    clearStates();
  };

  return (
    <Dialog
      visible={props.isOpen}
      style={{ width: "500px" }}
      modal
      onHide={() => {
        props.handleHide();
        clearStates();
      }}
      header={`Добавление нового студента`}
    >
      <div className={styles["create-account-popup-wrapper"]}>
        <span className={styles["create-account-input-wrapper"]}>
          <p>Имя</p>
          <InputText
            name="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            maxLength={50}
            placeholder="Введите имя"
          />
        </span>
        <span className={styles["create-account-input-wrapper"]}>
          <p>Фамилия</p>
          <InputText
            name="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            maxLength={50}
            placeholder="Введите фамилию"
          />
        </span>
        <span className={styles["create-account-input-wrapper"]}>
          <p>Email</p>
          <InputText
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            maxLength={50}
            placeholder="Введите email"
          />
        </span>
        <div className={styles["create-account-btn-wrap"]}>
          <button
            className={styles["cancel-btn"]}
            onClick={() => {
              props.handleHide();
              clearStates();
            }}
          >
            Отмена
          </button>
          <button
            className={styles["create-acc-btn"]}
            onClick={createNewStudent}
            disabled={!firstName || !email || !lastName}
          >
            Добавить
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default CreateStudentPopup;
