import { Dialog } from "primereact/dialog";
import { FC } from "react";
import styles from "../components/Admins/Admins.module.scss";
import { TModule } from "typings/module";

type RemoveModulePopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  selectedModule: TModule | null;
  deleteModule: (courseId: number) => void;
};

const RemoveModulePopup: FC<RemoveModulePopupProps> = (props) => {
  const removeModule = () => {
    if (props.selectedModule) props.deleteModule(props.selectedModule.id);
    else return;
  };

  return (
    <Dialog
      visible={props.isOpen}
      style={{ width: "345px" }}
      modal
      onHide={props.handleHide}
      header={`Удаление модуля`}
    >
      <div className={styles["remove-admin-popup-wrapper"]}>
        <i className="pi pi-info-circle"></i>

        <h1>Вы уверены, что хотите удалить следующий модуль?</h1>

        <span>{props.selectedModule?.name}</span>

        <p></p>

        <div className={styles["remove-admin-btn-wrap"]}>
          <button className={styles["cancel-btn"]} onClick={props.handleHide}>
            Отмена
          </button>
          <button className={styles["remove-btn"]} onClick={removeModule}>
            Удалить
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default RemoveModulePopup;
