export const TARIFS_COURSE_AVAILABLE_VALUES = [
  {
    name: "2",
    value: 2,
  },
  {
    name: "4",
    value: 4,
  },
  {
    name: "6",
    value: 6,
  },
  {
    name: "8",
    value: 8,
  },
];
