import { getRoles } from "utils/getRoles";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";
import { Accordion, AccordionTab } from "primereact/accordion";
import { FC, memo, useCallback, useEffect, useState } from "react";
import { TCourseInfo, TCoursePanel, TTeacher, TUpdateCourse } from "typings/courses";

import styles from "./Settings.module.scss";
import AdditionalSettingsFAQ from "./AdditionalSettingsFAQ";
import AddTeacherOnCoursePopup from "popups/add-teachers-on-course";
import AdditionalSettingsPanel from "./AdditionalSettingsPanel";
import AdditionalSettingsTeachers from "./AdditionalSettingsTeachers";
import AdditionalSettingsCourseFor from "./AdditionalSettingsCourseFor";
import AdditionalSettingsReviews from "./AdditionalSettingsReviews";

type CourseSettingsTabProps = {
  courseInfo: TCourseInfo | null;
  updateCourse: (courseData: TUpdateCourse) => void;
};

const CourseSettingsTab: FC<CourseSettingsTabProps> = (props) => {
  const [name, setName] = useState<string>("");
  const [previewHeader, setPreviewHeader] = useState<string>("");
  const [teachers, setTeachers] = useState<TTeacher[]>([]);
  const [description, setDescription] = useState<string>("");
  const [isOpenAddTeahersPopup, setIsOpenAddTeahersPopup] = useState<boolean>(false);
  const [panel, setPanel] = useState<TCoursePanel[]>([emptyPanel]);
  const [advantages, setAdvantages] = useState([emptyCourseFor]);
  const [faq, setFaq] = useState([emptyFAQ]);
  const [teachersInfo, setTeachersInfo] = useState([emptyTeacher]);
  const [reviews, setReviews] = useState([emptyReview]);

  const isAdmin = getRoles();

  const handleOpenAddTeahersPopup = () => setIsOpenAddTeahersPopup(true);
  const handleCloseAddTeahersPopup = () => setIsOpenAddTeahersPopup(false);

  useEffect(() => {
    if (props.courseInfo) {
      setName(props.courseInfo.name);
      setDescription(props.courseInfo.description);
      setTeachers(props.courseInfo.teachers);
      setPanel(props.courseInfo?.previewInfo?.panel || [emptyPanel]);
      setAdvantages(props.courseInfo?.previewInfo?.advantages || [emptyCourseFor]);
      setFaq(props.courseInfo?.previewInfo?.faq || [emptyFAQ]);
      setTeachersInfo(props.courseInfo?.previewInfo?.teachersInfo || [emptyTeacher]);
      setReviews(props.courseInfo?.previewInfo?.reviews || [emptyReview]);
      setPreviewHeader(props.courseInfo?.previewHeader);
    }
  }, [props.courseInfo]);

  const addTeachers = (newteachers: TTeacher[]) => {
    const allTeachers = [
      ...teachers,
      ...newteachers.map((el) => ({ id: el.id, firstName: el.firstName, lastName: el.lastName })),
    ];
    const temple = {};
    const newTeachersArray = allTeachers.filter(({ id }) => !temple[id] && (temple[id] = 1));
    setTeachers(newTeachersArray);
  };

  const removeTeachers = (teacher: TTeacher) =>
    setTeachers((prev) => prev.filter((el) => el.id !== teacher.id));

  const renderTeacherList = useCallback(() => {
    return (
      <div className={styles["main-wrapper-content-right-teachers-list"]}>
        {teachers.map((teacher) => (
          <div key={teacher.id} className={styles["main-wrapper-content-right-teachers-list-item"]}>
            <span>
              <i className="pi pi-user" />
              <p>{teacher.firstName + " " + teacher.lastName}</p>
            </span>
            {isAdmin && (
              <button onClick={() => removeTeachers(teacher)}>
                <i className="pi pi-trash" />
              </button>
            )}
          </div>
        ))}
        {isAdmin && (
          <button
            className={styles["main-wrapper-content-right-teachers-add_btn"]}
            onClick={handleOpenAddTeahersPopup}
          >
            Добавить преподавателя
          </button>
        )}
      </div>
    );
  }, [teachers, props.courseInfo]);

  return (
    <div className={styles["main-wrapper"]}>
      <div className={styles["main-wrapper-content"]}>
        <div className={styles["main-wrapper-content-left"]}>
          <span className={styles["main-wrapper-content-left-label"]}>
            <p>Название</p>
            <InputText
              id="name"
              name="name"
              placeholder="Название"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={!isAdmin}
            />
          </span>
          <span className={styles["main-wrapper-content-left-label"]}>
            <p>Описание</p>
            <InputTextarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={5}
              cols={30}
              autoResize
              placeholder="Описание"
              disabled={!isAdmin}
            />
          </span>
          <span className={styles["main-wrapper-content-left-label"]}>
            <p>Заголовок превью</p>
            <InputText
              id="previewHeader"
              name="previewHeader"
              placeholder="Заголовок превью"
              value={previewHeader}
              onChange={(e) => setPreviewHeader(e.target.value)}
              disabled={!isAdmin}
            />
          </span>
          <Accordion className={styles["accordion"]}>
            <AccordionTab header="Настроить панель" disabled={!isAdmin}>
              <div className={styles["add-set-block"]}>
                <p className={styles["add-set-block-header"]}>Панель</p>
                <AdditionalSettingsPanel panelList={panel} changePanel={setPanel} />
              </div>
            </AccordionTab>
          </Accordion>
          <Accordion className={styles["accordion"]}>
            <AccordionTab header="Курс для тебя, если…" disabled={!isAdmin}>
              <div className={styles["add-set-block"]}>
                <p className={styles["add-set-block-header"]}>Курс для тебя, если…</p>
                <AdditionalSettingsCourseFor
                  advantagesList={advantages}
                  changeAdvantage={setAdvantages}
                />
              </div>
            </AccordionTab>
          </Accordion>
          <Accordion className={styles["accordion"]}>
            <AccordionTab header="Отзывы" disabled={!isAdmin}>
              <div className={styles["add-set-block"]}>
                <p className={styles["add-set-block-header"]}>Отзывы</p>
                <AdditionalSettingsReviews reviewsList={reviews} changeReviews={setReviews} />
              </div>
            </AccordionTab>
          </Accordion>
          <Accordion className={styles["accordion"]}>
            <AccordionTab header="Кто ведет курс" disabled={!isAdmin}>
              <div className={styles["add-set-block"]}>
                <p className={styles["add-set-block-header"]}>Кто ведет курс</p>
                <AdditionalSettingsTeachers
                  teachersList={teachersInfo}
                  changeTeachers={setTeachersInfo}
                />
              </div>
            </AccordionTab>
          </Accordion>
          <Accordion className={styles["accordion"]}>
            <AccordionTab header="FAQ" disabled={!isAdmin}>
              <div className={styles["add-set-block"]}>
                <p className={styles["add-set-block-header"]}>FAQ</p>
                <AdditionalSettingsFAQ faqList={faq} changeFaq={setFaq} />
              </div>
            </AccordionTab>
          </Accordion>
        </div>
        <div className={styles["main-wrapper-content-right"]}>
          <div className={styles["main-wrapper-content-right-teachers"]}>
            <p className={styles["main-wrapper-content-right-teachers-header"]}>Преподаватели</p>
            {teachers.length > 0 ? (
              <>{renderTeacherList()}</>
            ) : (
              <div className={styles["main-wrapper-content-right-teachers-list_empty"]}>
                <p>Вы не добавили ни одного преподавателя</p>
                {isAdmin && (
                  <button
                    className={styles["main-wrapper-content-right-teachers-add_btn"]}
                    onClick={handleOpenAddTeahersPopup}
                  >
                    Добавить преподавателя
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {isAdmin && (
        <button
          className={classNames(
            styles["save-button"],
            (name !== props.courseInfo?.name ||
              previewHeader !== props.courseInfo?.previewHeader ||
              description !== props.courseInfo?.description ||
              teachers !== props.courseInfo.teachers ||
              panel !== props.courseInfo?.previewInfo?.panel ||
              advantages !== props.courseInfo?.previewInfo?.advantages ||
              faq !== props.courseInfo?.previewInfo?.faq ||
              teachersInfo !== props.courseInfo?.previewInfo?.teachersInfo ||
              reviews !== props.courseInfo?.previewInfo?.reviews) &&
              styles["save-button-visible"]
          )}
          onClick={() =>
            props.updateCourse({
              name,
              description,
              teacherIds: teachers.map((el) => el.id),
              previewInfo: { panel, advantages, faq, teachersInfo, reviews },
              previewHeader,
            })
          }
        >
          Сохранить изменения
        </button>
      )}

      <AddTeacherOnCoursePopup
        isOpen={isOpenAddTeahersPopup}
        handleHide={handleCloseAddTeahersPopup}
        addTeachers={addTeachers}
        courseId={props.courseInfo?.id}
      />
    </div>
  );
};

export default memo(CourseSettingsTab);

const emptyPanel = {
  number: 0,
  title: "",
  text: "",
};

const emptyCourseFor = {
  title: "",
  text: "",
  url: "",
};

const emptyFAQ = {
  title: "",
  text: "",
};

const emptyTeacher = {
  fio: "",
  avatar: "",
  text: "",
};

const emptyReview = {
  fio: "",
  age: "",
  avatar: "",
  text: "",
  url: "",
};
