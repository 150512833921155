import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import styles from "../components/Courses/UpdateCourse/UpdateCourse.module.scss";
import UpdateTarif from "components/Courses/UpdateTarif/UpdateTarif";

const UpdateTarifPage = () => {
  const [stepId, setStepId] = useState<string | null>();
  const [courseId, setCourseId] = useState<string | null>();
  const [tarifId, setTarifId] = useState<string | null>();

  let { search } = useLocation();

  const navigate = useNavigate();

  const changeTarifId = (tarifID: string | null) => setTarifId(tarifID);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const stepId: string | null = params.get("step_id");
    const courseId: string | null = params.get("course_id");
    const tarifId: string | null = params.get("tarif_id");
    setStepId(stepId);
    setCourseId(courseId);
    setTarifId(tarifId);
  }, []);

  const renderPageLayout = useCallback(() => {
    if (tarifId)
      return (
        <UpdateTarif
          courseId={stepId}
          headCourseId={courseId}
          tarifId={tarifId}
          changeTarifId={changeTarifId}
        />
      );
    else
      return (
        <div className={styles["empty-page"]}>
          <i className="pi pi-minus-circle" />
          <p>К сожалению, тариф не найден!</p>
          <Button
            className="p-button p-button-inverted"
            onClick={() => {
              stepId
                ? navigate(`/courses/update_step?course_id=${courseId}&step_id=${stepId}`)
                : navigate(`/courses`);
            }}
          >
            {stepId ? "Вернуться к степу" : "Вернуться к списку курсов"}
          </Button>
        </div>
      );
  }, [tarifId]);

  return renderPageLayout();
};

export default UpdateTarifPage;
