import { classNames } from "primereact/utils";
import { FC, CSSProperties } from "react";
import RingLoader from "react-spinners/RingLoader";
import styles from "./Loader.module.scss";

type LoaderProps = {
  loading: boolean;
};

export const Loader: FC<LoaderProps> = ({ loading }) => {
  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
  };

  return (
    <div
      className={classNames(
        styles.loaderWrapper,
        loading ? styles.showLoader : styles.hideLoader
      )}
    >
      <RingLoader
        color={"#771feb"}
        loading={loading}
        cssOverride={override}
        size={150}
        speedMultiplier={1}
      />
      {/* <p>Ваш запрос обрабатывается. Это может занять некоторое время</p> */}
    </div>
  );
};
