import { Calendar } from "primereact/calendar";
import { getRoles } from "utils/getRoles";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";
import { openFileStoragePopup } from "slices/fileStoragePopupSlice";
import { IMAGE_COUNTER_LABEL_LIST } from "constants/lesson";
import { FC, memo, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { THeadCourseInfo, TUpdateHeadCourse } from "typings/headCourse";

import styles from "./Settings.module.scss";

type CourseSettingsTabProps = {
  headCourseInfo: THeadCourseInfo | null;
  updateHeadCourse: (headCourseData: TUpdateHeadCourse) => void;
};

const CourseSettingsTab: FC<CourseSettingsTabProps> = (props) => {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [startAt, setStartAt] = useState<Date | Date[] | undefined>(undefined);
  const [endAt, setEndAt] = useState<Date | Date[] | undefined>(undefined);
  const [imageUrl, setIamgeUrl] = useState<string | null>(null);

  const { selectedUrl }: { selectedUrl: string } = useAppSelector(
    (state) => state.fileStoragePopup
  );

  const isAdmin = getRoles();

  const fileTypes = ["JPEG", "PNG", "JPG"];

  const dispatch = useAppDispatch();

  const handleOpenFileStoragePopup = () =>
    dispatch(
      openFileStoragePopup({
        type: "image",
        fileTypes,
        counterLabels: IMAGE_COUNTER_LABEL_LIST,
        isSingleType: true,
      })
    );

  useEffect(() => {
    if (props.headCourseInfo) {
      setName(props.headCourseInfo.name);
      setDescription(props.headCourseInfo.description);
      setStartAt(new Date(props.headCourseInfo.startAt));
      setEndAt(new Date(props.headCourseInfo.endAt));
      setIamgeUrl(props.headCourseInfo.imageUrl);
    }
  }, [props.headCourseInfo]);

  useEffect(() => {
    if (selectedUrl) setIamgeUrl(selectedUrl);
  }, [selectedUrl]);

  return (
    <div className={styles["main-wrapper"]}>
      <div className={styles["main-wrapper-content"]}>
        <div className={styles["main-wrapper-content-left"]}>
          <span className={styles["main-wrapper-content-left-label"]}>
            <p>Название</p>
            <InputText
              id="name"
              name="name"
              placeholder="Название"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={!isAdmin}
            />
          </span>
          <span className={styles["main-wrapper-content-left-label"]}>
            <p>Описание</p>
            <InputTextarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={5}
              cols={30}
              autoResize
              placeholder="Описание"
              disabled={!isAdmin}
            />
          </span>
          <span className={styles["main-wrapper-content-left-label"]}>
            <p>Введите дату старта курса</p>
            <Calendar
              value={startAt}
              onChange={(e) => setStartAt(e.value)}
              showTime
              hourFormat="24"
              className={styles["calendar"]}
              dateFormat="dd/mm/yy"
              readOnlyInput
              minDate={new Date()}
              placeholder="Введите дату старта курса"
              disabled={!isAdmin}
            />
          </span>
          <span className={styles["main-wrapper-content-left-label"]}>
            <p>Введите дату окончания курса</p>
            <Calendar
              value={endAt}
              onChange={(e) => setEndAt(e.value)}
              showTime
              hourFormat="24"
              className={styles["calendar"]}
              dateFormat="dd/mm/yy"
              readOnlyInput
              minDate={new Date()}
              placeholder="Введите дату окончания курса"
              disabled={!isAdmin}
            />
          </span>
        </div>
        <div className={styles["main-wrapper-content-right"]}>
          <span className={styles["main-wrapper-content-left-label"]}>
            <p>Обложка</p>
            <div className={styles["uploader"]}>
              <div className={styles["uloader-wrapper"]} onClick={handleOpenFileStoragePopup}>
                <div
                  className={classNames(
                    styles["image-wrapper"],
                    imageUrl && styles["filled-image-wrapper"]
                  )}
                >
                  {imageUrl ? (
                    <img alt="Preview" src={imageUrl} className={styles["img"]}></img>
                  ) : (
                    <div className={styles["empty-image-wrapper"]}>
                      <span>
                        <i className="pi pi-image" />
                      </span>
                      <p>Загрузите изображение</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </span>
        </div>
      </div>

      {isAdmin && (
        <button
          className={classNames(
            styles["save-button"],
            (name !== props.headCourseInfo?.name ||
              description !== props.headCourseInfo?.description ||
              new Date(String(startAt)).toISOString() !==
                new Date(props.headCourseInfo.startAt).toISOString() ||
              new Date(String(endAt)).toISOString() !==
                new Date(props.headCourseInfo.endAt).toISOString() ||
              imageUrl !== props.headCourseInfo?.imageUrl) &&
              styles["save-button-visible"]
          )}
          onClick={() =>
            props.updateHeadCourse({
              name,
              description,
              startAt: new Date(String(startAt)).toISOString(),
              endAt: new Date(String(endAt)).toISOString(),
              imageUrl,
            })
          }
        >
          Сохранить изменения
        </button>
      )}
    </div>
  );
};

export default memo(CourseSettingsTab);
