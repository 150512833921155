import { FC, memo } from "react";
import { getRoles } from "utils/getRoles";
import { classNames } from "primereact/utils";
import { useNavigate } from "react-router";
import { TModuleInfo } from "typings/module";

import styles from "./Content.module.scss";

type ModuleContentTabProps = {
  moduleInfo: TModuleInfo | null;
  courseId: string | null | undefined;
  headCourseId: string | null | undefined;
};

const ModuleContentTab: FC<ModuleContentTabProps> = (props) => {
  const isAdmin = getRoles();

  const navigate = useNavigate();

  return (
    <div className={styles["main-wrapper"]}>
      {props.moduleInfo && props.moduleInfo?.lessons.length > 0 && (
        <div className={styles["main-wrapper-leson_list"]}>
          {props.moduleInfo?.lessons.map((el) => (
            <div className={styles["lesson-list-item"]} key={el.id}>
              <div className={styles["row"]}>
                <span>{el.name}</span>
                <p>
                  {el.startAt
                    ? new Date(el.startAt).toLocaleDateString() +
                      " " +
                      new Date(el.startAt).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                    : "Дата не задана"}
                </p>
                <p>{el.description}</p>
              </div>
              <button
                className={classNames(styles["action-btn"], styles["edit-btn"])}
                onClick={() => {
                  navigate({
                    pathname: "/courses/update_lesson",
                    search: `?module_id=${props.moduleInfo?.id}&course_id=${props.headCourseId}&lesson_id=${el.id}&step_id=${props.courseId}`,
                  });
                }}
              >
                {isAdmin ? <i className="pi pi-pencil" /> : <i className="pi pi-reply" />}
              </button>
            </div>
          ))}
          <span className={styles["main-wrapper-leson_list-label"]}>Уроки</span>
        </div>
      )}
    </div>
  );
};

export default memo(ModuleContentTab);
