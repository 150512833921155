import { Dialog } from "primereact/dialog";
import { TShortCourse } from "typings/courses";
import { getCourseById } from "api/courses";
import { CHAGE_POSITION } from "constants/lesson";
import { FC, useCallback, useEffect, useState } from "react";

import styles from "../components/Courses/Courses.module.scss";

type ChangePlacesPopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  courseId: string | null | undefined;
  type: string;
  saveChangePlaces: (indexesData: { id: number; index: number }[], isLessons: boolean) => void;
};

const ChangePlacesPopup: FC<ChangePlacesPopupProps> = (props) => {
  const [itemList, setItemList] = useState<TShortCourse[]>([]);

  const closePopup = () => {
    props.handleHide();
    setItemList([]);
  };

  const getCoursesModulesList = useCallback(async () => {
    try {
      if (props.courseId) {
        const response = await getCourseById(props.courseId);
        if (response.status === 200 || response.status === 201) {
          const modulesList: TShortCourse[] = [];
          const lessonsList: TShortCourse[] = [];

          props.type === "lessons" &&
            response.data.lessons.map((lesson, index) => {
              lessonsList.push({
                id: lesson.id,
                index: index,
                name: lesson.name,
              });
            });

          props.type === "modules" &&
            response.data.submodules.map((module, index) => {
              modulesList.push({
                id: module.id,
                index: index,
                name: module.name,
              });
            });

          setItemList(props.type === "lessons" ? lessonsList : modulesList);
        } else throw new Error();
      } else throw new Error();
    } catch (err: any) {}
  }, [props.courseId, props.type]);

  useEffect(() => {
    props.isOpen && getCoursesModulesList();
  }, [getCoursesModulesList, props.isOpen]);

  const changePosition = (type: string, indexItem: number) => {
    const swap = (index1: number, index2: number) => {
      const newArray = itemList.map((el) => {
        if (el.index === index1) {
          return { ...el, index: index2 };
        } else if (el.index === index2) return { ...el, index: index1 };
        else return el;
      });
      setItemList(newArray);
    };

    if (
      (indexItem === 0 && type === CHAGE_POSITION.UP) ||
      (indexItem === itemList.length - 1 && type === CHAGE_POSITION.DOWN)
    )
      return;
    if (type === CHAGE_POSITION.UP) swap(indexItem, indexItem - 1);
    else if (type === CHAGE_POSITION.DOWN) swap(indexItem, indexItem + 1);
    else return;
  };

  const renderItemList = useCallback(() => {
    return (
      <>
        {itemList && itemList.length > 0 ? (
          itemList
            .sort((a, b) => (a.index > b.index ? 1 : -1))
            .map((el, index) => (
              <div className={styles["ch-pl-item"]} key={el.id}>
                <span>
                  <p>{index + 1}.</p>
                  <p>{el.name}</p>
                </span>
                <div className={styles["ch-pl-item-settings"]}>
                  {index !== 0 && (
                    <button onClick={() => changePosition(CHAGE_POSITION.UP, el.index)}>
                      <i className="pi pi-arrow-up" />
                    </button>
                  )}
                  {index !== itemList.length - 1 && (
                    <button onClick={() => changePosition(CHAGE_POSITION.DOWN, el.index)}>
                      <i className="pi pi-arrow-down" />
                    </button>
                  )}
                </div>
              </div>
            ))
        ) : (
          <p className={styles["popup-wrapper-empty_list"]}>
            Вы не добавили ни одного {props.type === "lessons" ? "урока" : "модуля"}
          </p>
        )}
      </>
    );
  }, [itemList]);

  return (
    <Dialog
      visible={props.isOpen}
      style={{ width: "500px" }}
      modal
      onHide={closePopup}
      header={`Перемещение ${props.type === "lessons" ? "уроков" : "модулей"}`}
    >
      <div className={styles["popup-wrapper"]}>
        <span className={styles["popup-wrapper-header"]}>
          Расположите {props.type === "lessons" ? "уроки" : "модули"} в необходимой
          последовательности, после нажмите кнопку сохранить.
        </span>
        {renderItemList()}
        <div className={styles["btn-wrap"]}>
          <button className={styles["cancel-btn"]} onClick={closePopup}>
            Отмена
          </button>
          <button
            className={styles["copy-btn"]}
            onClick={() => {
              props.saveChangePlaces(itemList, props.type === "lessons" ? true : false);
              closePopup();
            }}
          >
            Сохранить
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default ChangePlacesPopup;
