import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { login } from "api/auth";

const initialState = {};

export const loginThunk = createAsyncThunk(
  "login",
  async ({ email, password }: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const { data } = await login(email, password);
      return data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginThunk.fulfilled, (_, action) => {
      localStorage.setItem("accessToken", action.payload.token); 
      localStorage.setItem("role", action.payload.role); 
      localStorage.setItem("isCurator", action.payload.isCurator); 
    });
  },
});

export const {} = authSlice.actions;
