import loremImg from "../assets/lorem.png";

export const UPDATE_LESSON_PAGE_MENU: {
  label: string;
  name: string;
}[] = [
  {
    label: "Содержание",
    name: "content",
  },
  // {
  //   label: "Настройки",
  //   name: "settings",
  // },
  {
    label: "Задание и комментарии",
    name: "homeweork_and_comments",
  },
  {
    label: "Ответы на задание",
    name: "homework_answers",
  },
];

export const CHAGE_POSITION = {
  UP: "up",
  DOWN: "down",
};

export const LESSON_TAB = {
  PREVIEW: "preview",
  CONSTRUCTOR: "constructor",
};

export const LESSON_TYPE_COMPONENTS = {
  TEXT: "text",
  IMAGE: "image",
  VIDEO: "video",
  AUDIO: "audio",
  FILE: "file",
  HEADER: "header",
  SEPARATOR: "separator",
  NET_VIDEO: "net_video",
  OTHER: "other",
};

export const ALL_LESSON_BLOCKS = [
  {
    label: "Простой текст",
  },
];

export const ADD_LESSON_CONPONENTS_POPUP = [
  {
    type: "text",
    label: "Текст",
    icon: "pi pi-align-justify",
  },
  {
    type: "image",
    label: "Изображение",
    icon: "pi pi-image",
  },
  {
    type: "video",
    label: "Видео",
    icon: "pi pi-video",
  },
  {
    type: "net_video",
    label: "Видео c Youtube / Vimeo / RuTube",
    icon: "pi pi-youtube",
  },
  {
    type: "audio",
    label: "Аудио",
    icon: "pi pi-volume-up",
  },
  {
    type: "file",
    label: "Файл",
    icon: "pi pi-file",
  },
  {
    type: "header",
    label: "Заголовок",
    icon: "pi pi-align-center",
  },
  {
    type: "separator",
    label: "Разделитель",
    icon: "pi pi-ellipsis-h",
  },
];

export const ADD_LESSON_CONPONENTS_SHORT_LIST = [
  {
    type: "text",
    label: "Текст",
    icon: "pi pi-align-justify",
  },
  {
    type: "video",
    label: "Видео",
    icon: "pi pi-video",
  },
  {
    type: "audio",
    label: "Аудио",
    icon: "pi pi-volume-up",
  },
  {
    type: "file",
    label: "Файл",
    icon: "pi pi-file",
  },
  {
    type: "image",
    label: "Изображение",
    icon: "pi pi-image",
  },
];

export const LESSON_AVAILABLE_OPTIONS = [
  {
    label: "Доступен",
    value: "available",
  },
  {
    label: "Недоступен, но отображается в списке",
    value: "unavailable_show",
  },
  {
    label: "Недоступен, не показывать в списке",
    value: "unavailable_not_show",
  },
];

export const IMAGE_COUNTER_LABEL_LIST = ["изображение", "изображения", "изображений"];
export const VIDEO_COUNTER_LABEL_LIST = ["видеозапись", "видеозаписи", "видеозаписей"];
export const AUDIO_COUNTER_LABEL_LIST = ["аудиозапись", "аудиозаписи", "аудиозаписей"];
export const FILE_COUNTER_LABEL_LIST = ["файл", "файла", "файлов"];

export const HOMEWORK_LESSON_VISIBLE = [
  { name: "ответ виден ВСЕМ участникам", value: "all" },
  { name: "ответ виден только ученику и учителю (ученик может показать всем)", value: "single" },
];

export const HOMEWORK_TYPES_ADDITIONAL_TASK = [
  { name: "Строка", value: "string" },
  { name: "Текст (несколько строк)", value: "text" },
  { name: "Деньги", value: "money" },
  { name: "Число", value: "number" },
  { name: "Чекбокс", value: "checkbox" },
  { name: "Файл", value: "file" },
  { name: "Список файлов", value: "files" },
];

export const ANSWER_HOMEWORK_STATUSES = {
  check: "На проверке",
  accept: "Принято",
  denied: "Отказ от выполнения (домашнее задание не требует проверки)",
};

export const HOMEWORK_STATUSES = [
  {
    value: "check",
    label: "На проверке",
  },
  {
    value: "accept",
    label: "Принять",
  },
  {
    value: "denied",
    label: "Отклонить",
  },
];

export const ANSWER_ADDITIONAL_TYPE_TASKS = {
  string: "Строка",
  file: "Файл",
  number: "Число",
  text: "Текст",
  files: "Список файлов",
  money: "Деньки",
  checkbox: "Чекбокс",
};

export const STATUSES_HOMEWORK_AWNSWER = [
  { name: "Все статусы", value: null },
  { name: "Принятые", value: "accept" },
  { name: "Отклоненные", value: "reject" },
  { name: "На проверке", value: "check" },
];
