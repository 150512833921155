import { FC, memo } from "react";
import { getRoles } from "utils/getRoles";
import { classNames } from "primereact/utils";
import { useNavigate } from "react-router";
import { THeadCourseInfo } from "typings/headCourse";
import { returnCorrectNameCounterLessonsModules } from "utils/returnCorrectNameCounterLessonsModules";

import styles from "./Content.module.scss";

type CourseContentTabProps = {
  headCourseInfo: THeadCourseInfo | null;
};

const CourseContentTab: FC<CourseContentTabProps> = (props) => {
  const navigate = useNavigate();

  const isAdmin = getRoles();

  return (
    <div className={styles["main-wrapper"]}>
      {props.headCourseInfo && props.headCourseInfo?.courses.length > 0 && (
        <div
          className={classNames(
            styles["main-wrapper-leson_list"],
            styles["main-wrapper-leson_list_label"]
          )}
        >
          {props.headCourseInfo?.courses
            .sort((a, b) => (a.index > b.index ? 1 : -1))
            .map((el) => (
              <div className={styles["lesson-list-item"]} key={el.id}>
                <div>
                  <span>{el.name}</span>
                  <p className={styles["black-text"]}>
                    {el.count_submodules && el.count_submodules !== "0" && (
                      <b>
                        {el.count_submodules}{" "}
                        {returnCorrectNameCounterLessonsModules(el.count_submodules, "module")}
                        {". "}
                      </b>
                    )}
                    {el.count_lessons && el.count_lessons !== "0" && (
                      <b>
                        {el.count_lessons}{" "}
                        {returnCorrectNameCounterLessonsModules(el.count_lessons, "lesson")}
                        {". "}
                      </b>
                    )}
                  </p>
                  <p>
                    {el.startAt
                      ? new Date(el.startAt).toLocaleDateString() +
                        " " +
                        new Date(el.startAt).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      : "Дата не задана"}
                  </p>
                  <p className={styles["desc"]}>{el.description}</p>
                </div>
                <div>
                  <button
                    className={classNames(styles["action-btn"], styles["edit-lesson-btn"])}
                    onClick={() => {
                      navigate({
                        pathname: "/courses/update_step",
                        search: `?step_id=${el.id}&course_id=${props.headCourseInfo?.id}`,
                      });
                    }}
                  >
                    {isAdmin ? <i className="pi pi-pencil" /> : <i className="pi pi-reply" />}
                  </button>
                </div>
              </div>
            ))}
          <span
            className={classNames(
              styles["main-wrapper-leson_list-label"],
              styles["main-wrapper-leson_list-label-blue"]
            )}
          >
            Степы
          </span>
        </div>
      )}
    </div>
  );
};

export default memo(CourseContentTab);
