import http from "./https";

import { TNewTarif, TTarifInfo } from "typings/tarifs";

export const getAllTarifsList = (courseId: number) => http.get(`/api/tariffs/${courseId}/list`);

export const createNewTarif = (tarifData: TNewTarif) => http.post(`/api/tariffs`, tarifData);

export const removeTarif = (id: number) => http.remove(`/api/tariffs/${id}`, {});

export const getTarifById = (id: string) => http.get(`/api/tariffs/${id}`);

export const updateTarifAsync = (id: string, data: TTarifInfo) =>
  http.patch(`/api/tariffs/${id}`, data);

export const getCourseCompositionAsync = (courseId: string) =>
  http.get(`/api/courses/${courseId}/list`);
