import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { TCourseInfo, TShortCourse } from "typings/courses";
import { getShortListCoursesModules } from "api/courses";
import { FC, useCallback, useEffect, useState } from "react";

import styles from "../components/Lesson/LessonUpdate/UpdateLesson.module.scss";

type CopyCoursePopupProps = {
  isOpen: boolean;
  handleHide: () => void;
  copyCourse: (name: string, headCourseId: number) => void;
  courseInfo: TCourseInfo | null;
};

const CopyCoursePopup: FC<CopyCoursePopupProps> = (props) => {
  const [courseName, setCourseName] = useState<string>("");
  const [selectedCourse, setSelectedCourse] = useState<number | null>(null);
  const [allCoursesList, setAllCouresesList] = useState<TShortCourse[]>([]);

  const closePopup = () => {
    props.handleHide();
  };

  useEffect(() => {
    if (props.courseInfo) setCourseName(props.courseInfo.name);
  }, [props.courseInfo]);

  const getCoursesList = useCallback(async () => {
    try {
      const response = await getShortListCoursesModules();
      if (response.status === 200 || response.status === 201) {
        const coursesList: TShortCourse[] = [];

        response.data.map((el) => {
          coursesList.push({ id: el.id, index: el.index, name: el.name });
        });

        setAllCouresesList(coursesList);
      } else throw new Error();
    } catch (err: any) {}
  }, []);

  useEffect(() => {
    props.isOpen && getCoursesList();
  }, [getCoursesList, props.isOpen]);

  return (
    <Dialog
      visible={props.isOpen}
      style={{ width: "500px" }}
      modal
      onHide={closePopup}
      header={`Копировать степ`}
    >
      <div className={styles["popup-wrapper"]}>
        <span className={styles["input-wrapper"]}>
          <p>Новое название степа</p>
          <InputText
            name="name"
            value={courseName}
            onChange={(e) => setCourseName(e.target.value)}
            placeholder="Введите название степа"
          />
        </span>
        <span className={styles["input-wrapper"]}>
          <p>Поместить копию в</p>
          <Dropdown
            options={allCoursesList}
            onChange={(e) => setSelectedCourse(e.value)}
            value={selectedCourse}
            placeholder="Выберите куда копировать степ"
            className={styles["available-dropdown"]}
            optionLabel="name"
            optionValue="id"
          />
        </span>
        <div className={styles["btn-wrap"]}>
          <button className={styles["cancel-btn"]} onClick={closePopup}>
            Отмена
          </button>
          <button
            className={styles["copy-btn"]}
            onClick={() => {
              props.copyCourse(courseName, Number(selectedCourse));
              closePopup();
            }}
            disabled={!courseName}
          >
            Копировать
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default CopyCoursePopup;
