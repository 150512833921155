import { FC, memo } from "react";
import { getRoles } from "utils/getRoles";
import { classNames } from "primereact/utils";
import { useNavigate } from "react-router";
import { TCourseInfo } from "typings/courses";
import { returnCorrectNameCounterLessonsModules } from "utils/returnCorrectNameCounterLessonsModules";

import styles from "./Content.module.scss";

type CourseContentTabProps = {
  courseInfo: TCourseInfo | null;
  headCourseId: string | null | undefined;
};

const CourseContentTab: FC<CourseContentTabProps> = (props) => {
  const navigate = useNavigate();

  const isAdmin = getRoles();

  return (
    <div className={styles["main-wrapper"]}>
      {props.courseInfo && props.courseInfo?.lessons.length > 0 && (
        <div
          className={classNames(
            styles["main-wrapper-leson_list"],
            styles["main-wrapper-leson_list_label"]
          )}
        >
          {props.courseInfo?.lessons
            .sort((a, b) => (a.index > b.index ? 1 : -1))
            .map((el) => (
              <div className={styles["lesson-list-item"]} key={el.id}>
                <div className={styles["row"]}>
                  <span>{el.name}</span>
                  <p>
                    {el.startAt
                      ? new Date(el.startAt).toLocaleDateString() +
                        " " +
                        new Date(el.startAt).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      : "Дата не задана"}
                  </p>
                  <p>{el.description}</p>
                </div>
                <button
                  className={classNames(styles["action-btn"], styles["edit-lesson-btn"])}
                  onClick={() => {
                    navigate({
                      pathname: "/courses/update_lesson",
                      search: `?lesson_id=${el.id}&step_id=${props.courseInfo?.id}&course_id=${props.headCourseId}`,
                    });
                  }}
                >
                  {isAdmin ? <i className="pi pi-pencil" /> : <i className="pi pi-reply" />}
                </button>
              </div>
            ))}
          <span
            className={classNames(
              styles["main-wrapper-leson_list-label"],
              styles["main-wrapper-leson_list-label-blue"]
            )}
          >
            Уроки
          </span>
        </div>
      )}
      {props.courseInfo && props.courseInfo?.submodules.length > 0 && (
        <div className={styles["main-wrapper-leson_list"]}>
          {props.courseInfo?.submodules
            .sort((a, b) => (a.index > b.index ? 1 : -1))
            .map((el) => (
              <div className={styles["lesson-list-item"]} key={el.id}>
                <div className={styles["row"]}>
                  <span>{el.name}</span>
                  <p>
                    {el.date_publication
                      ? new Date(el.date_publication).toLocaleDateString() +
                        " " +
                        new Date(el.date_publication).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      : "Дата не задана"}
                  </p>
                  <p className={styles["black-text"]}>
                    {el.count_submodules && el.count_submodules !== "0" && (
                      <b>
                        {el.count_submodules}{" "}
                        {returnCorrectNameCounterLessonsModules(el.count_submodules, "module")}
                        {". "}
                      </b>
                    )}
                    {el.count_lessons && el.count_lessons !== "0" && (
                      <b>
                        {el.count_lessons}{" "}
                        {returnCorrectNameCounterLessonsModules(el.count_lessons, "lesson")}
                        {". "}
                      </b>
                    )}
                  </p>
                  <p>{el.description}</p>
                </div>
                <button
                  className={classNames(styles["action-btn"], styles["edit-btn"])}
                  onClick={() => {
                    navigate({
                      pathname: "/courses/update_module",
                      search: `?module_id=${el.id}&step_id=${props.courseInfo?.id}&course_id=${props.headCourseId}`,
                    });
                  }}
                >
                  {isAdmin ? <i className="pi pi-pencil" /> : <i className="pi pi-reply" />}
                </button>
              </div>
            ))}
          <span className={styles["main-wrapper-leson_list-label"]}>Модули</span>
        </div>
      )}
    </div>
  );
};

export default memo(CourseContentTab);
