import { FC } from "react";
import { Image } from "primereact/image";

import styles from "./Image.module.scss";

type CustomLessonImageProps = {
  url: string;
  alt: string;
};

const CustomLessonImage: FC<CustomLessonImageProps> = (props) => {
  return (
    <div className={styles["image-wrapper"]}>
      <Image src={props.url} alt={props.alt} className={styles["image"]} />
    </div>
  );
};

export default CustomLessonImage;
