import { Button } from "primereact/button";
import { getRoles } from "utils/getRoles";
import { FC, memo } from "react";
import { useNavigate } from "react-router";
import { THeadCourse } from "typings/headCourse";
import { TAxiosGetCourseList } from "typings/courses";
import { returnCorrectNameCounterLessonsModules } from "utils/returnCorrectNameCounterLessonsModules";

import styles from "./HeadCourses.module.scss";
import HeadCoursesSettings from "./HeadCoursesSettings";

type HeadCoursesLayoutProps = {
  setParams: React.Dispatch<React.SetStateAction<TAxiosGetCourseList>>;
  params: TAxiosGetCourseList;
  headCoursesList: THeadCourse[];
  handleSelectedHeadCourse: (headcourse: THeadCourse) => void;
  handleOpenCreateHeadCoursePopup: () => void;
  deleteHeadCourse: (headCourseId: number) => void;
  handleOpenRemoveHeadCoursePopup: () => void;
};

const HeadCoursesLayout: FC<HeadCoursesLayoutProps> = (props) => {
  const navigate = useNavigate();

  const isAdmin = getRoles();

  return (
    <div className={styles["main-wrapper"]}>
      <div className={styles["header-container"]}>
        <h1 className={styles["page-title"]}>Курсы</h1>
        {isAdmin && (
          <Button
            className="p-button p-button-inverted"
            onClick={props.handleOpenCreateHeadCoursePopup}
          >
            <i className="pi pi-plus" />
            Новый курс
          </Button>
        )}
      </div>
      <HeadCoursesSettings setParams={props.setParams} params={props.params} />

      <div className={styles["content"]}>
        <span className={styles["content-header"]}>Доступные курсы</span>
        {props.headCoursesList.length > 0 ? (
          props.headCoursesList.map((el) => (
            <div
              key={el.id}
              className={!el.isFree ? styles["course-card"] : styles["course-card-free"]}
              onClick={() => {
                props.handleSelectedHeadCourse(el);
                navigate(`/courses/update_course?course_id=${el.id}`);
              }}
            >
              <div>
                <p className={styles["title-course"]}>
                  {el.isFree && <i>бесплатные материалы</i>}
                  {el.name}
                </p>
                <p className={styles["black-text"]}>
                  {el.count_courses && (
                    <b>
                      {el.count_courses}{" "}
                      {returnCorrectNameCounterLessonsModules(el.count_courses, "step")}
                      {". "}
                    </b>
                  )}
                </p>
                <p>
                  {el.startAt
                    ? new Date(el.startAt).toLocaleDateString() +
                      " " +
                      new Date(el.startAt).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                    : "Дата начала не задана"}{" "}
                  -{" "}
                  {el.endAt
                    ? new Date(el.endAt).toLocaleDateString() +
                      " " +
                      new Date(el.endAt).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                    : "Дата окончания не задана"}
                </p>
                <p className={styles["desc"]}>{el.description}</p>
              </div>
              {isAdmin && (
                <button
                  onClick={(e) => {
                    props.handleSelectedHeadCourse(el);
                    props.handleOpenRemoveHeadCoursePopup();
                    e.stopPropagation();
                  }}
                >
                  <i className="pi pi-trash" />
                </button>
              )}
            </div>
          ))
        ) : (
          <div className={styles["content-empty"]}>
            <p>Список пуст</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(HeadCoursesLayout);
