export const returnCorrectNameCounterLessonsModules = (counter: string, whoseCounter: string) => {
  if (whoseCounter === "module") {
    if (counter === "1") return "модуль";
    else if (counter === "2" || counter === "3" || counter === "4") return "модуля";
    else return "модулей";
  } else if (whoseCounter === "lesson") {
    if (counter === "1") return "урок";
    else if (counter === "2" || counter === "3" || counter === "4") return "урока";
    else return "уроков";
  } else if (whoseCounter === "step") {
    if (counter === "1") return "Степ";
    else if (counter === "2" || counter === "3" || counter === "4") return "степа";
    else return "степов";
  } else return "";
};
