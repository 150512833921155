import { TLesson } from "typings/lesson";
import { getRoles } from "utils/getRoles";
import { LESSON_TAB } from "constants/lesson";
import { useAppSelector } from "utils/hooks";
import { FC, memo, useCallback, useState } from "react";

import ScrollToTop from "react-scroll-to-top";
import LessonPreview from "components/Lesson/Preview/Preview";
import LessonConstructor from "../../../Constructor/Constructor";

import styles from "./Content.module.scss";

type LessonContentTabProps = {
  lessonInfo: TLesson;
  updateLesson: (lessonData: TLesson) => void;
};

const LessonContentTab: FC<LessonContentTabProps> = (props) => {
  const [activeTab, setActiveTab] = useState<string>(LESSON_TAB.PREVIEW);

  const { lessonInfo } = useAppSelector((state) => state.lessonConstructor);

  const isAdmin = getRoles();

  const renderActiveTab = useCallback(() => {
    switch (activeTab) {
      case LESSON_TAB.PREVIEW:
        return <LessonPreview />;
      // lessonInfo.blocks.length > 0 ? (
      //   <LessonPreview />
      // ) : (
      //   <div className={styles["empty-content"]}>
      //     <i className="pi pi-info-circle" />
      //     <p>Урок не заполнен!</p>
      //   </div>
      // );
      case LESSON_TAB.CONSTRUCTOR:
        return <LessonConstructor />;
      default:
        return <LessonPreview />;
    }
  }, [activeTab]);

  const changeTab = () => {
    if (activeTab === LESSON_TAB.CONSTRUCTOR && props.lessonInfo) {
      props.updateLesson(props.lessonInfo);
    }
    setActiveTab(activeTab === LESSON_TAB.PREVIEW ? LESSON_TAB.CONSTRUCTOR : LESSON_TAB.PREVIEW);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={styles["main-wrapper"]}>
      <div className={styles["constructor"]}>
        <div className={styles["constructor-edit_name"]}>
          <span>{props.lessonInfo?.name}</span>
          {isAdmin && (
            <p onClick={changeTab}>
              {activeTab === LESSON_TAB.PREVIEW ? "[изменить]" : "[сохранить]"}
            </p>
          )}
        </div>
        {renderActiveTab()}
      </div>
      <ScrollToTop
        smooth
        component={<i className="pi pi-chevron-up"></i>}
        className={styles["up-btn"]}
      />
      {activeTab === LESSON_TAB.CONSTRUCTOR && (
        <ScrollToTop
          smooth
          component={<i className="pi pi-save"></i>}
          className={styles["save-btn"]}
          onClick={changeTab}
        />
      )}
    </div>
  );
};

export default memo(LessonContentTab);
