import http from "./https";

export const getUploadedFiles = (type: string) => http.get(`api/storage?type=${type}`);

export const uploadFile = (file: FormData) => http.post(`/api/storage`, file);

export const removeFile = (type: string, key: string) =>
  http.remove(`/api/storage?type=${type}&key=${key}`, {});

export const initMultipartUpload = () => http.post(`/api/storage/init-multipart-upload`, {});

export const uploadChunk = (data) =>
  http.post(
    `/api/storage/upload-chunk?uploadId=${data.uploadId}&key=${data.key}&index=${data.index}`,
    data.file
  );

export const completeMultipartUpload = (data) =>
  http.post(
    `/api/storage/complete-multipart-upload?uploadId=${data.uploadId}&key=${data.key}&name=${data.name}`,
    {}
  );
